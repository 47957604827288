import React from "react";
import MapFull from "../../../common/components/MapFull";
import Loading from "../../../common/components/Loading";
import { computeZoom } from "../../../common/utils/position";
import UserPosition from "../../../common/components/UserPosition";
import Polyline from "../../../common/components/Polyline";
import { Box, Button, Divider, FormControl, FormControlLabel, FormLabel, InputLabel, MenuItem, Radio, RadioGroup, Select, Slider, Stack, Step, StepContent, StepLabel, Stepper, Switch, TextField, Typography } from "@mui/material";
import { SegmentGroup, metersToCoordinates, rotatePoint } from "../../../classes/geometry";
import Polygon from "../../../common/components/Polygon";
import { Polygon as GeomPolygon } from "../../../classes/geometry";
import { primary } from "../../../theme";
import { generateFlightPlan } from "../../../utils/flightplan";
import { gql, useApolloClient, useMutation, useQuery } from "@apollo/client";
import useUser from "../../../common/hooks/useUser";
import { numberFormat } from "../../../common/utils/text";
import { lookupMatrix, Overlap } from "../../../utils/flightplan-matrix";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { DroneModel, parseDroneModelsFromGraphQL } from "../../../classes/drone";

const Offset = require('polygon-offset');

interface SpeedSliderProps {
  maxSpeed: number;
  value: number;
  onChange?: (value: number) => void;
};

const SpeedSlider = (props: SpeedSliderProps) => {
  return (
    <Slider
      min={1}
      max={props.maxSpeed}
      step={0.1}
      value={props.value}
      onChange={(_, value) => {
        if (props.onChange) {
          props.onChange(value as number);
        }
      }}
    />
  );
}

interface AltitudeSliderProps {
  value: number;
  onChange?: (value: number) => void;
}

const AltitudeSlider = (props: AltitudeSliderProps) => {
  return (
    <Slider
      min={25}
      max={120}
      step={5}
      value={props.value}
      onChange={(_, value) => {
        if (props.onChange) {
          props.onChange(value as number);
        }
      }}
    />
  );
};

interface PercentSliderProps {
  value: number;
  onChange?: (value: number) => void;
  step?: number;
}

const PercentSlider = (props: PercentSliderProps) => {
  return (
    <Slider
      value={props.value}
      step={ props.step ?? 5 }
      min={0}
      max={100}
      onChange={(_, value) => {
        if (props.onChange) {
          props.onChange(value as number);
        }
      }}
    />
  );
};

interface RotationSliderProps {
  value: number;
  onChange?: (value: number) => void;
}

const RotationSlider = (props: RotationSliderProps) => {
  return (
    <Slider
      min={-180}
      max={180}
      step={1}
      value={props.value}
      onChange={(_, value) => {
        if (props.onChange) {
          props.onChange(value as number);
        }
      }}
    />
  );
};

interface LateralMenuProps {
  back?: () => void;
  save?: () => void;
  reset?: () => void;
  name: string;
  setName: (value: string) => void;
  construct3d: boolean;
  setConstruct3d: (value: boolean) => void;
  rotation: number;
  setRotation: (value: number) => void;
  followTerrain: boolean;
  setFollowTerrain: (value: boolean) => void;
  speed: number;
  setSpeed: (value: number) => void;
  maxSpeed: number;
  altitude: number;
  setAltitude: (value: number) => void;
  // horizontalOverlapping: number;
  // setHorizontalOverlapping: (value: number) => void;
  // verticalOverlapping: number;
  // setVerticalOverlapping: (value: number) => void;
  overlapping: number;
  setOverlapping: (value: number) => void;
  resolution: number;
  endBehavior: 'rth' | 'hovering';
  setEndBehavior: (value: 'rth' | 'hovering') => void;
}

const LateralMenu = (props: LateralMenuProps) => {
  const [ activeStep, setActiveStep ] = React.useState(0);

  const { t } = useTranslation();

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    if (props.reset) props.reset();
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <Box style={{
      padding: 16,
      width: 600,
      maxWidth: '60%',
      height: '100%',
      overflowX: 'hidden',
      overflowY: 'scroll',
    }}>
      <Stepper activeStep={activeStep} orientation="vertical" style={{width: '100%'}}>
        <Step key="General" style={{width: '100%'}}>
          <StepLabel style={{
            cursor: 'pointer',
          }} onClick={() => {
            setActiveStep(0);
          }}>
            {t('General')}
          </StepLabel>
          <StepContent>
            <Box><Stack spacing={2} style={{
              width: '100%',
              padding: 0,
            }}>
              <Box style={{
                width: '100%',
              }}>
                <FormControl style={{
                  width: '100%',
                }}>
                  <TextField
                    id="name"
                    label={t("Name")}
                    variant="filled"
                    value={props.name}
                    onChange={(e) => {
                      props.setName(e.target.value);
                    }}
                    size="small"
                    autoFocus
                    fullWidth />
                </FormControl>
              </Box>
              <Box>
                <FormControl>
                  <FormLabel id="map-type-label">
                    <Typography variant="body2">
                      {t('Map Type')}
                    </Typography>
                  </FormLabel>
                  <RadioGroup
                    aria-labelledby="map-type-label"
                    defaultValue="2d"
                    name="map-type"
                    value={props.construct3d ? '3d' : '2d'}
                    onChange={(e) => {
                      props.setConstruct3d(e.target.value === '3d');
                    }}
                  >
                    <FormControlLabel value="2d" control={<Radio size="small" />} label={
                      <Typography variant="body2">
                        {t('2D, Orthogonal Pictures')}
                      </Typography>
                    } />
                    <FormControlLabel value="3d" control={<Radio size="small" />} label={
                      <Typography variant="body2">
                        {t('3D, Orthogonal and Perspective')}
                      </Typography>
                    } />
                  </RadioGroup>
                </FormControl>
              </Box>
              <Box>
                <Typography variant="body2" style={{
                  color: 'rgba(0, 0, 0, 0.6)',
                  marginBottom: 8,
                }}>
                  {t('Follow Terrain')}
                </Typography>
                <FormControlLabel
                  control={
                  <Switch checked={props.followTerrain} onChange={(e) => {
                    props.setFollowTerrain(e.target.checked);
                  }} />
                } label={
                  <Typography variant="caption">
                    {t('The drone will follow the terrain; set this option only if you are flying over a mountain or a hill.')}
                  </Typography>
                } />
              </Box>
              <Box style={{
                width: '100%',
              }}>
                <Typography variant="body2">
                  {t('Altitude')}: {props.altitude}m
                </Typography>
                <AltitudeSlider value={props.altitude} onChange={(v) => {
                  props.setAltitude(v);
                }} />
              </Box>

              <Stack direction="row" spacing={1}>
                { props.back && <Button
                  onClick={props.back}
                  sx={{ mt: 1, mr: 1 }}
                  fullWidth
                >
                  {t('Back')}
                </Button> }
                <Button
                  variant="contained"
                  onClick={handleNext}
                  sx={{ mt: 1, mr: 1 }}
                  fullWidth
                  disabled={props.name.length === 0}
                >
                  {t('Continue')}
                </Button>
              </Stack>
            </Stack></Box>
          </StepContent>
        </Step>

        <Step key="Details">
          <StepLabel style={{
            cursor: 'pointer',
          }} onClick={() => {
            if (props.name.length === 0) return;
            if (props.reset) props.reset();
            setActiveStep(1);
          }}>
            {t('Details')}
          </StepLabel>
          <StepContent>
            <Box><Stack spacing={2} style={{
              width: '100%',
              padding: 0,
            }}>
              { props.reset && <Box style={{
                marginTop: 0,
                marginBottom: 0,
              }}>
                <Stack direction="row" spacing={2} style={{
                  width: '100%',
                }}>
                  <Button
                    onClick={props.reset}
                    fullWidth
                    variant="text">
                    {t('Reset')}
                  </Button>
                </Stack>
              </Box> }
              <Box style={{
                width: '100%',
              }}>
                <FormControl fullWidth variant="filled" size="small">
                  <InputLabel id="overlapping-select-label">{t('Overlapping')}</InputLabel>
                  <Select
                    labelId="overlapping-select-label"
                    id="overlapping-select"
                    value={props.overlapping}
                    label={t("Overlapping")}
                    onChange={(e) => {
                      props.setOverlapping(e.target.value as number);
                    }}
                  >
                    <MenuItem value={Overlap.OVERLAP_70_80}>{t('70% (side) - 80% (frontal)')}</MenuItem>
                    <MenuItem value={Overlap.OVERLAP_60_70}>{t('60% (side) - 70% (frontal)')}</MenuItem>
                    <MenuItem value={Overlap.OVERLAP_50_60}>{t('50% (side) - 60% (frontal)')}</MenuItem>
                  </Select>
                </FormControl>
              </Box>
              {/* <Box style={{
                width: '100%',
              }}>
                <Typography variant="body2">
                  Horizontal Overlapping: {props.horizontalOverlapping}%
                </Typography>
                <PercentSlider value={props.horizontalOverlapping} onChange={(v) => {
                  props.setHorizontalOverlapping(v);
                }} />
              </Box>
              <Box style={{
                width: '100%',
              }}>
                <Typography variant="body2">
                  Vertical Overlapping: {props.verticalOverlapping}%
                </Typography>
                <PercentSlider value={props.verticalOverlapping} onChange={(v) => {
                  props.setVerticalOverlapping(v);
                }} />
              </Box> */}
              <Box style={{
                width: '100%',
              }}>
                <Typography variant="body2">
                  {t('Speed')}: {props.speed}m/s
                </Typography>
                <SpeedSlider value={props.speed} maxSpeed={props.maxSpeed} onChange={(v) => {
                  props.setSpeed(v);
                }} />
              </Box>
              <Box style={{
                width: '100%',
              }}>
                <Typography variant="body2">
                  {t('Rotation')}: {numberFormat(props.rotation)}°
                </Typography>
                <RotationSlider value={props.rotation} onChange={(v) => {
                  props.setRotation(v);
                }} />
              </Box>
              <Box style={{
                width: '100%',
              }}>
                <FormControl variant="filled" size="small" fullWidth sx={{ mt: 1 }}>
                  <InputLabel id="end-behavior-select-label">{t('End Behavior')}</InputLabel>
                  <Select
                    labelId="end-behavior-select-label"
                    id="end-behavior-select"
                    value={props.endBehavior}
                    label={t("End Behavior")}
                    onChange={(e) => props.setEndBehavior(e.target.value as 'rth' | 'hovering')}
                  >
                    <MenuItem value="rth">{t('Return to Home')}</MenuItem>
                    <MenuItem value="hovering">{t('Hovering')}</MenuItem>
                  </Select>
                </FormControl>
              </Box>
              <Divider />
              <Box style={{
                width: '100%',
                marginBottom: 16,
              }}>
                <Typography variant="body2">
                  {t('Resolution')}: {props.resolution} cm<sup>2</sup>/pixel
                </Typography>
              </Box>

              <Stack direction="row" spacing={1}>
                <Button
                  onClick={handleBack}
                  sx={{ mt: 1, mr: 1 }}
                  fullWidth
                >
                  {t('Back')}
                </Button>
                <Button
                  variant="contained"
                  disabled={!props.save || props.name.length === 0}
                  onClick={() => {
                    if (props.save) props.save();
                  }}
                  sx={{ mt: 1, mr: 1 }}
                  fullWidth
                >
                  {t('Save')}
                </Button>
              </Stack>
            </Stack></Box>
          </StepContent>
        </Step>
      </Stepper>
    </Box>
  );
};

export interface SetupFlightPlanProps {
  path: google.maps.LatLngLiteral[];
  fieldID?: number;
  back?: () => void;
  save?: (name?: string) => Promise<number | undefined>;
}

const SetupFlightPlan = (props: SetupFlightPlanProps) => {
  const [ saving, setSaving ] = React.useState(false);
  const [ defaultCenter, setDefaultCenter ] = React.useState<google.maps.LatLngLiteral | undefined>();
  const [ defaultZoom, setDefaultZoom ] = React.useState<number | undefined>();
  const [ safeArea, setSafeArea ] = React.useState<google.maps.LatLngLiteral[] | undefined>();
  const [ name, setName ] = React.useState('');
  const [ construct3d, setConstruct3d ] = React.useState(false);
  const [ rotation, setRotation ] = React.useState(0);
  const [ followTerrain, setFollowTerrain ] = React.useState(false);
  const [ speed, setSpeed ] = React.useState(5);
  const [ maxSpeed, setMaxSpeed ] = React.useState(5);
  const [ altitude, setAltitude ] = React.useState(60);
  // const [ horizontalOverlapping, setHorizontalOverlapping ] = React.useState(70);
  // const [ verticalOverlapping, setVerticalOverlapping ] = React.useState(80);
  const [ overlapping, setOverlapping ] = React.useState(Overlap.OVERLAP_70_80);
  const [ resolution, setResolution ] = React.useState(0.0);
  const [ gap, setGap ] = React.useState(10);
  const [ path, setPath ] = React.useState<google.maps.LatLngLiteral[] | undefined>();
  const [ pathRotated3D, setPathRotated3D ] = React.useState<google.maps.LatLngLiteral[] | undefined>();
  const [ dronePath, setDronePath ] = React.useState<google.maps.LatLngLiteral[] | undefined>();
  const [ endBehavior, setEndBehavior ] = React.useState<'rth' | 'hovering'>('rth');
  const [ droneModels, setDroneModels ] = React.useState<DroneModel[]>([]);

  const safeAreaMinMeters = 30;

  const { userID, organizationID } = useUser();
  const navigate = useNavigate();

  const { loading, error, data } = useQuery(gql(`
    query DroneModelsCameras {
      drone_models {
        id
        name
        camera_multispectral_focal_length
        camera_multispectral_height
        camera_multispectral_sensor_height
        camera_multispectral_sensor_width
        camera_multispectral_width
        camera_rgb_focal_length
        camera_rgb_height
        camera_rgb_sensor_height
        camera_rgb_sensor_width
        camera_rgb_width
        camera_thermal_focal_length
        camera_thermal_height
        camera_thermal_sensor_height
        camera_thermal_sensor_width
        camera_thermal_width
      }
    }
  `));

  const client = useApolloClient();

  const [ insertFlightplan ] = useMutation(gql(`
    mutation InsertFlightplan(
      $name: String!,
      $data: bytea!,
      $user_id: bigint!,
      $organization_id: bigint,
      $field_id: bigint,
    ) {
      insert_flight_plans(objects: {
        name: $name,
        data: $data,
        user_id: $user_id,
        organization_id: $organization_id,
        field_id: $field_id,
      }) {
        returning {
          id
          uuid
        }
      }
    }
  `), {
    refetchQueries: ['FlightPlans', 'FlightPlan', 'FlightPlanNames'],
    onCompleted: async () => {
      await client.resetStore();
    },
  });

  const safeAreaOffset = React.useCallback((): number => {
    return metersToCoordinates(Math.max(altitude, safeAreaMinMeters));
  }, [safeAreaMinMeters, altitude]);

  const onSave = async () => {
    if (!path) return;

    setSaving(true);
    const fp = await generateFlightPlan({
      altitude: altitude,
      speed: speed,
      path: path,
      pathRotated3D: pathRotated3D,
      rotated3D: construct3d,
      followTerrain: followTerrain,
      endBehavior: endBehavior,
    });

    let fieldID = props.fieldID;
    if (!fieldID && props.save) {
      fieldID = await props.save(name);
    }

    const data = await fp.toData();
    await insertFlightplan({
      variables: {
        name: name,
        data: data,
        user_id: userID,
        organization_id: organizationID,
        field_id: fieldID,
      },
    });

    setSaving(false);
    navigate('../..');
  };

  const onReset = () => {
    setOverlapping(Overlap.OVERLAP_70_80);
    const v = lookupMatrix(Overlap.OVERLAP_70_80, altitude);

    setSpeed(v.speed);
    setMaxSpeed(v.speed);
    setResolution(v.precision);
    setGap(v.gap);

    const polygon = new GeomPolygon(...props.path);
    const longestSegment = polygon.getLongestSegment();
    let rotation = 90 - (longestSegment?.getRotation() ?? 0);
    if (rotation < -180) rotation += 360;
    if (rotation > 180) rotation -= 360;
    setRotation(rotation);
  };

  React.useEffect(() => {
    const v = lookupMatrix(overlapping, altitude);

    setSpeed(v.speed);
    setMaxSpeed(v.speed);
    setResolution(v.precision);
    setGap(v.gap);
  }, [overlapping, altitude]);

  React.useEffect(() => {
    if (!google.maps.LatLngBounds) {
      return
    }
    const b = new google.maps.LatLngBounds();
    props.path.forEach((latLng) => {
      b.extend(new google.maps.LatLng(latLng.lat, latLng.lng));
    });
    setDefaultCenter(b.getCenter().toJSON());
    setDefaultZoom(computeZoom(b, window.innerWidth, window.innerHeight));
  }, [props.path, google.maps.LatLngBounds]);

  React.useEffect(() => {
    if (!props.path) return;

    const closedCoords = [
      ...props.path,
      props.path[0],
    ];
    const offset = new Offset();
    const safeArea = offset.data(closedCoords.map(p => {
      return [p.lat, p.lng];
    })).margin(safeAreaOffset())[0].map((p: number[]) => {
      return {
        lat: p[0],
        lng: p[1],
      };
    });
    setSafeArea(safeArea);
  }, [props.path, safeAreaOffset]);

  React.useEffect(() => {
    if (!props.path) return;
    if (!google.maps.geometry) return;
    onReset();
  }, [props.path, google.maps.geometry]);

  React.useEffect(() => {
    const polygon = new GeomPolygon(...props.path);

    const polygonRotated = polygon.rotate(rotation);
    const roundingBox = polygonRotated.getRoundingBox();
    const segmentGroup = new SegmentGroup(roundingBox, gap);
    let p = segmentGroup.getPath(polygonRotated).map(p => rotatePoint(p, -rotation));
    setPath(p);

    if (construct3d) {
      const r = rotation + 20;

      const polygonRotated = polygon.rotate(r);
      const roundingBox = polygonRotated.getRoundingBox();
      const segmentGroup = new SegmentGroup(roundingBox, gap);
      const pathRotated = segmentGroup.getPath(polygonRotated).map(p => rotatePoint(p, -r));
      setPathRotated3D(pathRotated);
      p = [...p, ...pathRotated];
    } else {
      setPathRotated3D(undefined);
    }

    setDronePath(p);
  }, [props.path, rotation, construct3d, gap]);

  React.useEffect(() => {
    if (!data) return;

    const droneModels = parseDroneModelsFromGraphQL(data.drone_models);
    setDroneModels(droneModels);
  }, [data]);

  if (!defaultCenter || saving || loading) {
    return <Loading open />;
  }

  return (
    <Stack
      style={{
        height: '100%',
      }}
      direction="row">
      <LateralMenu
        back={props.back}
        save={onSave}
        reset={onReset}
        name={name} setName={setName}
        construct3d={construct3d} setConstruct3d={setConstruct3d}
        rotation={rotation} setRotation={setRotation}
        followTerrain={followTerrain} setFollowTerrain={setFollowTerrain}
        speed={speed} setSpeed={setSpeed}
        maxSpeed={maxSpeed}
        altitude={altitude} setAltitude={setAltitude}
        // horizontalOverlapping={horizontalOverlapping} setHorizontalOverlapping={setHorizontalOverlapping}
        // verticalOverlapping={verticalOverlapping} setVerticalOverlapping={setVerticalOverlapping}
        overlapping={overlapping} setOverlapping={setOverlapping}
        resolution={resolution}
        endBehavior={endBehavior} setEndBehavior={setEndBehavior}
      />
      <MapFull
        defaultCenter={defaultCenter}
        defaultZoom={defaultZoom}>
        { safeArea && <Polygon
          path={safeArea}
          opacity={0.25}
          color="green"
          /> }
        <Polyline path={props.path} closed />
        { dronePath && <Polyline path={dronePath} strokeColor={primary} zIndex={1} /> }
        <UserPosition />
      </MapFull>
    </Stack>
  );
};

export default SetupFlightPlan;
