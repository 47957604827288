export interface ForumComment {
  id: number;
  comment: string;
  createdAt: string;
  username: string;
  email: string;
}

export interface ForumPost {
  id: number;
  title: string;
  description: string;
  createdAt: string;
  currentStatus: string;
  username: string;
  email: string;
  nComments?: number;
  comments?: ForumComment[];
}

export const ForumPostStatus = {
  OPEN: 'Open',
  RESOLVED: 'Resolved',
};
