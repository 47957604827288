import React from 'react';
import { useParams } from 'react-router-dom';
import { Box } from '@mui/material';
import useFiles from '../../../hooks/useFiles';
import Loading from '../../../common/components/Loading';

const PDFViewer = React.lazy(() => import('../../../components/pilot/resources/viewer/PDFViewer'));

const PDFView: React.FC = () => {
  const { fileUUID } = useParams<{ fileUUID: string }>();
  const [ fileURL, setFileURL ] = React.useState<string | null>(null);

  const { downloadFile } = useFiles();

  React.useEffect(() => {
    if (!fileUUID) return;

    downloadFile(fileUUID).then((res: Blob) => {
      setFileURL(URL.createObjectURL(res));
    });
  }, [downloadFile, fileUUID]);

  if (!fileURL) return <Loading open />;

  return (
    <Box sx={{ width: '100%', height: '100vh' }}>
      <React.Suspense fallback={<Loading open />}>
        <PDFViewer url={fileURL} />
      </React.Suspense>
    </Box>
  );
};

export default PDFView;
