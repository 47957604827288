import { Autocomplete, TextField } from "@mui/material";
import { useMapsLibrary } from "@vis.gl/react-google-maps";
import React from "react";

export interface LocationOption {
  description: string;
  place_id: string;
}

export interface LocationAutocompleteProps {
  label?: string;
  placeholder?: string;
  value?: LocationOption | null;
  onChange?: (location: LocationOption | null) => void;
  error?: boolean;
  helperText?: string;
  required?: boolean;
  disabled?: boolean;
}

const LocationAutocomplete = (props: LocationAutocompleteProps) => {
  const {
    label,
    placeholder,
    value,
    onChange,
    error,
    helperText,
    required,
    disabled
  } = props;

  const [inputValue, setInputValue] = React.useState<string>("");
  const [options, setOptions] = React.useState<LocationOption[]>([]);
  const [loading, setLoading] = React.useState(false);

  const places = useMapsLibrary("places");

  const [sessionToken, setSessionToken] =
    React.useState<google.maps.places.AutocompleteSessionToken>();
  const [autocompleteService, setAutocompleteService] =
    React.useState<google.maps.places.AutocompleteService | null>(null);
  const [placesService, setPlacesService] =
    React.useState<google.maps.places.PlacesService | null>(null);

  React.useEffect(() => {
    if (!places) return;

    setAutocompleteService(new places.AutocompleteService());
    setSessionToken(new places.AutocompleteSessionToken());

    return () => {
      setAutocompleteService(null);
      setPlacesService(null);
    };
  }, [places]);

  const fetchPredictions = React.useCallback(
    async (input: string) => {
      if (!autocompleteService || !input) {
        setOptions([]);
        return;
      }

      setLoading(true);
      try {
        const request = { input, sessionToken };
        const response = await autocompleteService.getPlacePredictions(request);

        const newOptions = response.predictions.filter((prediction) => {
          return prediction.types.includes("locality") && prediction.types.includes("political");
        }).map((prediction) => ({
          description: prediction.description,
          place_id: prediction.place_id,
        }));

        setOptions(newOptions);
      } catch (error) {
        console.error("Error fetching predictions:", error);
        setOptions([]);
      } finally {
        setLoading(false);
      }
    },
    [autocompleteService, sessionToken]
  );

  const handleLocationSelect = React.useCallback(
    (option: LocationOption | null) => {
      if (!places || !option) {
        onChange?.(null);
        return;
      }

      onChange?.(option);
    },
    [places, placesService, sessionToken, onChange]
  );

  return (
    <Autocomplete
      value={value}
      onChange={(_, newValue) => handleLocationSelect(newValue)}
      inputValue={inputValue}
      onInputChange={async (_, newInputValue) => {
        setInputValue(newInputValue);
        await fetchPredictions(newInputValue);
      }}
      options={options}
      getOptionLabel={(option) => option.description}
      loading={loading}
      loadingText="Searching..."
      noOptionsText="No locations found"
      disabled={disabled}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          placeholder={placeholder}
          error={error}
          helperText={helperText}
          required={required}
          fullWidth
        />
      )}
    />
  );
};

export default LocationAutocomplete;
