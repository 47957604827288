import React, { useState, useEffect } from 'react';
import {
  Box,
  TextField,
  Button,
  Paper,
  Typography,
  useMediaQuery,
  Toolbar,
  IconButton,
  Tooltip,
} from '@mui/material';
import MDEditor from '@uiw/react-md-editor';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'react-oidc-context';
import { useApolloClient, useMutation, useQuery } from '@apollo/client';
import useUser from '../../../common/hooks/useUser';
import { ForumPostStatus } from '../../../classes/forum';
import rehypeSanitize from 'rehype-sanitize';
import theme from '../../../theme';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SaveIcon from '@mui/icons-material/Save';
import gql from 'graphql-tag';

export interface CreatePostProps {
  isEditing?: boolean;
}

const CreatePost: React.FC<CreatePostProps> = ({ isEditing }) => {
  const { postUUID } = useParams<{ postUUID: string }>();
  const [title, setTitle] = useState('');
  const [content, setContent] = useState<string>('');
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { user } = useAuth();
  const { userID, authenticatedUserEmail } = useUser();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const client = useApolloClient();

  const { data: postData } = useQuery(
    gql`
      query GetPost($id: bigint!) {
        forum_posts_by_pk(id: $id) {
          title
          description
          email
        }
      }
    `,
    {
      variables: { id: postUUID },
      skip: !isEditing,
    }
  );

  const [insertPostMutation] = useMutation(gql`
    mutation InsertPost($title: String!, $description: String!, $status: String!, $userId: bigint, $userEmail: String!, $username: String!) {
      insert_forum_posts(objects: { title: $title, description: $description, current_status: $status, user_id: $userId, email: $userEmail, username: $username }) {
        returning {
          id
        }
      }
    }
  `);

  const [updatePostMutation] = useMutation(gql`
    mutation UpdatePost($id: bigint!, $title: String!, $description: String!) {
      update_forum_posts_by_pk(
        pk_columns: { id: $id },
        _set: { title: $title, description: $description }
      ) {
        id
      }
    }
  `);

  useEffect(() => {
    if (isEditing && postData?.forum_posts_by_pk) {
      const post = postData.forum_posts_by_pk;
      setTitle(post.title);
      setContent(post.description);
    }
  }, [postData, isEditing]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    let id: number | undefined = undefined;
    if (isEditing) {
      await updatePostMutation({
        variables: {
          id: postUUID,
          title,
          description: content,
        },
      });
    } else {
      const result = await insertPostMutation({
        variables: {
          title,
          description: content,
          status: ForumPostStatus.OPEN,
          userId: userID ?? 0,
          userEmail: authenticatedUserEmail ?? '',
          username: user?.profile.name ?? '',
        },
      });

      id = result.data?.insert_forum_posts.returning[0].id;
    }

    await client.resetStore();
    navigate(`/community/forum/${id ?? postUUID}`);
  };

  const handleCancel = () => {
    if (isEditing) {
      navigate(`/community/forum/${postUUID}`);
      return;
    }
    navigate('/community/forum');
  };

  return (
    <Box sx={{ 
      p: isMobile ? 1 : 3,
      pt: 3,
      pb: 3,
      width: '100%',
      maxWidth: '1000px',
      maxHeight: 'calc(100vh - 64px)',
      mx: 'auto',
    }}>
      <Toolbar 
        variant="dense"
        sx={{ 
          mb: 1,
          px: '0 !important',
          minHeight: 'unset !important',
          gap: 1,
        }}
      >
        <Tooltip title={t('forum.back_to_forum')}>
          <IconButton
            onClick={handleCancel}
            sx={{ mr: 'auto' }}
          >
            <ArrowBackIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title={isEditing ? t('forum.save_changes') : t('forum.create_post')}>
          <IconButton
            onClick={handleSubmit}
            color="primary"
            disabled={!title.trim() || !content.trim()}
          >
            <SaveIcon />
          </IconButton>
        </Tooltip>
      </Toolbar>

      <Paper sx={{ p: 3, width: '100%' }}>
        <Typography variant="h6" sx={{ mb: 3 }}>
          {isEditing ? t('forum.page_title.edit') : t('forum.page_title.create')}
        </Typography>

        <form onSubmit={handleSubmit}>
          <TextField
            fullWidth
            label={t('forum.post_title')}
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            required
            sx={{ mb: 2 }}
          />

          <Typography variant="caption" color="text.secondary" sx={{ mb: 1, display: 'block' }}>
            {t('forum.markdown_supported')}
          </Typography>
          <Box sx={{ 
            mb: 3,
            '& .w-md-editor': {
              backgroundColor: 'background.paper',
              border: '1px solid',
              borderColor: 'divider',
              borderRadius: 1,
            },
            '& .w-md-editor-toolbar': {
              borderBottom: '1px solid',
              borderColor: 'divider',
              backgroundColor: 'background.default',
            },
            '& .w-md-editor-text': {
              fontSize: '0.875rem',
            },
            '& .wmde-markdown-color': { 
              backgroundColor: 'transparent',
            },
            '& .w-md-editor-preview': {
              boxSizing: 'border-box',
              backgroundColor: 'background.default',
              borderLeft: '1px solid',
              borderColor: 'divider',
            }
          }}>
            <div data-color-mode="light">
              <MDEditor
                value={content}
                onChange={(value) => setContent(value || '')}
                preview="live"
                height={'calc(100vh - 450px)'}
                visibleDragbar={false}
                previewOptions={{
                  rehypePlugins: [[rehypeSanitize]],
                }}
              />
            </div>
          </Box>

          <Box sx={{ display: 'flex', gap: 2, justifyContent: 'flex-end' }}>
            <Button
              variant="outlined"
              onClick={handleCancel}
            >
              {t('Cancel')}
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={!title.trim() || !content.trim()}
            >
              {isEditing ? t('forum.button.save') : t('forum.button.create')}
            </Button>
          </Box>
        </form>
      </Paper>
    </Box>
  );
};

export default CreatePost;
