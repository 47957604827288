import React from 'react';
import { Box, Card, CardContent, CardMedia, Chip, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { PilotProfileData } from '../../classes/PilotProfile';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

interface PilotCardProps {
  profile: PilotProfileData;
}

const PilotCard: React.FC<PilotCardProps> = ({ profile }) => {
  const { t } = useTranslation();

  if (!profile) {
    return null;
  }

  if (!profile.personalInfo) {
    return null;
  }

  return (
    <Card sx={{ 
      maxWidth: 400, 
      mx: 'auto', 
      mt: 4,
      borderRadius: 4,
      boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
      backgroundColor: 'var(--gray)',
    }}>
      <Box sx={{ position: 'relative', textAlign: 'center', pt: 4, pb: 2 }}>
        {(profile.personalInfo as any).avatar ? (
          <CardMedia
            component="img"
            image={(profile.personalInfo as any).avatar}
            alt={profile.personalInfo.firstName + ' ' + profile.personalInfo.lastName}
            sx={{
              width: 120,
              height: 120,
              borderRadius: '50%',
              margin: '0 auto',
              border: '4px solid white',
              boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
            }}
          />
        ) : (
          <AccountCircleIcon 
            sx={{
              width: 120,
              height: 120,
              margin: '0 auto',
              color: 'action.disabled',
            }}
          />
        )}
      </Box>

      <CardContent sx={{ textAlign: 'center' }}>
        <Typography variant="h4" component="div" gutterBottom>
          {profile.personalInfo.firstName + ' ' + profile.personalInfo.lastName}
        </Typography>

        <Box sx={{ my: 2 }}>
          {profile.qualifications?.pilotLicenses?.length > 0 && (
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', mb: 1 }}>
              <Typography variant="body1" component="span" color="text.secondary" sx={{ mr: 1 }}>
                {t('pilot.sections.certificate')}:
              </Typography>
              <Chip
                label={profile.qualifications.pilotLicenses[0].license_name}
                variant="filled"
                color="default"
                size="small"
              />
            </Box>
          )}

          {profile.personalInfo?.city && (
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', mb: 1 }}>
              <Typography variant="body1" component="span" color="text.secondary" sx={{ mr: 1 }}>
                {t('pilot.sections.residence')}:
              </Typography>
              <Chip
                label={profile.personalInfo.city}
                variant="filled"
                color="default"
                size="small"
              />
            </Box>
          )}

          {profile.availability?.operationalRange !== undefined && (
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', mb: 1 }}>
              <Typography variant="body1" component="span" color="text.secondary" sx={{ mr: 1 }}>
                {t('pilot.sections.operationalRange')}:
              </Typography>
              <Chip
                label={`${profile.availability.operationalRange} km`}
                variant="filled"
                color="default"
                size="small"
              />
            </Box>
          )}
        </Box>
      </CardContent>
    </Card>
  );
};

export default PilotCard;
