export interface FlightPlan {
  id?: number;
  uuid: string;
  name: string;
}

export interface DroneModel {
  name: string;
  image_url: string;
  brand?: string;
  weight?: string;  // in kilograms
  battery_duration?: string;  // in minutes
  camera_rgb?: DroneModelCamera;
  camera_multispectral?: DroneModelCamera;
  camera_thermal?: DroneModelCamera;
  size?: DroneModelSize;
  sdk?: SDK;
}

export interface DroneModelCamera {
  width: number;  // in pixels
  height: number;  // in pixels
  sensor_width: number;  // in mm
  sensor_height: number;  // in mm
  focal_length: number;  // in mm
}

export interface DroneModelSize {
  width: number;  // in cm
  height: number;  // in cm
}

export interface SDK {
  version: string;
}

export const parseDroneModelsFromGraphQL = (droneModels: any[]): DroneModel[] => {
  return droneModels.map((droneModel: any) => {
    const item: DroneModel = {
      name: droneModel.name,
      image_url: droneModel.image_url,
      brand: droneModel.brand,
      weight: droneModel.weight,
      battery_duration: droneModel.battery_duration,
      camera_rgb: droneModel.camera_rgb_width ? {
        width: droneModel.camera_rgb_width,
        height: droneModel.camera_rgb_height,
        sensor_width: droneModel.camera_rgb_sensor_width,
        sensor_height: droneModel.camera_rgb_sensor_height,
        focal_length: droneModel.camera_rgb_focal_length,
      } : undefined,
      camera_multispectral: droneModel.camera_multispectral_width ? {
        width: droneModel.camera_multispectral_width,
        height: droneModel.camera_multispectral_height,
        sensor_width: droneModel.camera_multispectral_sensor_width,
        sensor_height: droneModel.camera_multispectral_sensor_height,
        focal_length: droneModel.camera_multispectral_focal_length,
      } : undefined,
      camera_thermal: droneModel.camera_thermal_width ? {
        width: droneModel.camera_thermal_width,
        height: droneModel.camera_thermal_height,
        sensor_width: droneModel.camera_thermal_sensor_width,
        sensor_height: droneModel.camera_thermal_sensor_height,
        focal_length: droneModel.camera_thermal_focal_length,
      } : undefined,
      size: droneModel.size,
      sdk: droneModel.sdk,
    };
    return item;
  });
};

export interface DroneInsurance {
  expire_date: string;
  file_id: number;
  file_uuid: string;
}

export interface DronePilot {
  mtom?: string;
  flight_controller?: string;
  gcs?: string;
  insurance?: DroneInsurance;
}

export default interface Drone {
  id?: number;
  uuid: string;
  name: string;
  flightPlan?: FlightPlan;
  model?: DroneModel;
  pilot?: DronePilot;
}
