import React, { useState } from 'react';
import {
  Box,
  Button,
  Typography,
} from '@mui/material';
import MDEditor from '@uiw/react-md-editor';
import { useAuth } from 'react-oidc-context';
import { useTranslation } from 'react-i18next';
import { gql, useMutation, useApolloClient } from '@apollo/client';
import useUser from '../../../common/hooks/useUser';
import rehypeSanitize from 'rehype-sanitize';

export interface CommentFormProps {
  post_id?: number;
  initialValue?: string;
  onCancel?: () => void;
  onSubmit?: (comment: string) => Promise<void>;
  isEditing?: boolean;
}

const CommentForm: React.FC<CommentFormProps> = ({ 
  post_id,
  initialValue = '',
  onCancel,
  onSubmit,
  isEditing = false,
}) => {
  const [comment, setComment] = useState<string>(initialValue);
  const { user } = useAuth();
  const { t } = useTranslation();
  const { userID, authenticatedUserEmail } = useUser();

  const client = useApolloClient();

  const [ insertCommentMutation ] = useMutation(gql`
    mutation InsertComment(
      $comment: String!,
      $forum_post_id: bigint!,
      $user_id: bigint!,
      $username: String!,
      $email: String
    ) {
      insert_forum_post_comments_one(
        object: {
          comment: $comment,
          forum_post_id: $forum_post_id,
          user_id: $user_id,
          username: $username,
          email: $email
        }
      ) {
        id
      }
    }
  `);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (onSubmit) {
      await onSubmit(comment);
    } else {
      await insertCommentMutation({
        variables: {
          comment,
          forum_post_id: post_id,
          user_id: userID ?? 0,
          username: user?.profile.name ?? '',
          email: authenticatedUserEmail ?? '',
        }
      });
      await client.resetStore();
      setComment('');
    }
  };

  return (
    <Box sx={{ width: '100%' }}>
      <form onSubmit={handleSubmit}>
        <Box sx={{ display: 'flex', gap: 2 }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="caption" color="text.secondary" sx={{ mb: 1, display: 'block' }}>
              {t('forum.markdown_supported')}
            </Typography>
            <Box sx={{
              mb: 2,
              '& .w-md-editor': {
                backgroundColor: 'background.paper',
                border: '1px solid',
                borderColor: 'divider',
                borderRadius: 1,
              },
              '& .w-md-editor-toolbar': {
                borderBottom: '1px solid',
                borderColor: 'divider',
                backgroundColor: 'background.default',
              },
              '& .w-md-editor-text': {
                fontSize: '0.875rem',
              },
              '& .wmde-markdown-color': { 
                backgroundColor: 'transparent',
              },
              '& .w-md-editor-preview': {
                boxSizing: 'border-box',
                backgroundColor: 'background.default',
                borderLeft: '1px solid',
                borderColor: 'divider',
              }
            }}>
              <div data-color-mode="light">
                <MDEditor
                  value={comment}
                  onChange={(value) => setComment(value || '')}
                  preview="live"
                  height={150}
                  visibleDragbar={false}
                  previewOptions={{
                    rehypePlugins: [[rehypeSanitize]],
                  }}
                />
              </div>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 1 }}>
              {onCancel && (
                <Button
                  size="small"
                  onClick={onCancel}
                >
                  {t('forum.cancel')}
                </Button>
              )}
              <Button
                type="submit"
                size="small"
                variant="contained"
                disabled={!comment.trim() || (isEditing && comment === initialValue)}
              >
                {isEditing ? t('forum.save_changes') : t('forum.comment')}
              </Button>
            </Box>
          </Box>
        </Box>
      </form>
    </Box>
  );
};

export default CommentForm;
