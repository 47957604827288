import React from "react";
import { useAuth } from "react-oidc-context";
import axios from "axios";
import useUser from "./useUser";

var defaultApiURL = process.env.REACT_APP_API_URL ?? 'https://api.stream.dromt.it/v1alpha1';

function joinPath(...paths: string[]) {
  const isHttp = paths[0].startsWith('http://');
  const isHttps = paths[0].startsWith('https://');
  if (isHttp) {
    paths[0] = paths[0].replace('http://', '');
    return 'http://'+paths.join('/').replace(/\/+/g, '/');
  }
  if (isHttps) {
    paths[0] = paths[0].replace('https://', '');
    return 'https://'+paths.join('/').replace(/\/+/g, '/');
  }
  return paths.join('/').replace(/\/+/g, '/');
}

function useApi<T>(apiURL?: string) {
  const [baseURL, setBaseURL] = React.useState(apiURL ?? defaultApiURL);

  const auth = useAuth();
  const { organizationID: organizationID_Int } = useUser();

  const optionalHeaders = () => {
    let headers: any = {};

    const organizationID = localStorage.getItem('currentOrganizationId');
    if (organizationID) {
      headers['Current-Organization'] = organizationID;
    }

    if (organizationID_Int) {
      headers['Current-Organization-ID'] = organizationID_Int;
    }

    return headers;
  }

  const get = React.useCallback((url: string): Promise<T> => {
    return new Promise<T>(async(resolve, reject) => {
      try {
        const resp = await axios.get(joinPath(baseURL, url), {
          withCredentials: false,
          headers: {
            'Authorization': `Bearer ${auth.user?.access_token}`,
            ...optionalHeaders(),
          },
        });

        resolve(resp.data);
      } catch (e) {
        reject(e);
      }
    });
  }, [auth.user?.access_token, baseURL, organizationID_Int]);

  const getBlob = React.useCallback((url: string): Promise<Blob> => {
    return new Promise<Blob>(async(resolve, reject) => {
      try {
        const resp = await axios.get(joinPath(baseURL, url), {
          withCredentials: false,
          headers: {
            'Authorization': `Bearer ${auth.user?.access_token}`,
            ...optionalHeaders(),
          },
          responseType: 'blob',
        });

        resolve(resp.data);
      } catch (e) {
        reject(e);
      }
    });
  }, [auth.user?.access_token, baseURL, organizationID_Int]);

  const post = React.useCallback((url: string, data?: any, headers?: any): Promise<T> => {
    return new Promise<T>(async(resolve, reject) => {
      try {
        const resp = await axios.post(joinPath(baseURL, url), data, {
          withCredentials: false,
          headers: {
            'Authorization': `Bearer ${auth.user?.access_token}`,
            'Content-Type': 'application/json',
            ...optionalHeaders(),
            ...headers
          },
        });

        resolve(resp.data);
      } catch (e) {
        reject(e);
      }
    });
  }, [auth.user?.access_token, baseURL, organizationID_Int]);

  const put = React.useCallback((url: string, data?: any): Promise<T> => {
    return new Promise<T>(async(resolve, reject) => {
      try {
        const resp = await axios.put(joinPath(baseURL, url), data, {
          withCredentials: false,
          headers: {
            'Authorization': `Bearer ${auth.user?.access_token}`,
            'Content-Type': 'application/json',
            ...optionalHeaders(),
          },
        });

        resolve(resp.data);
      } catch (e) {
        reject(e);
      }
    });
  }, [auth.user?.access_token, baseURL, organizationID_Int]);

  const del = React.useCallback((url: string): Promise<T> => {
    return new Promise<T>(async(resolve, reject) => {
      try {
        const resp = await axios.delete(joinPath(baseURL, url), {
          withCredentials: false,
          headers: {
            'Authorization': `Bearer ${auth.user?.access_token}`,
            ...optionalHeaders(),
          },
        });

        resolve(resp.data);
      } catch (e) {
        reject(e);
      }
    });
  }, [auth.user?.access_token, baseURL, organizationID_Int]);

  return { get, getBlob, post, put, del };
}

export default useApi;
