import React from 'react';
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { APIProvider } from '@vis.gl/react-google-maps';
import { useAuth, withAuthenticationRequired } from 'react-oidc-context';
import { OrganizationContext } from './common/contexts/organization';
import Layout from './layouts/Layout';
import { ApolloClient, InMemoryCache, ApolloProvider, createHttpLink, InMemoryCacheConfig, ObservableQuery, split } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { getMainDefinition } from '@apollo/client/utilities';
import { createClient } from 'graphql-ws';
import { PositionContext, defaultPositionManager } from './common/contexts/position';
import { graphqlURL, gtmId } from './config';
import { initDB } from './common/db/db';
import i18n from "i18next";
import FrameLayout from './layouts/FrameLayout';
import theme from './theme';
import 'flag-icons/css/flag-icons.min.css';
import Frame from './components/Frame';
import Plans from './pages/Plans';
import Missions from './pages/Missions';
import Dashboard from './pages/Dashboard';
import Organizations from './pages/Organizations';
import NewAreaFlightPlan from './pages/flightplans/NewAreaFlightPlan';
import Fleet from './pages/Fleet';
import Redirect from './common/components/Redirect';
import NewDrone from './pages/fleet/NewDrone';
import Areas from './pages/Areas';
import NewArea from './pages/areas/NewArea';
import EditArea from './pages/areas/EditArea';
import Reports from './pages/Reports';
import ShowFlightPlan from './pages/flightplans/ShowFlightPlan';
import Download from './pages/Download';
import DownloadManual from './pages/DownloadManually';
import Loading from './common/components/Loading';
import PilotLayout from './pages/pilot/PilotLayout';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import TagManager from 'react-gtm-module';
import TrackPageView from './common/components/TrackPageView';
import RoleGuard from './common/guards/RoleGuard';
import ScrollLayout from './layouts/ScrollLayout';
import FileExplorerView from './pages/pilot/resources/FileExplorerView';
import PDFView from './pages/pilot/resources/PDFView';
import { Snackbar, Button, IconButton, Stack } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useServiceWorkerUpdate } from './hooks/useServiceWorkerUpdate';

const PilotView = React.lazy(() => import('./pages/pilot/PilotView'));
const ForumPage = React.lazy(() => import('./pages/community/forum'));
const PostDetails = React.lazy(() => import('./pages/community/forum/PostDetails'));
const CreatePost = React.lazy(() => import('./pages/community/forum/CreatePost'));
const Learn = React.lazy(() => import('./pages/Learn'));
const PDFReader = React.lazy(() => import('./pages/learn/PDFReader'));
const Questions = React.lazy(() => import('./pages/learn/Questions'));
const ExpertList = React.lazy(() => import('./pages/learn/experts/ExpertList'));
const ExpertDetails = React.lazy(() => import('./pages/learn/experts/ExpertDetails'));

function App() {
  const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY ?? 'AIzaSyD68kpit7GVfUExBXN8EwiKmBH9pk-34lA';

  const [ selectedOrganizationID, setSelectedOrganizationID ] = React.useState<string | null>(localStorage.getItem('currentOrganizationId'));
  const organizationCtxValue = { organizationID: selectedOrganizationID, setOrganizationID: setSelectedOrganizationID };

  const [ client, setClient ] = React.useState<ApolloClient<any> | null>(null);
  const { isUpdateAvailable, applyUpdate } = useServiceWorkerUpdate();
  const [ showUpdateSnackbar, setShowUpdateSnackbar ] = React.useState(false);

  React.useEffect(() => {
    if (isUpdateAvailable) {
      setShowUpdateSnackbar(true);
    }
  }, [isUpdateAvailable]);

  document.documentElement.lang = i18n.resolvedLanguage ?? 'en';

  initDB('hub');

  const auth = useAuth();

  const positionManager = defaultPositionManager;

  React.useEffect(() => {
    if (!gtmId) {
      console.warn('GTM_ID is not set');
      return;
    }

    const tagManagerArgs = {
      gtmId,
    };
    TagManager.initialize(tagManagerArgs);
  }, []);

  React.useEffect(() => {
    if (!auth.user || auth.user.expired) {
      return;
    }

    const authLink = setContext((_, { headers }) => {
      // get the authentication token from local storage if it exists
      const token = auth.user?.access_token;
      // return the headers to the context so httpLink can read them
      const h = {
        headers: {
          ...headers,
          authorization: token ? `Bearer ${token}` : "",
        }
      }
      if (selectedOrganizationID) {
        h.headers['current-organization'] = selectedOrganizationID;
      }
      return h;
    });
  
    const httpLink = createHttpLink({
      uri: graphqlURL,
    });

    const wsLink = new GraphQLWsLink(createClient({
      url: graphqlURL,
      connectionParams: {
        reconnect: true,
        headers: {
          'content-type': 'application/json',
          'authorization': `Bearer ${auth.user?.access_token}`,
          'x-hasura-role': 'user',
          ...(selectedOrganizationID && { 'current-organization': selectedOrganizationID }),
        },
      },
    }));

    const splitLink = split(
      ({ query }) => {
        const definition = getMainDefinition(query);
        return (
          definition.kind === 'OperationDefinition' &&
          definition.operation === 'subscription'
        );
      },
      authLink.concat(wsLink),
      authLink.concat(httpLink)
    );

    if (!client) {
      const wait = (ms: number) => new Promise((res) => setTimeout(res, ms))
      const delayRefetchedQuery = async (observableQuery: ObservableQuery) => {
        await wait(100);
        observableQuery.refetch();
      }

      setClient(new ApolloClient({
        link: splitLink,
        cache: new InMemoryCache({
          resultCaching: true,
        } as InMemoryCacheConfig),
        defaultOptions: {
          mutate: {
            onQueryUpdated: delayRefetchedQuery,
          },
        },
        connectToDevTools: true,
      }));
    } else {
      client.setLink(splitLink);
    }
  }, [auth.user?.access_token]);

  // import active drone and organization from url
  React.useEffect(() => {
    if (!auth.user) {
      return;
    }

    const url = new URL(window.location.href);
    const organizationID = url.searchParams.get('organization');

    if (organizationID) {
      setSelectedOrganizationID(organizationID);
      window.history.replaceState({}, document.title, window.location.pathname);
    }
  }, [auth.user]);

  React.useEffect(() => {
    if (!auth.user) {
      return;
    }

    const currentOrganizationId = localStorage.getItem('currentOrganizationId');

    if (selectedOrganizationID) {
      localStorage.setItem('currentOrganizationId', selectedOrganizationID);
    } else {
      localStorage.removeItem('currentOrganizationId');
    }

    if (currentOrganizationId !== selectedOrganizationID) {
      window.location.reload();
    }

    if (auth.user.expired) {
      return;
    }
  }, [selectedOrganizationID, auth.user]);

  if (auth.isLoading || !client) {
    return (
      <Loading open />
    );
  }

  if (auth.error) {
    window.location.href = '/';
    return <div>Oops... {auth.error.message}</div>;
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <ApolloProvider client={client}>
        <APIProvider apiKey={apiKey}>
          <PositionContext.Provider value={positionManager}>
            <OrganizationContext.Provider value={organizationCtxValue}>
              <BrowserRouter>
                <TrackPageView />
                <Routes>
                  <Route path="/" element={<Layout />}>
                    <Route index element={<Dashboard />} />
                    <Route path="pilot" element={<RoleGuard requiredRoles={["pilot"]} featureName="pilot"><FrameLayout theme={theme} fullHeight /></RoleGuard>}>
                      <Route index element={<Redirect to="/pilot/profile" />} />
                      <Route path="profile" element={<PilotLayout />} />
                      <Route path="profile/view" element={
                        <React.Suspense fallback={<Loading open />}>
                          <PilotView />
                        </React.Suspense>
                      } />
                      <Route path="fleet" element={null}>
                        <Route index element={<Fleet viewKind="pilot" />} />
                        <Route path="new" element={null}>
                          <Route index element={<NewDrone />} />
                        </Route>
                      </Route>
                      <Route path="resources" element={<ScrollLayout height="100%" />}>
                        <Route index element={<FileExplorerView />} />
                        <Route path=":folderId" element={<FileExplorerView />} />
                        <Route path="view/:fileUUID" element={<PDFView />} />
                      </Route>
                      <Route path="download" element={<Download />} />
                      <Route path="download/manual" element={<DownloadManual />} />
                    </Route>
                    <Route path="community" element={<ScrollLayout />}>
                      <Route index element={<Redirect to="/community/forum" />} />
                      <Route path="forum">
                        <Route index element={
                          <React.Suspense fallback={<Loading open />}>
                            <ForumPage />
                          </React.Suspense>
                        } />
                        <Route path=":postUUID" element={
                          <React.Suspense fallback={<Loading open />}>
                            <PostDetails />
                          </React.Suspense>
                        } />
                        <Route path="create" element={
                          <React.Suspense fallback={<Loading open />}>
                            <CreatePost />
                          </React.Suspense>
                        } />
                        <Route path="edit/:postUUID" element={
                          <React.Suspense fallback={<Loading open />}>
                            <CreatePost isEditing />
                          </React.Suspense>
                        } />
                      </Route>
                    </Route>
                    <Route path="learn" element={<FrameLayout theme={theme} fullHeight />}>
                      <Route index element={<Redirect to="/learn/licenses" />} />
                      <Route path="licenses" element={null}>
                        <Route index element={
                          <React.Suspense fallback={<Loading open />}>
                            <Learn />
                          </React.Suspense>
                        } />
                        <Route path=":learnableUUID" element={
                          <React.Suspense fallback={<Loading open />}>
                            <PDFReader />
                          </React.Suspense>
                        } />
                      </Route>
                      <Route path="questions" element={null}>
                        <Route index element={
                          <React.Suspense fallback={<Loading open />}>
                            <Questions />
                          </React.Suspense>
                        } />
                      </Route>
                    </Route>
                    <Route path="learn/experts" element={<ScrollLayout />}>
                      <Route index element={
                        <React.Suspense fallback={<Loading open />}>
                          <ExpertList />
                        </React.Suspense>
                      } />
                      <Route path=":expertId" element={
                        <React.Suspense fallback={<Loading open />}>
                          <ExpertDetails />
                        </React.Suspense>
                      } />
                    </Route>
                    <Route path="fly" element={<RoleGuard requiredRoles={["pilot"]} featureName="pilot"><FrameLayout theme={theme} fullHeight /></RoleGuard>}>
                      <Route index element={<Redirect to="/fly/fleet" />} />
                      <Route path="fleet" element={null}>
                        <Route index element={<Fleet viewKind="flight" />} />
                        <Route path="new" element={null}>
                          <Route index element={<NewDrone />} />
                        </Route>
                      </Route>
                      <Route path="areas" element={null}>
                        <Route index element={<Areas />} />
                        <Route path="new" element={null}>
                          <Route index element={<NewArea />} />
                          <Route path=":fieldUUID" element={<NewArea />} />
                        </Route>
                        <Route path=":fieldUUID" element={null}>
                          <Route index element={<EditArea />} />
                        </Route>
                      </Route>
                      <Route path="plans" element={null}>
                        <Route index element={<Plans />} />
                        <Route path=':flightPlanUUID' element={null}>
                          <Route index element={<ShowFlightPlan />} />
                        </Route>
                        <Route path="new" element={null}>
                          <Route path=":type" element={<NewAreaFlightPlan />}>
                            <Route path=":fieldUUID" element={<NewAreaFlightPlan />} />
                          </Route>
                        </Route>
                      </Route>
                      <Route path="reports" element={null}>
                        <Route index element={<Reports />} />
                      </Route>
                      <Route path="organizations" element={<Organizations />} />
                    </Route>
                    <Route path="fly/missions" element={<Missions />} />
                    <Route path="agriculture" element={<FrameLayout theme={theme} fullHeight />}>
                      <Route index element={<Frame url='https://agriculture.dromt.it/app' requiredRoles={['agriculture_extensive']} featureName='agriculture' />} />
                      <Route path=":path" element={<Frame url='https://agriculture.dromt.it/app' requiredRoles={['agriculture_extensive']} featureName='agriculture' />} />
                    </Route>
                    <Route path="photogrammetry" element={<FrameLayout theme={theme} fullHeight />}>
                      <Route index element={<Frame url='https://photogrammetry.dromt.it/app' requiredRoles={['photogrammetry_base']} featureName='photogrammetry' />} />
                      <Route path=":path" element={<Frame url='https://photogrammetry.dromt.it/app' requiredRoles={['photogrammetry_base']} featureName='photogrammetry' />} />
                    </Route>
                  </Route>
                </Routes>
              </BrowserRouter>
            </OrganizationContext.Provider>
          </PositionContext.Provider>
        </APIProvider>
      </ApolloProvider>
      <Snackbar
        open={showUpdateSnackbar}
        message={i18n.t('common.update.available')}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        autoHideDuration={5000}
        onClose={() => {
          setShowUpdateSnackbar(false);
        }}
        action={
          <Stack direction="row" spacing={1} alignItems="center">
            <Button color="secondary" size="small" onClick={async () => await applyUpdate()}>
              {i18n.t('common.update.now')}
            </Button>
            <IconButton
              size="small"
              color="inherit"
              onClick={() => setShowUpdateSnackbar(false)}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </Stack>
        }
      />
    </LocalizationProvider>
  );
}

export default withAuthenticationRequired(App);
