import React from 'react';
import { useTranslation } from 'react-i18next';
import { Snackbar, Alert } from '@mui/material';
import HelpDialog from '../dialogs/HelpDialog';
import axios from 'axios';

export interface HelpData {
  message: string;
  email: string;
  page: string;
}

export default function useHelp() {
  const { t } = useTranslation();
  const [helpDialogOpen, setHelpDialogOpen] = React.useState(false);
  const [snackbar, setSnackbar] = React.useState<{
    open: boolean;
    message: string;
    severity: 'success' | 'error';
  }>({
    open: false,
    message: '',
    severity: 'success'
  });

  const submitHelp = async(data: HelpData) => {
    await axios.post("https://n8n.dromt.it/webhook/0a738965-bb50-47b7-aaa9-42bf744a7abb", data);
  }

  const handleClose = async (data?: HelpData) => {
    setHelpDialogOpen(false);

    if (data) {
      try {
        // TODO: Replace with actual API call
        await submitHelp(data);

        setSnackbar({
          open: true,
          message: t('Your message has been submitted successfully'),
          severity: 'success'
        });
      } catch (error) {
        setSnackbar({
          open: true,
          message: t('Failed to submit your message. Please try again later.'),
          severity: 'error'
        });
      }
    }
  };

  const handleSnackbarClose = () => {
    setSnackbar(prev => ({ ...prev, open: false }));
  };

  const helpDialog = (
    <React.Fragment>
      <HelpDialog
        open={helpDialogOpen}
        onClose={handleClose}
      />
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert 
          onClose={handleSnackbarClose} 
          severity={snackbar.severity}
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </React.Fragment>
  );

  return {
    helpDialog,
    setHelpDialogOpen
  };
}
