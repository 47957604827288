import React from 'react';
import { Backdrop, Box, Fab, Grow } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import CloseIcon from '@mui/icons-material/Close';
import { CertificateButton } from './CertificateButton';
import { LogBookButton } from './LogBookButton';
import { InsuranceButton } from './InsuranceButton';
import { Aggregate } from '../../pages/Dashboard';

interface MobileButtonsProps {
  certificateData?: Aggregate;
  onLogBookClick?: () => void;
  insuranceData?: any[];
}

export const MobileButtons: React.FC<MobileButtonsProps> = ({
  certificateData,
  onLogBookClick,
  insuranceData,
}) => {
  const [isOpen, setIsOpen] = React.useState(false);

  const handleClose = () => setIsOpen(false);

  return (
    <React.Fragment>
      <Fab
        onClick={() => setIsOpen(!isOpen)}
        sx={{
          position: 'fixed',
          top: 80,
          right: 16,
          zIndex: 1300
        }}
      >
        {isOpen ? <CloseIcon /> : <PersonIcon />}
      </Fab>

      <Backdrop
        open={isOpen}
        onClick={handleClose}
        sx={{ 
          zIndex: 1200,
          backgroundColor: 'rgba(0, 0, 0, 0.2)'
        }}
      />

      <Grow 
        in={isOpen}
        mountOnEnter
        unmountOnExit
        timeout={{
          enter: 200,
          exit: 150
        }}
      >
        <Box
          sx={{
            position: 'fixed',
            top: 144,
            right: 16,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            gap: 2,
            maxHeight: 'calc(100vh - 200px)',
            overflowY: 'auto',
            transformOrigin: 'top right',
            zIndex: 1300
          }}
        >
          <CertificateButton
            data={certificateData}
          />
          <LogBookButton
            onClick={onLogBookClick}
            disabled
          />
          <InsuranceButton
            data={insuranceData}
          />
        </Box>
      </Grow>
    </React.Fragment>
  );
};
