import React from 'react';
import { Card, CardContent, Typography, Box, Stack, Skeleton, IconButton, Link } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Weather } from '../../common/hooks/useWeather';
import AirIcon from '@mui/icons-material/Air';
import ThermostatIcon from '@mui/icons-material/Thermostat';
import ExploreIcon from '@mui/icons-material/Explore';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import TimelineIcon from '@mui/icons-material/Timeline';

interface WeatherCardProps {
  weather?: Weather;
  sx?: React.CSSProperties;
  collapsed?: boolean;
  onToggleCollapse?: () => void;
  small?: boolean;
}

export const WeatherCard: React.FC<WeatherCardProps> = ({ 
  weather, 
  sx, 
  collapsed = false,
  onToggleCollapse,
  small = false
}) => {
  const { t } = useTranslation();
  const width = small ? 250 : 300;

  if (!weather) {
    return (
      <Card sx={{ width, ...sx }}>
        <CardContent sx={{ p: collapsed ? 1 : 2, '&:last-child': { pb: collapsed ? 1 : 2 } }}>
          {collapsed ? (
            <Stack direction="row" spacing={1} alignItems="center">
              {!small && <Skeleton variant="circular" width={20} height={20} />}
              <Skeleton variant="text" width={40} />
              {!small && <Skeleton variant="circular" width={20} height={20} />}
              <Skeleton variant="text" width={40} />
              {!small && <Skeleton variant="circular" width={20} height={20} />}
              <Skeleton variant="text" width={40} />
            </Stack>
          ) : (
            <>
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                <Skeleton variant="circular" width={50} height={50} sx={{ mr: 2 }} />
                <Skeleton variant="text" width={120} />
              </Box>

              <Stack spacing={2}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Skeleton variant="circular" width={24} height={24} sx={{ mr: 1 }} />
                  <Box sx={{ flex: 1 }}>
                    <Skeleton variant="text" width={80} />
                    <Skeleton variant="text" width={60} />
                  </Box>
                </Box>

                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Skeleton variant="circular" width={24} height={24} sx={{ mr: 1 }} />
                  <Box sx={{ flex: 1 }}>
                    <Skeleton variant="text" width={80} />
                    <Skeleton variant="text" width={60} />
                  </Box>
                </Box>

                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Skeleton variant="circular" width={24} height={24} sx={{ mr: 1 }} />
                  <Box sx={{ flex: 1 }}>
                    <Skeleton variant="text" width={80} />
                    <Skeleton variant="text" width={60} />
                  </Box>
                </Box>
              </Stack>
            </>
          )}
        </CardContent>
      </Card>
    );
  }

  if (collapsed) {
    return (
      <Card sx={{ width, ...sx }}>
        <CardContent sx={{ p: 1, '&:last-child': { pb: 1 } }}>
          <Stack 
            direction="row" 
            spacing={1} 
            alignItems="center"
            sx={{ 
              '& .MuiTypography-root': { 
                fontSize: '0.75rem',
                whiteSpace: 'nowrap'
              }
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5, minWidth: 0, flex: 1 }}>
              {!small && (
                <img 
                  src={weather.condition.icon} 
                  alt={weather.condition.text}
                  style={{ width: 20, height: 20 }}
                />
              )}
              <Typography sx={{ 
                flex: 1, 
                overflow: 'hidden', 
                textOverflow: 'ellipsis'
              }}>
                {weather.condition.text}
              </Typography>
            </Box>

            <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
              {!small && <ThermostatIcon sx={{ fontSize: 16, color: 'text.secondary' }} />}
              <Typography>
                {weather.temperature}{t('dashboard.weather.celsius')}
              </Typography>
            </Box>

            <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
              {!small && <AirIcon sx={{ fontSize: 16, color: 'text.secondary' }} />}
              <Typography>
                {weather.windSpeed}{t('dashboard.weather.metersPerSecond')}
              </Typography>
            </Box>

            <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
              {!small && (
                <ExploreIcon 
                  sx={{ 
                    fontSize: 16,
                    color: 'text.secondary',
                    transform: `rotate(${weather.windDirection}deg)`,
                    transition: 'transform 0.3s ease-in-out'
                  }} 
                />
              )}
              <Typography>
                {weather.windDirection}
              </Typography>
            </Box>

            <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
              {!small && <TimelineIcon sx={{ fontSize: 16, color: 'text.secondary' }} />}
              <Typography>
                {t('dashboard.weather.pki')}: {weather.pki.value}
              </Typography>
            </Box>

            {onToggleCollapse && (
              <IconButton 
                size="small" 
                onClick={onToggleCollapse}
                sx={{ ml: 0.5, p: 0.5 }}
              >
                <ExpandMoreIcon sx={{ fontSize: 16 }} />
              </IconButton>
            )}
          </Stack>
        </CardContent>
      </Card>
    );
  }

  return (
    <Card sx={{ width, ...sx }}>
      <CardContent style={{ paddingBottom: 5 }}>
        <Box sx={{ 
          display: 'flex', 
          justifyContent: 'space-between', 
          alignItems: 'flex-start'
        }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <img 
              src={weather.condition.icon} 
              alt={weather.condition.text}
              style={{ width: 50, height: 50, marginRight: 16 }}
            />
            <Typography variant="h6">
              {weather.condition.text}
            </Typography>
          </Box>
          {onToggleCollapse && (
            <IconButton 
              size="small" 
              onClick={onToggleCollapse}
              sx={{ mt: -0.5, mr: -0.5 }}
            >
              <ExpandLessIcon fontSize="small" />
            </IconButton>
          )}
        </Box>

        <Stack spacing={2}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <ThermostatIcon sx={{ mr: 1, color: 'text.secondary' }} />
            <Box>
              <Typography variant="body2" color="text.secondary">
                {t('dashboard.weather.temperature')}
              </Typography>
              <Typography variant="body1" fontWeight={500}>
                {weather.temperature}{t('dashboard.weather.celsius')}
              </Typography>
            </Box>
          </Box>

          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <AirIcon sx={{ mr: 1, color: 'text.secondary' }} />
            <Box>
              <Typography variant="body2" color="text.secondary">
                {t('dashboard.weather.windSpeed')}
              </Typography>
              <Typography variant="body1" fontWeight={500}>
                {weather.windSpeed}{t('dashboard.weather.metersPerSecond')}
              </Typography>
            </Box>
          </Box>

          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <ExploreIcon 
              sx={{ 
                mr: 1, 
                color: 'text.secondary',
                transition: 'transform 0.3s ease-in-out'
              }} 
            />
            <Box>
              <Typography variant="body2" color="text.secondary">
                {t('dashboard.weather.windDirection')}
              </Typography>
              <Typography variant="body1" fontWeight={500}>
                {weather.windDirection}
              </Typography>
            </Box>
          </Box>

          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <TimelineIcon sx={{ mr: 1, color: 'text.secondary' }} />
            <Box>
              <Typography variant="body2" color="text.secondary">
                {t('dashboard.weather.pkiValue')}
              </Typography>
              <Typography variant="body1" fontWeight={500}>
                {weather.pki.value}
              </Typography>
            </Box>
          </Box>
        </Stack>
        <Box sx={{ mt: 2, textAlign: 'center' }}>
          <Typography 
            variant="caption" 
            color="text.secondary" 
            component="div"
            sx={{ 
              fontSize: '0.65rem',
              opacity: 0.7
            }}
          >
            Powered by{' '}
            <Link
              href="https://www.weatherapi.com/"
              target="_blank"
              rel="noopener noreferrer"
              title="Free Weather API"
              sx={{ 
                color: 'inherit',
                textDecoration: 'none',
                '&:hover': {
                  textDecoration: 'underline'
                }
              }}
            >
              WeatherAPI.com
            </Link>
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
};
