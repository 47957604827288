import React from 'react';
import { Box, TextField, FormControlLabel, Switch, Button } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useTranslation } from 'react-i18next';
import { PersonalInfoData } from '../../../classes/PilotProfile';
import dayjs from 'dayjs';
import { gql, useMutation } from '@apollo/client';
import useUser from '../../../common/hooks/useUser';
import LocationAutocomplete from '../../LocationAutocomplete';

interface PersonalInfoProps {
  data: PersonalInfoData;
}

const PersonalInfo: React.FC<PersonalInfoProps> = ({ data }) => {
  const [ newData, setNewData ] = React.useState(data);

  const { userID } = useUser();
  const { t } = useTranslation();

  const [ personalInfoMutationUpdate ] = useMutation(gql`
    mutation UpdatePersonalInfo(
      $id: bigint!,
      $first_name: String,
      $last_name: String,
      $birth_date: String,
      $email: String,
      $phone: String,
      $city: String,
      $is_public: Boolean
    ) {
      update_personal_infos_by_pk(pk_columns: {id: $id}, _set: {
        first_name: $first_name,
        last_name: $last_name,
        birth_date: $birth_date,
        email: $email,
        phone: $phone,
        city: $city,
        is_public: $is_public
      }) {
        id
      }
    }
  `, {
    refetchQueries: ['UserProfiles']
  });
  const [ personalInfoMutationInsert ] = useMutation(gql`
    mutation InsertPersonalInfo(
      $user_id: bigint!,
      $first_name: String,
      $last_name: String,
      $birth_date: String,
      $email: String,
      $phone: String,
      $city: String,
      $is_public: Boolean
    ) {
      insert_personal_infos(objects: {
        user_id: $user_id,
        first_name: $first_name,
        last_name: $last_name,
        birth_date: $birth_date,
        email: $email,
        phone: $phone,
        city: $city,
        is_public: $is_public
      }) {
        affected_rows
      }
    }
  `, {
    refetchQueries: ['UserProfiles']
  });

  const handleChange = (field: keyof PersonalInfoData) => (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (!event.target) return;
    const value = field === 'isPublic' ? event.target.checked : event.target.value;
    setNewData(prevState => ({ ...prevState, [field]: value }));
  }

  const handleChangeDate = (field: keyof PersonalInfoData) => (
    date: any
  ) => {
    if (!date) return;
    const value = dayjs(date).format('YYYY-MM-DD');
    setNewData(prevState => ({ ...prevState, [field]: value }));
  }

  const handleSave = () => {
    if (!data.id) {
      personalInfoMutationInsert({
        variables: {
          user_id: userID,
          first_name: newData.firstName,
          last_name: newData.lastName,
          birth_date: newData.birthDate,
          email: newData.email,
          phone: newData.phone,
          city: newData.city,
          is_public: newData.isPublic
        }
      });
    } else {
      personalInfoMutationUpdate({
        variables: {
          id: newData.id,
          first_name: newData.firstName,
          last_name: newData.lastName,
          birth_date: newData.birthDate,
          email: newData.email,
          phone: newData.phone,
          city: newData.city,
          is_public: newData.isPublic
        }
      });
    }
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, p: 2 }}>
      <TextField
        label={t('pilot.fields.firstName')}
        value={newData.firstName}
        onChange={handleChange('firstName')}
        fullWidth
        required
      />
      <TextField
        label={t('pilot.fields.lastName')}
        value={newData.lastName}
        onChange={handleChange('lastName')}
        fullWidth
        required
      />
      <DatePicker
        label={t('pilot.fields.birthDate')}
        value={dayjs(newData.birthDate)}
        onChange={handleChangeDate('birthDate')}
      />
      <TextField
        label={t('pilot.fields.email')}
        value={newData.email}
        onChange={handleChange('email')}
        type="email"
        fullWidth
        required
      />
      <TextField
        label={t('pilot.fields.phone')}
        value={newData.phone}
        onChange={handleChange('phone')}
        fullWidth
      />

      <LocationAutocomplete
        label={t('pilot.fields.city')}
        value={{ description: newData.city, place_id: '' }}
        onChange={(location) => {
          if (location) {
            // TODO: save location id and/or coordinates
            setNewData(prevState => ({ ...prevState, city: location.description }));
          }
        }}
      />

      <FormControlLabel
        control={
          <Switch
            checked={newData.isPublic}
            onChange={handleChange('isPublic')}
          />
        }
        label={t('pilot.fields.publicProfile')}
      />
      <Button
        variant="contained"
        color="primary"
        onClick={handleSave}
      >
        {t('Save')}
      </Button>
    </Box>
  );
};

export default PersonalInfo;
