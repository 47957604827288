import React from 'react';
import {
  ButtonGroup,
  IconButton,
  Tooltip,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import { useNavigate } from 'react-router-dom';
import { gql, useApolloClient, useMutation } from '@apollo/client';
import { ForumPost } from '../../../classes/forum';
import ConfirmDialog from '../../../common/dialogs/ConfirmDialog';
import { useTranslation } from 'react-i18next';

interface PostButtonsProps {
  post: ForumPost;
}

const PostButtons: React.FC<PostButtonsProps> = ({ post }) => {
  const navigate = useNavigate();
  const client = useApolloClient();
  const { t } = useTranslation();
  const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);

  const [ postStatusMutation ] = useMutation(gql`
    mutation UpdatePostStatus($id: bigint!, $status: String!) {
      update_forum_posts_by_pk(pk_columns: { id: $id }, _set: { current_status: $status }) {
        current_status
      }
    }
  `);

  const [ deletePostMutation ] = useMutation(gql`
    mutation DeletePost($id: bigint!) {
      update_forum_posts_by_pk(pk_columns: { id: $id }, _set: { deleted_at: "now()" }) {
        deleted_at
      }
    }
  `);

  const handleStatusChange = async (newStatus: string) => {
    await postStatusMutation({
      variables: {
        id: post.id,
        status: newStatus,
      },
    });
    await client.clearStore();
    window.location.reload();
  };

  return (
    <>
      <ButtonGroup size="small" onClick={(e) => e.stopPropagation()}>
        {post.currentStatus === 'Open' && (
          <Tooltip title={t('forum.mark_resolved')}>
            <IconButton
              style={{ padding: 4 }}
              onClick={() => handleStatusChange('Resolved')}
            >
              <TaskAltIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        )}
        {post.currentStatus === 'Resolved' && (
          <Tooltip title={t('forum.reopen_post')}>
            <IconButton
              style={{ padding: 4 }}
              onClick={() => handleStatusChange('Open')}
            >
              <TaskAltIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        )}
        <Tooltip title={t('forum.edit_post')}>
          <IconButton
            style={{ padding: 4 }}
            onClick={() => navigate(`/community/forum/edit/${post.id}`)}
          >
            <EditIcon fontSize="small" />
          </IconButton>
        </Tooltip>
        <Tooltip title={t('forum.delete_post')}>
          <IconButton
            style={{ padding: 4 }}
            onClick={() => setDeleteDialogOpen(true)}
            color="error"
          >
            <DeleteIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      </ButtonGroup>

      <ConfirmDialog
        open={deleteDialogOpen}
        onClose={async (confirmed) => {
          setDeleteDialogOpen(false);
          if (confirmed) {
            await deletePostMutation({
              variables: {
                id: post.id,
              },
            });
            await client.clearStore();
            navigate('/community/forum');
          }
        }}
        title={t('forum.delete_post')}
        body={t('forum.delete_post_confirm')}
        confirmColor="error"
      />
    </>
  );
};

export default PostButtons;
