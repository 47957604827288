import React from 'react';
import { Card, CardContent, Typography, Button, Stack, Box, IconButton } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { PositionContext } from '../../common/contexts/position';
import { FlightZone, limitToColor } from '../../common/hooks/useFlightZones';
import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { getTextColor } from '../../common/utils/color';
import { useMapsLibrary } from '@vis.gl/react-google-maps';

interface CurrentPositionProps {
  sx?: React.CSSProperties;
  selectedPosition?: google.maps.LatLngLiteral;
  selectedZones?: FlightZone[];
  onClear?: () => void;
  collapsible?: boolean;
  collapsed?: boolean;
  setCollapsed?: (collapsed: boolean) => void;
}

export const CurrentPosition: React.FC<CurrentPositionProps> = ({ 
  sx, 
  selectedPosition, 
  selectedZones, 
  onClear,
  collapsible,
  collapsed,
  setCollapsed
}) => {
  const positionCtx = React.useContext(PositionContext);
  const { t } = useTranslation();

  const [ position, setPosition ] = React.useState<GeolocationPosition | null>(null);
  const [ lowerZone, setLowerZone ] = React.useState<FlightZone | undefined>(undefined);
  const [ placeName, setPlaceName ] = React.useState<string>('');

  const geocoding = useMapsLibrary("geocoding");

  React.useEffect(() => {
    const posSub = positionCtx?.watchPosition().subscribe((position: GeolocationPosition | null) => {
      if (position) {
        setPosition(position);
      }
    });

    return () => {
      posSub?.unsubscribe();
    };
  }, [positionCtx]);

  React.useEffect(() => {
    const lower = selectedZones?.reduce((lower, zone) => {
      return zone.height < lower?.height ? zone : lower;
    }, selectedZones[0]);

    setLowerZone(lower);
  }, [selectedZones]);

  React.useEffect(() => {
    if (!geocoding || (!selectedPosition && !position) || (collapsible && collapsed)) return;

    const geocoder = new geocoding.Geocoder();
    const pos = selectedPosition ?? {
      lat: position?.coords.latitude,
      lng: position?.coords.longitude
    } as google.maps.LatLngLiteral;
    geocoder.geocode({ location: pos }, (results, status) => {
      if (status === 'OK' && results && results.length > 0) {
        // Try to find the most appropriate locality name
        const locality = results[0].address_components.find(component => 
          component.types.includes('locality')
        ) || results[0].address_components.find(component => 
          component.types.includes('postal_town')
        ) || results[0].address_components.find(component => 
          component.types.includes('administrative_area_level_3')
        ) || results[0].address_components.find(component => 
          component.types.includes('administrative_area_level_2')
        );

        const adminArea = results[0].address_components.find(component => 
          component.types.includes('administrative_area_level_1')
        );

        if (locality && adminArea) {
          setPlaceName(`${locality.long_name} (${adminArea.short_name})`);
        } else if (locality) {
          setPlaceName(locality.long_name);
        } else if (adminArea) {
          setPlaceName(adminArea.long_name);
        }
      }
    });
  }, [geocoding, selectedPosition, collapsible, collapsed]);

  if (collapsed && collapsible) {
    return (
      <Card sx={{ maxWidth: 300, width: '100%', ...sx }}>
        <CardContent sx={{ p: 1, '&:last-child': { pb: 1 } }}>
          <Stack direction="row" spacing={1} alignItems="center">
            <Typography variant="body2" sx={{ flex: 1 }}>
              {t('dashboard.position.latitude')} {selectedPosition?.lat?.toFixed(3) ?? position?.coords.latitude?.toFixed(3)} - {t('dashboard.position.longitude')} {selectedPosition?.lng?.toFixed(3) ?? position?.coords.longitude?.toFixed(3)}
            </Typography>
            <Typography 
              variant="body2" 
              sx={{ 
                bgcolor: limitToColor(lowerZone?.height ?? 120),
                color: getTextColor(limitToColor(lowerZone?.height ?? 120)),
                p: 1,
                borderRadius: '20px'
              }}
            >
              {lowerZone?.height?.toFixed(0) ?? 120}{t('dashboard.position.meters')}
            </Typography>
            {collapsible && (
              <IconButton 
                size="small" 
                onClick={() => setCollapsed?.(false)}
                sx={{ p: 0.5 }}
              >
                <ExpandMoreIcon fontSize="small" />
              </IconButton>
            )}
          </Stack>
        </CardContent>
      </Card>
    );
  }

  return (
    <Card sx={{ maxWidth: 300, width: '100%', ...sx }}>
      <CardContent>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1 }}>
          <Typography variant="h6" gutterBottom>
            {selectedPosition ? t('dashboard.position.selectedPosition') : t('dashboard.position.currentPosition')}
          </Typography>
          <Stack direction="row" spacing={0}>
            {selectedPosition && (
              <IconButton size="small" onClick={onClear}>
                <CloseIcon />
              </IconButton>
            )}
            {collapsible && (
              <IconButton size="small" onClick={() => setCollapsed?.(true)}>
                <ExpandLessIcon />
              </IconButton>
            )}
          </Stack>
        </Box>

        <Stack direction="row" spacing={2} sx={{ mb: 1.5 }}>
          <Box>
            <Typography color="text.secondary" variant="body2">
              {t('dashboard.position.latitude')}
            </Typography>
            <Typography variant="body1" fontWeight={500}>
              {selectedPosition?.lat?.toFixed(3) ?? position?.coords.latitude?.toFixed(3)}
            </Typography>
          </Box>
          <Box>
            <Typography color="text.secondary" variant="body2">
              {t('dashboard.position.longitude')}
            </Typography>
            <Typography variant="body1" fontWeight={500}>
              {selectedPosition?.lng?.toFixed(3) ?? position?.coords.longitude?.toFixed(3)}
            </Typography>
          </Box>
        </Stack>

        <Typography variant="body2" sx={{ mb: 2 }}>
          {placeName}
        </Typography>

        <Stack direction="row" spacing={1} sx={{ mb: 1 }}>
          <Button
            variant="contained"
            fullWidth
            sx={{
              bgcolor: limitToColor(lowerZone?.height ?? 120),
              color: getTextColor(limitToColor(lowerZone?.height ?? 120)),
              borderRadius: '20px',
            }}
          >
            {t('dashboard.position.open')} {lowerZone?.height.toFixed(0) ?? 120}{t('dashboard.position.meters')}
          </Button>
          <Button
            variant="contained"
            fullWidth
            sx={{
              bgcolor: 'grey.300',
              color: 'text.primary',
              '&:hover': {
                bgcolor: 'grey.400',
              },
              borderRadius: '20px',
            }}
          >
            {t('dashboard.position.checklist')}
          </Button>
        </Stack>

        <Box
          sx={{
            maxHeight: 'min(300px, calc(100vh - 325px))',
            overflowY: 'auto',
            mt: 2,
          }}
        >
          <Stack direction="column" spacing={1}>
            { selectedZones?.map((zone, index) => (
              <Box
                key={index}
                sx={{
                  borderRadius: 2,
                  p: 1,
                  backgroundColor: limitToColor(zone.height),
                  opacity: 0.8,
                  color: getTextColor(limitToColor(zone.height)),
                }}
              >
                <Typography variant="subtitle2" fontWeight={500}>
                  {zone.identifier} - {zone.name}
                </Typography>
                <Typography variant="subtitle2">
                  {zone.reason.join(', ')}
                </Typography>
                <Typography variant="h6" fontWeight={500}>
                  {zone.height.toFixed(0)}m
                </Typography>
              </Box>
            )) }
          </Stack>
        </Box>
      </CardContent>
    </Card>
  );
};
