import { gql } from '@apollo/client';

export const GET_ROOT_CONTENTS = gql`
  query GetRootContents {
    files(where: {_and: [{ parent_id: {_is_null: true} }, { deleted_at: {_is_null: true} }, { organization_id: {_is_null: true} }]}) {
      created_at
      downloadable_mime_type
      downloadable_size
      id
      name
      type
      updated_at
      uuid
    }
  }
`;

export const GET_FOLDER_CONTENTS = gql`
  query GetFolderContentsByID($id: bigint!) {
    files(where: {_and: [{ parent_id: {_eq: $id} }, { deleted_at: {_is_null: true} }, { organization_id: {_is_null: true} }]}) {
      created_at
      downloadable_mime_type
      downloadable_size
      id
      name
      type
      updated_at
      uuid
    }
    files_by_pk(id: $id) {
      name
    }
  }
`;

export const GET_ROOT_CONTENTS_IN_ORGANIZATION = gql`
  query GetRootContentsInOrganization($organization_id: uuid!) {
    files(where: {_and: [{ parent_id: {_is_null: true} }, { deleted_at: {_is_null: true} }, { organization: { uuid: {_eq: $organization_id} }}]}) {
      created_at
      downloadable_mime_type
      downloadable_size
      id
      name
      type
      updated_at
      uuid
    }
  }
`;

export const GET_FOLDER_CONTENTS_IN_ORGANIZATION = gql`
  query GetFolderContentsByIDInOrganization($id: bigint!, $organization_id: uuid!) {
    files(where: {_and: [{ parent_id: {_eq: $id} }, { deleted_at: {_is_null: true} }, { organization: { uuid: {_eq: $organization_id} }}]}) {
      created_at
      downloadable_mime_type
      downloadable_size
      id
      name
      type
      updated_at
      uuid
    }
    files_by_pk(id: $id) {
      name
    }
  }
`;

export const DELETE_FILE = gql`
  mutation DeleteFile($id: bigint!) {
    update_files_by_pk(
      pk_columns: { id: $id }
      _set: { deleted_at: "now()" }
    ) {
      id
      deleted_at
    }
  }
`;

export const GET_FILE_BY_UUID = gql`
  query GetFileByUUID($uuid: String!) {
    files(where: {uuid: {_eq: $uuid}}) {
      id
      name
      type
      downloadable_mime_type
      downloadable_size
      uuid
      updated_at
    }
  }
`;
