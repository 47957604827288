import { Card, CardActions, CardContent, IconButton, Stack, Typography } from "@mui/material";
import React from "react";
import { agri, gray, midGray } from "../../theme";
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import { ApolloCache, DefaultContext, MutationFunctionOptions, OperationVariables } from "@apollo/client";
import { useTranslation } from "react-i18next";
import Drone from "../../classes/drone";
import { DroneStatus } from "../../common/grpc/ui_pb";
import { useScreenSize } from "../../common/hooks/useScreenSize";
import ConfirmDialog from "../../common/dialogs/ConfirmDialog";
import Rename from "../../common/dialogs/Rename";
import LinkFlightplanDialog from "../dialogs/LinkFlightplanDialog";
import DroneModelDialog from "../dialogs/DroneModelDialog";
import DroneCardFlight, { DroneCardFlightActions } from "./DroneCardFlight";
import DroneCardPilot, { DroneCardPilotActions } from "./DroneCardPilot";

export interface DroneCardProps {
  drone: Drone;
  viewKind: 'pilot' | 'flight';
  deleteDrone?: (options?: MutationFunctionOptions<any, OperationVariables, DefaultContext, ApolloCache<any>> | undefined) => Promise<any>;
  renameDroneMutation?: (options?: MutationFunctionOptions<any, OperationVariables, DefaultContext, ApolloCache<any>> | undefined) => Promise<any>;
  linkFlightPlanDroneMutation?: (options?: MutationFunctionOptions<any, OperationVariables, DefaultContext, ApolloCache<any>> | undefined) => Promise<any>;
  updateDroneModelMutation?: (options?: MutationFunctionOptions<any, OperationVariables, DefaultContext, ApolloCache<any>> | undefined) => Promise<any>;
}

const DroneCard = (props: DroneCardProps) => {
  const [droneStatus, setDroneStatus] = React.useState<DroneStatus.AsObject | null>(null);
  const [connected, setConnected] = React.useState<boolean>(false);
  const [droneAltitude, setDroneAltitude] = React.useState<number | undefined>();
  const [renameDialogOpen, setRenameDialogOpen] = React.useState<boolean>(false);
  const [linkFlightplanDialogOpen, setLinkFlightplanDialogOpen] = React.useState<boolean>(false);
  const [updateDroneModelDialogOpen, setUpdateDroneModelDialogOpen] = React.useState<boolean>(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = React.useState<boolean>(false);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const menuOpen = Boolean(anchorEl);

  const { t } = useTranslation();
  const { verticalSmall } = useScreenSize();

  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const menuButton = (
    <IconButton
      id="drone-button"
      aria-controls={menuOpen ? 'drone-menu' : undefined}
      aria-haspopup="true"
      aria-expanded={menuOpen ? 'true' : undefined}
      onClick={handleMenuClick}
      style={{
        position: 'absolute',
        top: '8px',
        right: '8px',
      }}
    >
      <MoreVertOutlinedIcon />
    </IconButton>
  );

  return (
    <Card style={{
      // height: '100%',
      // maxHeight: '450px',
      backgroundColor: gray,
      borderRadius: '16px',
      textAlign: 'center',
    }}>
      <Stack style={{
        height: '100%',
      }}>
        <CardContent style={{
          height: 'calc(100% - 60px)',
          position: 'relative',
        }}>
          { menuButton }
          <Typography variant="h5" gutterBottom style={{
            cursor: 'pointer',
            marginBottom: verticalSmall ? '2px' : '12px',
          }} onClick={() => setUpdateDroneModelDialogOpen(true)}>
            { props.drone.model?.name ?? t('Unknown Model') }
          </Typography>
          { !verticalSmall && <img src={props.drone.model?.image_url ?? '/mock/imgs/drone.png'} alt={props.drone.name} style={{
            width: '100%',
            maxWidth: '250px',
            height: '150px',
            borderRadius: '8px',
            objectFit: 'contain',
          }} /> }
          <Typography sx={{ mb: 1.5 }} variant="h5"
          onClick={() => setRenameDialogOpen(true)}
          style={{
            cursor: 'pointer',
            marginBottom: verticalSmall ? '2px' : '12px',
          }}>
            { props.drone.name }
          </Typography>
          { props.viewKind === 'flight' &&
            <DroneCardFlight
              droneStatus={droneStatus} setDroneStatus={setDroneStatus}
              connected={connected} setConnected={setConnected}
              droneAltitude={droneAltitude} setDroneAltitude={setDroneAltitude}
              linkFlightplanDialogOpen={linkFlightplanDialogOpen} setLinkFlightplanDialogOpen={setLinkFlightplanDialogOpen}
              updateDroneModelDialogOpen={updateDroneModelDialogOpen} setUpdateDroneModelDialogOpen={setUpdateDroneModelDialogOpen}
              deleteDialogOpen={deleteDialogOpen} setDeleteDialogOpen={setDeleteDialogOpen}
              renameDialogOpen={renameDialogOpen} setRenameDialogOpen={setRenameDialogOpen}
              deleteDrone={props.deleteDrone}
              renameDroneMutation={props.renameDroneMutation}
              linkFlightPlanDroneMutation={props.linkFlightPlanDroneMutation}
              updateDroneModelMutation={props.updateDroneModelMutation}
              anchorEl={anchorEl}
              menuOpen={menuOpen}
              handleMenuClose={handleMenuClose}
              verticalSmall={verticalSmall}
              viewKind={props.viewKind}
              drone={props.drone} />
          }
          { props.viewKind === 'pilot' &&
            <DroneCardPilot
              updateDroneModelDialogOpen={updateDroneModelDialogOpen} setUpdateDroneModelDialogOpen={setUpdateDroneModelDialogOpen}
              deleteDialogOpen={deleteDialogOpen} setDeleteDialogOpen={setDeleteDialogOpen}
              renameDialogOpen={renameDialogOpen} setRenameDialogOpen={setRenameDialogOpen}
              deleteDrone={props.deleteDrone}
              renameDroneMutation={props.renameDroneMutation}
              linkFlightPlanDroneMutation={props.linkFlightPlanDroneMutation}
              updateDroneModelMutation={props.updateDroneModelMutation}
              anchorEl={anchorEl}
              menuOpen={menuOpen}
              handleMenuClose={handleMenuClose}
              verticalSmall={verticalSmall}
              viewKind={props.viewKind}
              drone={props.drone} />
          }
        </CardContent>
        <CardActions style={{
          backgroundColor: connected ? agri : midGray,
          height: '60px',
          padding: 0,
        }}>
          { props.viewKind === 'flight' &&
            <DroneCardFlightActions
              droneStatus={droneStatus} setDroneStatus={setDroneStatus}
              connected={connected} setConnected={setConnected}
              droneAltitude={droneAltitude} setDroneAltitude={setDroneAltitude}
              linkFlightplanDialogOpen={linkFlightplanDialogOpen} setLinkFlightplanDialogOpen={setLinkFlightplanDialogOpen}
              updateDroneModelDialogOpen={updateDroneModelDialogOpen} setUpdateDroneModelDialogOpen={setUpdateDroneModelDialogOpen}
              deleteDialogOpen={deleteDialogOpen} setDeleteDialogOpen={setDeleteDialogOpen}
              renameDialogOpen={renameDialogOpen} setRenameDialogOpen={setRenameDialogOpen}
              deleteDrone={props.deleteDrone}
              renameDroneMutation={props.renameDroneMutation}
              linkFlightPlanDroneMutation={props.linkFlightPlanDroneMutation}
              updateDroneModelMutation={props.updateDroneModelMutation}
              anchorEl={anchorEl}
              menuOpen={menuOpen}
              handleMenuClose={handleMenuClose}
              verticalSmall={verticalSmall}
              viewKind={props.viewKind}
              drone={props.drone} />
          }
          { props.viewKind === 'pilot' &&
            <DroneCardPilotActions
              updateDroneModelDialogOpen={updateDroneModelDialogOpen} setUpdateDroneModelDialogOpen={setUpdateDroneModelDialogOpen}
              deleteDialogOpen={deleteDialogOpen} setDeleteDialogOpen={setDeleteDialogOpen}
              renameDialogOpen={renameDialogOpen} setRenameDialogOpen={setRenameDialogOpen}
              deleteDrone={props.deleteDrone}
              renameDroneMutation={props.renameDroneMutation}
              linkFlightPlanDroneMutation={props.linkFlightPlanDroneMutation}
              updateDroneModelMutation={props.updateDroneModelMutation}
              anchorEl={anchorEl}
              menuOpen={menuOpen}
              handleMenuClose={handleMenuClose}
              verticalSmall={verticalSmall}
              viewKind={props.viewKind}
              drone={props.drone} />
          }
        </CardActions>
      </Stack>
      <ConfirmDialog
        open={deleteDialogOpen}
        title={t("Delete Drone")}
        confirmColor="error"
        body={(
          <React.Fragment>
            {t('Are you sure you want to delete the drone')} <strong>{props.drone.name}</strong>?
          </React.Fragment>
        )}
        onClose={async (confirm) => {
          setDeleteDialogOpen(false);
          if (confirm && props.deleteDrone) {
            await props.deleteDrone({
              variables: {
                drone_id: props.drone.uuid,
              },
            });
          }
        }} />
      <Rename
        open={renameDialogOpen}
        title={t("Rename Drone")}
        text={(
          <React.Fragment>
            {t('Rename the drone')} <strong>{props.drone.name}</strong>
          </React.Fragment>
        )}
        onClose={async (name) => {
          setRenameDialogOpen(false);
          if (name && props.renameDroneMutation) {
            await props.renameDroneMutation({
              variables: {
                drone_id: props.drone.uuid,
                name,
              },
            });
          }
        }} />
      <LinkFlightplanDialog
        open={linkFlightplanDialogOpen}
        onClose={async (fp) => {
          setLinkFlightplanDialogOpen(false);
          if (fp && props.linkFlightPlanDroneMutation) {
            await props.linkFlightPlanDroneMutation({
              variables: {
                drone_id: props.drone.uuid,
                flightplan_id: fp.id,
              },
            });
          }
        }}
        drone={props.drone} />
      <DroneModelDialog
        open={updateDroneModelDialogOpen}
        onClose={async (model) => {
          setUpdateDroneModelDialogOpen(false);
          if (model && props.updateDroneModelMutation) {
            await props.updateDroneModelMutation({
              variables: {
                drone_id: props.drone.uuid,
                model_id: model,
              },
            });
          }
        }}
        drone={props.drone} />
    </Card>
  );
};

export default DroneCard;
