import React, { useState, useEffect } from 'react';
import { Container, Paper, Typography, Box, Button, Autocomplete, TextField, Link } from '@mui/material';
import { gql, useQuery } from '@apollo/client';
import { and, whereNotDeleted } from '../common/utils/graphql';
import Loading from '../common/components/Loading';
import { useTranslation } from 'react-i18next';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import useApi from '../common/hooks/useApi';
import { Link as RouterLink } from 'react-router-dom';

interface DroneModel {
  id: number;
  name: string;
  sdk_version: string;
}

interface Downloadable {
  uuid: string;
  name: string;
  created_at: string;
  version: string;
}

const Download: React.FC = () => {
  const [droneModels, setDroneModels] = useState<DroneModel[]>([]);
  const [selectedModelInput, setSelectedModelInput] = useState<string>('');
  const [selectedModel, setSelectedModel] = useState<DroneModel | null>(null);
  const [selectedDownloadable, setSelectedDownloadable] = useState<Downloadable | null>(null);

  const { t } = useTranslation();
  const { get } = useApi();

  const { loading, error, data } = useQuery(gql(`
    query DroneModelsDownloadables {
      drone_models(where: ${and(whereNotDeleted())}, order_by: {name: asc}) {
        id
        name
        sdk_version
      }
      downloadables(where: ${and(whereNotDeleted())}, order_by: {created_at: desc}) {
        uuid
        name
        created_at
        version
      }
    }
  `));

  useEffect(() => {
    if (!data) return;
    setDroneModels(data.drone_models);
  }, [data]);

  useEffect(() => {
    const selectedDroneModel = droneModels.find((model) => model.name === selectedModelInput);
    if (selectedDroneModel) {
      setSelectedModel(selectedDroneModel);
    } else {
      setSelectedModel(null);
    }
    setSelectedDownloadable(null);
  }, [selectedModelInput, droneModels]);

  const handleModelSelect = (model: DroneModel | null) => {
    if (model) {
      setSelectedModel(model);
      setSelectedModelInput(model.name);
    } else {
      setSelectedModel(null);
      setSelectedModelInput('');
    }
    setSelectedDownloadable(null);
  };

  const download = async (uuid: string, name: string) => {
    const response: any = await get(`/downloads/${uuid}`);
    const url = response.url;

    if (!url) return;

    const link = document.createElement('a');
    link.setAttribute('href', url);
    link.setAttribute('download', name);
    link.setAttribute('target', '_blank');
    link.setAttribute('rel', 'noopener');

    link.style.display = 'none';
    document.body.appendChild(link);

    link.click();

    document.body.removeChild(link);
  };

  if (loading) return <Loading open />;

  const downloadables = data?.downloadables || [];
  const filteredDownloadables = selectedModel
    ? downloadables.filter((d: Downloadable) => d.name.includes(selectedModel.sdk_version))
    : [];

  return (
    <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
      <Typography variant="h4" component="h1" gutterBottom>
        {t('Download Application')}
      </Typography>

      <Box sx={{ mt: 4 }}>
        <Autocomplete
          value={selectedModel}
          onChange={(_, newValue) => handleModelSelect(newValue)}
          inputValue={selectedModelInput}
          onInputChange={(_, newInputValue) => setSelectedModelInput(newInputValue)}
          options={droneModels}
          getOptionLabel={(option) => option.name}
          renderInput={(params) => (
            <TextField
              {...params}
              label={t('Drone Model')}
              variant="outlined"
              fullWidth
            />
          )}
        />
      </Box>

      <Box sx={{ mt: 3 }}>
        <Autocomplete
          value={selectedDownloadable}
          onChange={(_, newValue) => setSelectedDownloadable(newValue)}
          options={filteredDownloadables}
          getOptionLabel={(option) => `${option.version} (${new Date(option.created_at).toLocaleDateString()})`}
          renderInput={(params) => (
            <TextField
              {...params}
              label={t('Version')}
              variant="outlined"
              fullWidth
            />
          )}
          disabled={!selectedModel}
        />
      </Box>

      <Box sx={{ mt: 4 }}>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          onClick={async () => { selectedDownloadable && await download(selectedDownloadable.uuid, selectedDownloadable.name) }}
          disabled={!selectedModel || !selectedDownloadable}
          startIcon={<CloudDownloadIcon />}
        >
          {t('Download')}
        </Button>
      </Box>

      <Box sx={{ mt: 3, textAlign: 'center' }}>
        <Typography variant="body2" color="text.secondary">
          {t('or')}
        </Typography>
        <Link
          component={RouterLink}
          to="/pilot/download/manual"
          color="text.secondary"
          sx={{ fontSize: '0.875rem' }}
        >
          {t('Manually Select')}
        </Link>
      </Box>
    </Container>
  );
};

export default Download;
