export enum Overlap {
  OVERLAP_70_80 = 0,
  OVERLAP_60_70 = 1,
  OVERLAP_50_60 = 2,
};

export interface Value {
  speed: number;
  gap: number;
  precision: number;
};

type Matrix = {
  [key: number]: Value;
};

const matrix_50_60: Matrix = {
  25: { speed: 4.4, gap: 13.5, precision: 1.15 },
  30: { speed: 5.3, gap: 18, precision: 1.38 },
  35: { speed: 6.2, gap: 22.4, precision: 1.61 },
  40: { speed: 7.1, gap: 24.6, precision: 1.84 },
  45: { speed: 8, gap: 27, precision: 2.07 },
  50: { speed: 8.9, gap: 29, precision: 2.31 },
  55: { speed: 9.8, gap: 31.3, precision: 2.54 },
  60: { speed: 10.7, gap: 35.8, precision: 2.77 },
  65: { speed: 11.6, gap: 38, precision: 3 },
  70: { speed: 12.5, gap: 40.3, precision: 3.23 },
  75: { speed: 13.4, gap: 44.7, precision: 3.46 },
  80: { speed: 14.3, gap: 47, precision: 3.69 },
  85: { speed: 15, gap: 49.2, precision: 3.92 },
  90: { speed: 15, gap: 53.7, precision: 4.15 },
  95: { speed: 15, gap: 56, precision: 4.38 },
  100: { speed: 15, gap: 58, precision: 4.61 },
  105: { speed: 15, gap: 60.5, precision: 4.84 },
  110: { speed: 15, gap: 65, precision: 5.07 },
  115: { speed: 15, gap: 67, precision: 5.3 },
  120: { speed: 15, gap: 71.6, precision: 5.53 },
};

const matrix_60_70: Matrix = {
  25: { speed: 3.3, gap: 11, precision: 1.15 },
  30: { speed: 4, gap: 13.5, precision: 1.38 },
  35: { speed: 4.7, gap: 15.5, precision: 1.61 },
  40: { speed: 5.3, gap: 18, precision: 1.84 },
  45: { speed: 6, gap: 20, precision: 2.07 },
  50: { speed: 6.7, gap: 22.4, precision: 2.31 },
  55: { speed: 7.3, gap: 27, precision: 2.54 },
  60: { speed: 8, gap: 29, precision: 2.77 },
  65: { speed: 8.7, gap: 31, precision: 3 },
  70: { speed: 9.4, gap: 33.5, precision: 3.23 },
  75: { speed: 10, gap: 35.8, precision: 3.46 },
  80: { speed: 10.7, gap: 38, precision: 3.69 },
  85: { speed: 11.4, gap: 40.3, precision: 3.92 },
  90: { speed: 12, gap: 42.5, precision: 4.15 },
  95: { speed: 12.7, gap: 45, precision: 4.38 },
  100: { speed: 13.4, gap: 47, precision: 4.61 },
  105: { speed: 14.1, gap: 49, precision: 4.84 },
  110: { speed: 14.7, gap: 51.5, precision: 5.07 },
  115: { speed: 15, gap: 54, precision: 5.3 },
  120: { speed: 15, gap: 56, precision: 5.53 },
};

const matrix_70_80: Matrix = {
  25: { speed: 2.2, gap: 9, precision: 1.15 },
  30: { speed: 2.6, gap: 11, precision: 1.38 },
  35: { speed: 3.1, gap: 13.5, precision: 1.61 },
  40: { speed: 3.5, gap: 16, precision: 1.84 },
  45: { speed: 4, gap: 18, precision: 2.07 },
  50: { speed: 4.4, gap: 20, precision: 2.31 },
  55: { speed: 4.9, gap: 22.5, precision: 2.54 },
  60: { speed: 5.3, gap: 25, precision: 2.77 },
  65: { speed: 5.8, gap: 26, precision: 3 },
  70: { speed: 6.2, gap: 27, precision: 3.23 },
  75: { speed: 6.7, gap: 29, precision: 3.46 },
  80: { speed: 7.1, gap: 30, precision: 3.69 },
  85: { speed: 7.6, gap: 31, precision: 3.92 },
  90: { speed: 8, gap: 31.5, precision: 4.15 },
  95: { speed: 8.5, gap: 33.5, precision: 4.38 },
  100: { speed: 8.9, gap: 36, precision: 4.61 },
  105: { speed: 9.4, gap: 38, precision: 4.84 },
  110: { speed: 9.8, gap: 39, precision: 5.07 },
  115: { speed: 10.3, gap: 41, precision: 5.3 },
  120: { speed: 10.7, gap: 45, precision: 5.53 },
};

const droneSpecs = {
  sensorWidth: 8.80, // [mm]
  sensorHeight: 6.60, // [mm]
  focalLength: 24.00, // [mm]
  photoWidth: 8000, // [pixel]
  photoHeight: 6000, // [pixel]
  weight: 0.570, // [kg]
  droneWidth: 18.30, // [cm]
  droneLength: 25.30, // [cm]
  batteryDuration: 34.00, // [min]
  maxSpeed: 19.00, // [m/s]
  fullframewidth: 36.00, // [mm]
  fullframeheight: 24.00, // [mm]
  photowidth: 8000, // [pixel]
  photoheight: 6000, // [pixel]
  GSDrequired: 0.50, // [cm/pixel]
  frontaloverlap: 80, // [%]
  lateraloverlap: 70, // [%]
  prudenza: 3, // [%]
  sensorDiagonal: function() {
    return Math.sqrt(this.sensorWidth ** 2 + this.sensorHeight ** 2);
  },
  cropFactor: function() {
    return Math.sqrt(this.fullframewidth ** 2 + this.fullframeheight ** 2)/this.sensorDiagonal();
  },
  focallength35mm: function() {
    return this.focalLength * Math.sqrt(this.fullframewidth ** 2 + this.fullframeheight ** 2)/this.sensorDiagonal();
  },
  photodiagonal: function() {
    return Math.sqrt(this.photoHeight ** 2 + this.photoWidth ** 2);
  },
  maxheightGSD: function() {
    return (this.photodiagonal() * this.GSDrequired* this.focalLength )/ (this.sensorDiagonal()*100);
  }
};

const matrix: { [key in Overlap]: Matrix } = {} as any;

matrix[Overlap.OVERLAP_50_60] = matrix_50_60;
matrix[Overlap.OVERLAP_60_70] = matrix_60_70;
matrix[Overlap.OVERLAP_70_80] = matrix_70_80;

export const lookupMatrix = (overlap: Overlap, altitude: number): Value => {
  return matrix[overlap][altitude];
};

export { droneSpecs };