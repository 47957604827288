import React from 'react';
import { Card, CardActionArea, Typography, Box, Skeleton } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PersonIcon from '@mui/icons-material/Person';
import { Aggregate, DroneDashboardData } from '../../pages/Dashboard';
import { orange, red } from '@mui/material/colors';
import { useNavigate } from 'react-router-dom';

interface CertificateButtonProps {
  sx?: React.CSSProperties;
  disabled?: boolean;
  data?: Aggregate;
}

export const CertificateButton: React.FC<CertificateButtonProps> = ({ sx, disabled, data }) => {
  const { t } = useTranslation();

  const [ledColor, setLedColor] = React.useState('text.disabled');
  const [navigateFunction, setNavigateFunction] = React.useState(() => () => {});

  const navigate = useNavigate();

  const navigateToResources = () => {
    navigate('/pilot/resources');
  }

  const navigateToPilot = () => {
    navigate('/pilot/profile?step=1');
  }

  React.useEffect(() => {
    if (disabled || !data) {
      setLedColor('text.disabled');
      setNavigateFunction(() => () => {});
      return;
    }

    const nCertifications = data?.count;

    if (nCertifications === 0) {
      setLedColor(red['500']);
      setNavigateFunction(() => navigateToPilot);
      return;
    }

    setLedColor('#4caf50');
    setNavigateFunction(() => navigateToResources);
  }, [disabled, data]);

  if (!data) {
    return (
      <Card 
        sx={{ 
          width: 90, 
          height: 90,
          backgroundColor: 'white',
          borderRadius: 1,
          ...sx 
        }}
      >
        <Box
          sx={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            padding: 2,
          }}
        >
          <Skeleton variant="circular" width={54} height={54} />
          <Skeleton variant="text" width={60} sx={{ mt: 1 }} />
        </Box>
      </Card>
    );
  }

  return (
    <Card 
      sx={{ 
        width: 90, 
        height: 90,
        backgroundColor: 'white',
        borderRadius: 1,
        opacity: disabled ? 0.5 : 1,
        ...sx 
      }}
    >
      <CardActionArea
        onClick={navigateFunction}
        disabled={disabled}
        sx={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          padding: 5,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: 0.5,
            margin: 1,
          }}
        >
          <PersonIcon 
            sx={{ 
              fontSize: 54,
              color: disabled ? 'text.disabled' : 'text.primary',
            }} 
          />
          <Typography
            variant="caption"
            align="center"
            sx={{
              color: disabled ? 'text.disabled' : 'text.primary',
              textTransform: 'uppercase',
              fontSize: 10,
            }}
          >
            {t('dashboard.buttons.certificate')}
          </Typography>
          <Box
            sx={{
              position: 'absolute',
              top: 4,
              right: 4,
              width: 12,
              height: 12,
              borderRadius: '50%',
              backgroundColor: ledColor,
            }}
          />
        </Box>
      </CardActionArea>
    </Card>
  );
};
