import React from 'react';
import { Card, CardActionArea, Typography, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import MenuBookIcon from '@mui/icons-material/MenuBook';

interface LogBookButtonProps {
  onClick?: () => void;
  sx?: React.CSSProperties;
  disabled?: boolean;
}

export const LogBookButton: React.FC<LogBookButtonProps> = ({ onClick, sx, disabled }) => {
  const { t } = useTranslation();

  const ledColor = disabled ? 'text.disabled' : '#4caf50';

  return (
    <Card 
      sx={{ 
        width: 90, 
        height: 90,
        backgroundColor: 'white',
        borderRadius: 1,
        opacity: disabled ? 0.5 : 1,
        ...sx 
      }}
    >
      <CardActionArea
        onClick={onClick}
        disabled={disabled}
        sx={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          padding: 5,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: 0.5,
            margin: 1,
          }}
        >
          <MenuBookIcon 
            sx={{ 
              fontSize: 54,
              color: disabled ? 'text.disabled' : 'text.primary',
            }} 
          />
          <Typography
            variant="caption"
            align="center"
            sx={{
              color: disabled ? 'text.disabled' : 'text.primary',
              textTransform: 'uppercase',
              fontSize: 10,
            }}
          >
            {t('dashboard.buttons.logbook')}
          </Typography>
          <Box
            sx={{
              position: 'absolute',
              top: 4,
              right: 4,
              width: 12,
              height: 12,
              borderRadius: '50%',
              backgroundColor: ledColor,
            }}
          />
        </Box>
      </CardActionArea>
    </Card>
  );
};
