import React, { useState, MouseEvent } from 'react';
import { Paper, Typography, Stack, IconButton, Menu, MenuItem, ListItemIcon } from '@mui/material';
import FolderIcon from '@mui/icons-material/Folder';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DownloadIcon from '@mui/icons-material/Download';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { gray } from '../../../theme';
import { File } from '../../../classes/file';
import { formatDateAgo, humanFileSize } from '../../../common/utils/text';
import { useTranslation } from 'react-i18next';
import { useApolloClient, useMutation } from '@apollo/client';
import { DELETE_FILE, GET_FOLDER_CONTENTS, GET_FOLDER_CONTENTS_IN_ORGANIZATION, GET_ROOT_CONTENTS, GET_ROOT_CONTENTS_IN_ORGANIZATION } from './queries';
import { useNavigate } from 'react-router-dom';
import useFiles from '../../../hooks/useFiles';
import ConfirmDialog from '../../../common/dialogs/ConfirmDialog';

interface ItemCardProps {
  item: File;
  onClick: (item: File) => void;
}

const ItemCard: React.FC<ItemCardProps> = ({ item, onClick }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { downloadFile } = useFiles();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const open = Boolean(anchorEl);

  const client = useApolloClient();

  const [deleteFile] = useMutation(DELETE_FILE, {
    refetchQueries: [GET_ROOT_CONTENTS, GET_ROOT_CONTENTS_IN_ORGANIZATION, GET_FOLDER_CONTENTS, GET_FOLDER_CONTENTS_IN_ORGANIZATION],
    variables: { id: item.id }
  });

  const handleMenuClick = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const handleDeleteClick = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    handleMenuClose(event);
    setConfirmOpen(true);
  };

  const handleConfirmClose = async (confirm?: boolean) => {
    setConfirmOpen(false);
    if (confirm) {
      try {
        await deleteFile();
        await client.clearStore();
        window.location.reload();
      } catch (error) {
        console.error('Error deleting file:', error);
      }
    }
  };

  const handleView = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    handleMenuClose(event);
    navigate(`/pilot/resources/view/${item.uuid}`);
  };

  const handleDownload = async (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    try {
      const blob = await downloadFile(item.uuid);
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = item.name;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    } catch (error) {
      console.error('Error downloading file:', error);
    } finally {
      handleMenuClose(event);
    }
  };

  const isPDF = item.type === 'file' && item.downloadable_mime_type === 'application/pdf';

  return (
    <Paper
      sx={{
        p: 2,
        cursor: 'pointer',
        bgcolor: gray,
        '&:hover': {
          bgcolor: 'action.hover',
        },
        borderRadius: '16px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
        position: 'relative',
      }}
      elevation={0}
      onClick={(e) => {
        onClick(item);
        if (item.type === 'file') {
          handleView(e);
        }
      }}
    >
      <IconButton
        sx={{
          position: 'absolute',
          top: 8,
          right: 8,
        }}
        onClick={handleMenuClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleMenuClose}
        onClick={handleMenuClose}
      >
        {isPDF && (
          <MenuItem onClick={handleView}>
            <ListItemIcon>
              <VisibilityIcon fontSize="small" />
            </ListItemIcon>
            {t('resources.files.view')}
          </MenuItem>
        )}
        {item.type === 'file' && (
          <MenuItem onClick={handleDownload}>
            <ListItemIcon>
              <DownloadIcon fontSize="small" />
            </ListItemIcon>
            {t('resources.files.download')}
          </MenuItem>
        )}
        <MenuItem onClick={handleDeleteClick}>
          <ListItemIcon>
            <DeleteIcon fontSize="small" />
          </ListItemIcon>
          {t('resources.files.delete')}
        </MenuItem>
      </Menu>

      <ConfirmDialog
        open={confirmOpen}
        onClose={handleConfirmClose}
        title={t('resources.files.delete_confirmation')}
        body={t('resources.files.delete_message', { name: item.name })}
        confirmColor="error"
      />

      {item.type === 'folder' ? (
        <FolderIcon sx={{ fontSize: 48, color: 'text.secondary' }} />
      ) : (
        <InsertDriveFileIcon sx={{ fontSize: 48, color: 'text.secondary' }} />
      )}
      <Typography sx={{ mt: 1, wordBreak: 'break-word' }}>
        {item.name}
      </Typography>
      {item.type === 'file' && (
        <Stack spacing={1} alignItems="center">
          {item.downloadable_size && (
            <Typography variant="caption" color="text.secondary">
              {humanFileSize(item.downloadable_size)}
            </Typography>
          )}
          {item.updated_at && (
            <Stack 
              direction="row" 
              spacing={0.5} 
              alignItems="center" 
              sx={{ color: 'text.secondary' }}
            >
              <Typography variant="caption">
                {formatDateAgo(item.updated_at, t)}
              </Typography>
            </Stack>
          )}
        </Stack>
      )}
    </Paper>
  );
};

export default ItemCard;
