import React from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { useQuery } from "@apollo/client";
import { gql } from "@apollo/client";
import {
  Container,
  Grid,
  Card,
  CardMedia,
  Typography,
  Box,
  Stack,
  Chip,
  Rating,
  Divider,
  IconButton,
  Link
} from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import LanguageIcon from '@mui/icons-material/Language';
import EmailIcon from '@mui/icons-material/Email';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/Twitter';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import { Expert } from '../../../classes/expert';
import Loading from "../../../common/components/Loading";
import { getText } from "../../../utils/language";

const GET_EXPERT = gql`
  query GetExpert($id: bigint!) {
    experts_by_pk(id: $id) {
      bio_en
      bio_it
      id
      image_url
      location
      name
      title_en
      title_it
      expert_specialties {
        specialty {
          name_en
          name_it
        }
      }
      expert_socials {
        email
        facebook
        instagram
        linked_in
        twitter
        website
        you_tube
      }
    }
  }
`;

const SocialLink: React.FC<{ href?: string; icon: React.ReactNode }> = ({ href, icon }) => {
  if (!href) return null;

  return (
    <IconButton
      component={Link}
      href={href}
      target="_blank"
      rel="noopener noreferrer"
      size="small"
    >
      {icon}
    </IconButton>
  );
};

const ExpertDetails: React.FC = () => {
  const { t, i18n } = useTranslation();
  const { expertId } = useParams<{ expertId: string }>();
  const [expert, setExpert] = React.useState<Expert | null>(null);
  
  const { loading, error, data } = useQuery(GET_EXPERT, {
    variables: { id: expertId },
    skip: !expertId
  });

  React.useEffect(() => {
    if (data?.experts_by_pk) {
      const expert: Expert = {
        bio: getText(i18n, data.experts_by_pk.bio_en, data.experts_by_pk.bio_it),
        id: data.experts_by_pk.id,
        imageUrl: data.experts_by_pk.image_url,
        location: data.experts_by_pk.location,
        name: data.experts_by_pk.name,
        title: getText(i18n, data.experts_by_pk.title_en, data.experts_by_pk.title_it),
        specialties: data.experts_by_pk.expert_specialties.map(
          (specialty: any) => getText(i18n, specialty.specialty.name_en, specialty.specialty.name_it)
        )
      };

      if (data.experts_by_pk.expert_socials.length > 0) {
        expert.social = {
          email: data.experts_by_pk.expert_socials[0].email,
          facebook: data.experts_by_pk.expert_socials[0].facebook,
          linkedin: data.experts_by_pk.expert_socials[0].linked_in,
          twitter: data.experts_by_pk.expert_socials[0].twitter,
          website: data.experts_by_pk.expert_socials[0].website,
          youtube: data.experts_by_pk.expert_socials[0].you_tube
        }
      }

      setExpert(expert);
    }
  }, [data, i18n.language]);

  if (loading) return <Loading open />;
  if (error || !expertId) {
    return (
      <Container>
        <Typography variant="h5" color="error" sx={{ mt: 4 }}>
          {t('experts.not_found')}
        </Typography>
      </Container>
    );
  }

  if (!expert) {
    return (
      <Container>
        <Typography variant="h5" color="error" sx={{ mt: 4 }}>
          {t('experts.not_found')}
        </Typography>
      </Container>
    );
  }

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <Grid container spacing={4}>
        {/* Left column - Profile info */}
        <Grid item xs={12} md={5} lg={4}>
          <Card>
            {expert.imageUrl && (
              <CardMedia
                component="img"
                height="300"
                image={expert.imageUrl}
                alt={expert.name}
                sx={{ objectFit: 'cover' }}
              />
            )}
            <Box sx={{ p: 3 }}>
              <Typography variant="h5" gutterBottom>
                {expert.name}
              </Typography>
              {expert.title && (
                <Typography variant="subtitle1" color="text.secondary" gutterBottom>
                  {expert.title}
                </Typography>
              )}
              
              {expert.location && (
                <Stack direction="row" spacing={1} alignItems="center" sx={{ mt: 2 }}>
                  <LocationOnIcon fontSize="small" color="action" />
                  <Typography variant="body2" color="text.secondary">
                    {expert.location}
                  </Typography>
                </Stack>
              )}

              {expert.rating !== undefined && (
                <Box sx={{ mt: 2 }}>
                  <Rating value={expert.rating} readOnly precision={0.5} />
                </Box>
              )}

              {expert.projectsCount !== undefined && (
                <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                  {expert.projectsCount} {t('experts.details.projects')}
                </Typography>
              )}

              {/* Social Links */}
              {expert.social && (
                <Box sx={{ mt: 3 }}>
                  <Divider sx={{ mb: 2 }} />
                  <Stack direction="row" spacing={1}>
                    <SocialLink href={expert.social.website} icon={<LanguageIcon />} />
                    <SocialLink href={`mailto:${expert.social.email}`} icon={<EmailIcon />} />
                    <SocialLink href={expert.social.linkedin} icon={<LinkedInIcon />} />
                    <SocialLink href={expert.social.twitter} icon={<TwitterIcon />} />
                    <SocialLink href={expert.social.facebook} icon={<FacebookIcon />} />
                    <SocialLink href={expert.social.instagram} icon={<InstagramIcon />} />
                    <SocialLink href={expert.social.youtube} icon={<YouTubeIcon />} />
                  </Stack>
                </Box>
              )}
            </Box>
          </Card>
        </Grid>

        {/* Right column - Bio and Specialties */}
        <Grid item xs={12} md={7} lg={8}>
          {expert.bio && (
            <Card sx={{ mb: 4, p: 3 }}>
              <Typography variant="h6" gutterBottom>
                {t('experts.details.about')}
              </Typography>
              <Typography variant="body1" color="text.secondary">
                {expert.bio}
              </Typography>
            </Card>
          )}

          {expert.specialties && expert.specialties.length > 0 && (
            <Card sx={{ p: 3 }}>
              <Typography variant="h6" gutterBottom>
                {t('experts.details.specialties')}
              </Typography>
              <Stack direction="row" spacing={1} flexWrap="wrap" gap={1}>
                {expert.specialties.map((specialty, index) => (
                  <Chip
                    key={index}
                    label={specialty}
                    color="primary"
                    variant="outlined"
                  />
                ))}
              </Stack>
            </Card>
          )}
        </Grid>
      </Grid>
    </Container>
  );
};

export default ExpertDetails;
