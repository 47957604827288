import React from 'react';
import {
  Typography,
  Box,
  Chip,
  IconButton,
  Paper,
  Avatar,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import CommentIcon from '@mui/icons-material/Comment';
import { ForumPost as ForumPostInt } from '../../../classes/forum';
import { formatDateAgo } from '../../../common/utils/text';
import { stringAvatar } from '../../../common/utils/avatar';
import Markdown from '../../utils/Markdown';
import theme from '../../../theme';
import PostButtons from './PostButtons';
import useUser from '../../../common/hooks/useUser';
import { useTranslation } from 'react-i18next';

interface PostProps {
  post: ForumPostInt;
}

const ForumPost: React.FC<PostProps> = ({ post }) => {
  const navigate = useNavigate();
  const { authenticatedUserEmail } = useUser();
  const { t } = useTranslation();

  const handleClick = () => {
    navigate(`/community/forum/${post.id}`);
  };

  return (
    <Paper
      sx={{
        p: 2,
        pt: 1,
        mb: 2,
        cursor: 'pointer',
        '&:hover': {
          bgcolor: 'action.hover',
        },
      }}
      onClick={handleClick}
    >
      {post && authenticatedUserEmail === post.email && (
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', m: 0 }}>
          <PostButtons post={post} />
        </Box>
      )}
      <Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.5 }}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <Chip
              label={t(`forum.status.${post.currentStatus.toLowerCase()}`)}
              size="small"
              color={post.currentStatus === 'Open' ? 'primary' : 'success'}
            />
            <Typography variant="h6" sx={{ color: theme.palette.text.primary }}>
              {post.title}
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
              <Avatar {...stringAvatar(post.username, { width: 24, height: 24, fontSize: 12 })} />
              <Typography variant="caption" color="text.secondary">
                {post.username}
              </Typography>
              <Typography variant="caption" color="text.secondary">•</Typography>
              <Typography variant="caption" color="text.secondary">
                {formatDateAgo(post.createdAt, t)}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box sx={{ mt: 2 }}>
          <Markdown
            pStyle={{ color: theme.palette.text.primary, fontSize: '0.875rem' }}
            boxStyle={{ overflow: 'hidden', overflowY: 'auto', maxHeight: '120px' }}
          >
            {post.description}
          </Markdown>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <IconButton
              size="small"
              disableRipple
              sx={{
                '&:hover': {
                  backgroundColor: 'transparent'
                }
              }}
            >
              <Typography variant="caption" sx={{ mr: 0.5 }}>
                {post.nComments ?? post.comments?.length ?? 0}
              </Typography>
              <CommentIcon fontSize="small" />
            </IconButton>
          </Box>
        </Box>
      </Box>
    </Paper>
  );
};

export default ForumPost;
