import React from 'react';
import { 
  Card, 
  CardActionArea, 
  Menu, 
  MenuItem, 
  Checkbox,
  Typography,
  Box,
  Stack
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import LayersIcon from '@mui/icons-material/Layers';

interface Layer {
  id: string;
  name: string;
  color?: string;
}

interface LayerSelectorProps {
  layers: Layer[];
  selectedLayers: string[];
  onLayerToggle: (layerId: string) => void;
  sx?: React.CSSProperties;
}

export const LayerSelector: React.FC<LayerSelectorProps> = ({
  layers,
  selectedLayers,
  onLayerToggle,
  sx
}) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Card 
        sx={{ 
          width: 120, 
          height: 48,
          backgroundColor: 'white',
          borderRadius: 1,
          ...sx 
        }}
      >
        <CardActionArea
          onClick={handleClick}
          sx={{
            height: '100%',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            padding: '0 16px',
            gap: 1,
          }}
        >
          <LayersIcon 
            sx={{ 
              fontSize: 28,
              color: 'text.primary',
            }} 
          />
          <Typography
            sx={{
              color: 'text.primary',
              textTransform: 'uppercase',
            }}
          >
            {t('Layer')}
          </Typography>
        </CardActionArea>
      </Card>

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        sx={{
          '& .MuiPaper-root': {
            minWidth: 200,
            mt: -1,
          }
        }}
      >
        {layers.map((layer) => (
          <MenuItem 
            key={layer.id}
            onClick={() => onLayerToggle(layer.id)}
            dense
          >
            <Stack 
              direction="row" 
              alignItems="center" 
              spacing={1}
              sx={{ width: '100%' }}
            >
              {/* <Box
                sx={{
                  width: 16,
                  height: 16,
                  backgroundColor: layer.color || 'transparent',
                  borderRadius: 0.5,
                  border: layer.color ? 'none' : '1px solid #ccc',
                }}
              /> */}
              <Typography variant="body2">
                {layer.name}
              </Typography>
              <Box sx={{ flexGrow: 1 }} />
              <Checkbox
                edge="end"
                checked={selectedLayers.includes(layer.id)}
                size="small"
              />
            </Stack>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
