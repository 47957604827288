import React from 'react';
import {
  Box,
  Typography,
  Avatar,
  Paper,
} from '@mui/material';
import { stringAvatar } from '../../../common/utils/avatar';
import { ForumComment as ForumPostComment } from '../../../classes/forum';
import { formatDateAgo } from '../../../common/utils/text';
import Markdown from '../../utils/Markdown';
import CommentButtons from './CommentButtons';
import CommentForm from './CommentForm';
import useUser from '../../../common/hooks/useUser';
import { gql, useMutation, useApolloClient } from '@apollo/client';
import { useTranslation } from 'react-i18next';

interface CommentProps {
  comment: ForumPostComment;
}

const Comment: React.FC<CommentProps> = ({ comment }) => {
  const { authenticatedUserEmail } = useUser();
  const [isEditing, setIsEditing] = React.useState(false);

  const client = useApolloClient();
  const { t } = useTranslation();

  const [ updateCommentMutation ] = useMutation(gql`
    mutation UpdateComment($id: bigint!, $comment: String!) {
      update_forum_post_comments_by_pk(
        pk_columns: { id: $id },
        _set: { comment: $comment }
      ) {
        id
      }
    }
  `);

  const handleSave = async (editedComment: string) => {
    await updateCommentMutation({
      variables: {
        id: comment.id,
        comment: editedComment,
      },
    });
    await client.clearStore();
    setIsEditing(false);
    window.location.reload();
  };

  return (
    <Paper sx={{ p: 2, pt: 1, mb: 2 }}>
      {authenticatedUserEmail === comment.email && (
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', m: 0 }}>
          <CommentButtons
            comment={comment}
            onEdit={() => setIsEditing(!isEditing)}
          />
        </Box>
      )}
      <Box sx={{ display: 'flex', gap: 2 }}>
        <Avatar {...stringAvatar(comment.username)} />
        <Box sx={{ flexGrow: 1 }}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 1 }}>
            <Typography variant="subtitle2">{comment.username}</Typography>
            <Typography variant="caption" color="text.secondary">•</Typography>
            <Typography variant="caption" color="text.secondary">
              {formatDateAgo(comment.createdAt, t)}
            </Typography>
          </Box>
          {isEditing ? (
            <CommentForm
              initialValue={comment.comment}
              onCancel={() => setIsEditing(false)}
              onSubmit={handleSave}
              isEditing
            />
          ) : (
            <Markdown children={comment.comment} />
          )}
        </Box>
      </Box>
    </Paper>
  );
};

export default Comment;
