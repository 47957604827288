import React from 'react';
import { Button, Fab, useMediaQuery, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import AddIcon from '@mui/icons-material/Add';
import { primary } from '../../theme';

interface NewFlightPlanButtonProps {
  onClick?: () => void;
  sx?: React.CSSProperties;
}

export const NewFlightPlanButton: React.FC<NewFlightPlanButtonProps> = ({ onClick, sx }) => {
  const { t } = useTranslation();
  const { breakpoints } = useTheme();
  const isSmall = useMediaQuery(breakpoints.down('md'));

  if (isSmall) {
    return (
      <Fab
        color="primary"
        aria-label={t('dashboard.buttons.newFlightPlan')}
        onClick={onClick}
        sx={{
          position: 'fixed',
          bottom: 16,
          right: 16,
          ...sx
        }}
      >
        <AddIcon />
      </Fab>
    );
  }

  return (
    <Button
      variant="contained"
      startIcon={<AddIcon />}
      onClick={onClick}
      sx={{
        borderRadius: '24px',
        backgroundColor: primary,
        color: 'white',
        textTransform: 'none',
        padding: '8px 24px',
        fontSize: '15px',
        fontWeight: 500,
        boxShadow: '0 2px 4px rgba(33, 150, 243, 0.2)',
        '&:hover': {
          backgroundColor: '#2196f3',
        },
        ...sx,
      }}
    >
      {t('dashboard.buttons.newFlightPlan')}
    </Button>
  );
};
