import React from 'react';
import { Chip, Dialog, DialogActions, DialogContent, DialogTitle, Button, TextField, Snackbar, Tooltip } from '@mui/material';
import DriveFileRenameOutlineOutlinedIcon from '@mui/icons-material/DriveFileRenameOutlineOutlined';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';

interface EditableChipWithCopyProps {
  value?: string;
  onUpdate: (text: string) => Promise<void>;
  dialogTitle: string;
  dialogTextFieldLabel: string;
  dialogConfirmButtonText: string;
}

const StyledChip = styled(Chip)({
  '& .MuiChip-label': {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    maxWidth: '60px',
  },
});

const EditableChipWithCopy: React.FC<EditableChipWithCopyProps> = ({
  value,
  onUpdate,
  dialogTitle,
  dialogTextFieldLabel,
  dialogConfirmButtonText,
}) => {
  const { t } = useTranslation();
  const [editDialogOpen, setEditDialogOpen] = React.useState(false);
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [inputText, setInputText] = React.useState('');

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleEditDialogClose = () => {
    setEditDialogOpen(false);
    setInputText('');
  };

  const handleEditDialogConfirm = async () => {
    if (!inputText) return;
    await onUpdate(inputText);
    handleEditDialogClose();
  };

  const handleCopy = () => {
    if (!value) return;
    navigator.clipboard.writeText(value);
    setSnackbarOpen(true);
  };

  return (
    <>
      {value ? (
        <Tooltip title={value} placement="top">
          <StyledChip
            label={value}
            icon={<DriveFileRenameOutlineOutlinedIcon fontSize="small" />}
            onClick={() => setEditDialogOpen(true)}
            deleteIcon={<ContentCopyIcon fontSize="small" />}
            onDelete={handleCopy}
          />
        </Tooltip>
      ) : (
        <Chip
          label={t('Insert')}
          icon={<DriveFileRenameOutlineOutlinedIcon fontSize="small" />}
          onClick={() => setEditDialogOpen(true)}
        />
      )}

      <Dialog open={editDialogOpen} onClose={handleEditDialogClose} fullWidth maxWidth="sm">
        <DialogTitle>{dialogTitle}</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label={dialogTextFieldLabel}
            fullWidth
            variant="standard"
            value={inputText}
            onChange={(e) => setInputText(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleEditDialogClose}>{t('Cancel')}</Button>
          <Button onClick={handleEditDialogConfirm}>{dialogConfirmButtonText}</Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        message={t('Copied to clipboard')}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      />
    </>
  );
};

export default EditableChipWithCopy;
