import React from "react";
import useUser from "../../common/hooks/useUser";
import { DroneCardProps } from "./DroneCard";
import { CircularProgress, ListItemIcon, ListItemText, Menu, MenuItem, Stack, Tooltip, Typography } from "@mui/material";
import DriveFileRenameOutlineOutlinedIcon from '@mui/icons-material/DriveFileRenameOutlineOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { useTranslation } from "react-i18next";
import WarningIcon from '@mui/icons-material/Warning';
import { agri } from "../../theme";
import DoneAllIcon from '@mui/icons-material/DoneAll';
import { red } from "@mui/material/colors";
import { gql, useApolloClient, useMutation } from "@apollo/client";
import EditableChipWithCopy from './EditableChipWithCopy';
import InsuranceChip from "./InsuranceChip";

export interface DroneCardPilotProps extends DroneCardProps {
  anchorEl: HTMLElement | null;
  menuOpen: boolean;
  handleMenuClose: () => void;
  verticalSmall?: boolean;
  updateDroneModelDialogOpen: boolean;
  setUpdateDroneModelDialogOpen: (updateDroneModelDialogOpen: boolean) => void;
  renameDialogOpen: boolean;
  setRenameDialogOpen: (renameDialogOpen: boolean) => void;
  deleteDialogOpen: boolean;
  setDeleteDialogOpen: (deleteDialogOpen: boolean) => void;
}

export const DroneCardPilotActions: React.FC<DroneCardPilotProps> = ({ drone }) => {
  const [ pilotState, setPilotState ] = React.useState<'unknown' | 'ok' | 'warning'>('unknown');

  const { t } = useTranslation();

  React.useEffect(() => {
    if (!drone.pilot?.mtom || !drone.pilot.flight_controller || !drone.pilot.gcs) {
      setPilotState('warning');
      return;
    }

    if (!drone.pilot.insurance || !drone.pilot.insurance.expire_date) {
      setPilotState('warning');
      return;
    }

    if (drone.pilot.insurance.expire_date > new Date().toISOString()) {
      setPilotState('ok');
    } else {
      setPilotState('warning');
    }
  }, [drone]);

  switch (pilotState) {
    case 'ok':
      return (
        <Stack style={{
          height: '100%',
          width: '100%',
          justifyContent: 'center',
          alignItems: 'center',
          textTransform: 'uppercase',
          color: 'white',
          backgroundColor: agri,
        }}>
          <Stack direction="row" spacing={1}>
            <DoneAllIcon fontSize="large" />
            <Typography variant="h6">
              {t('Compliant')}
            </Typography>
          </Stack>
        </Stack>
      );
    case 'warning':
      return (
        <Stack style={{
          height: '100%',
          width: '100%',
          justifyContent: 'center',
          alignItems: 'center',
          textTransform: 'uppercase',
          color: 'white',
          backgroundColor: red[500],
        }}>
          <Stack direction="row" spacing={1}>
            <WarningIcon fontSize="large" />
            <Typography variant="h6">
              {t('Not Compliant')}
            </Typography>
          </Stack>
        </Stack>
      );
    default:
      return (
        <div style={{
          height: '100%',
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
          <CircularProgress size={40} style={{
            color: 'white',
          }} />
        </div>
      );
  }
}

const DroneCardPilot = (props: DroneCardPilotProps) => {
  const client = useApolloClient();

  const [updateMTOMMutation] = useMutation(gql(`
    mutation UpdateMTOM($id: bigint!, $mtom: String!) {
      update_drones_by_pk(pk_columns: {id: $id}, _set: {drone_pilot_mtom: $mtom}) {
        id
      }
    }
  `), {
    refetchQueries: ['Fleet']
  });

  const [updateFlightControllerMutation] = useMutation(gql(`
    mutation UpdateFlightController($id: bigint!, $flightController: String!) {
      update_drones_by_pk(pk_columns: {id: $id}, _set: {drone_pilot_flight_controller: $flightController}) {
        id
      }
    }
  `), {
    refetchQueries: ['Fleet']
  });

  const [updateGCSMutation] = useMutation(gql(`
    mutation UpdateGCS($id: bigint!, $gcs: String!) {
      update_drones_by_pk(pk_columns: {id: $id}, _set: {drone_pilot_gcs: $gcs}) {
        id
      }
    }
  `), {
    refetchQueries: ['Fleet']
  });

  const { organizationUUID } = useUser();

  const { t } = useTranslation();

  const menu = (
    <Menu
      id="drone-menu"
      anchorEl={props.anchorEl}
      open={props.menuOpen}
      onClose={props.handleMenuClose}
      MenuListProps={{
        'aria-labelledby': 'drone-button',
      }}
    >
      <MenuItem onClick={() => {
        props.setUpdateDroneModelDialogOpen(true);
        props.handleMenuClose();
      }}>
        <ListItemIcon>
          <DriveFileRenameOutlineOutlinedIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText>{t('Change Model')}</ListItemText>
      </MenuItem>
      <MenuItem onClick={() => {
        props.setRenameDialogOpen(true);
        props.handleMenuClose();
      }}>
        <ListItemIcon>
          <DriveFileRenameOutlineOutlinedIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText>{t('Rename')}</ListItemText>
      </MenuItem>
      <MenuItem onClick={() => {
        props.setDeleteDialogOpen(true);
        props.handleMenuClose();
      }}>
        <ListItemIcon>
          <DeleteOutlineOutlinedIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText>{t('Delete')}</ListItemText>
      </MenuItem>
    </Menu>
  );

  return (
    <React.Fragment>
      { menu }
      <table style={{
        width: '100%',
      }}>
        <tbody style={{
          width: '100%',
        }}>
          <tr style={{
            width: '100%',
          }}>
            <td style={{
              width: '50%',
              textAlign: 'right',
              paddingRight: '8px',
            }}>
              <Tooltip title={t('Maximum Take Off Mass')} placement="top" style={{
                cursor: 'help',
              }}>
                <Typography variant="body2" color="text.secondary">
                  {t('MTOM')}
                </Typography>
              </Tooltip>
            </td>
            <td style={{
              width: '50%',
              textAlign: 'left',
              paddingLeft: '8px',
            }}>
              <EditableChipWithCopy
                value={props.drone.pilot?.mtom}
                onUpdate={async (text) => {
                  await updateMTOMMutation({
                    variables: {
                      id: props.drone.id,
                      mtom: text
                    }
                  });
                  await client.cache.reset();
                }}
                dialogTitle={t('MTOM')}
                dialogTextFieldLabel={t('MTOM')}
                dialogConfirmButtonText={props.drone.pilot?.mtom ? t('Update') : t('Insert')}
              />
            </td>
          </tr>
          <tr style={{
              borderBottom: '1px solid rgba(224, 224, 224, 1)',
            }}>
            <td style={{
              textAlign: 'right',
              paddingRight: '8px',
            }}>
              <Typography variant="body2" color="text.secondary">
                {t('Flight Controller')}:
              </Typography>
            </td>
            <td style={{
              textAlign: 'left',
              paddingLeft: '8px',
            }}>
              <EditableChipWithCopy
                value={props.drone.pilot?.flight_controller}
                onUpdate={async (text) => {
                  await updateFlightControllerMutation({
                    variables: {
                      id: props.drone.id,
                      flightController: text
                    }
                  });
                  await client.cache.reset();
                }}
                dialogTitle={t('Flight Controller')}
                dialogTextFieldLabel={t('Flight Controller')}
                dialogConfirmButtonText={props.drone.pilot?.flight_controller ? t('Update') : t('Insert')}
              />
            </td>
          </tr>
          <tr style={{
              borderBottom: '1px solid rgba(224, 224, 224, 1)',
            }}>
            <td style={{
              textAlign: 'right',
              paddingRight: '8px',
            }}>
              <Tooltip title={t('Ground Control Station')} placement="top" style={{
                cursor: 'help',
              }}>
                <Typography variant="body2" color="text.secondary">
                  {t('GCS')}:
                </Typography>
              </Tooltip>
            </td>
            <td style={{
              textAlign: 'left',
              paddingLeft: '8px',
            }}>
              <EditableChipWithCopy
                value={props.drone.pilot?.gcs}
                onUpdate={async (text) => {
                  await updateGCSMutation({
                    variables: {
                      id: props.drone.id,
                      gcs: text
                    }
                  });
                  await client.cache.reset();
                }}
                dialogTitle={t('GCS')}
                dialogTextFieldLabel={t('GCS')}
                dialogConfirmButtonText={props.drone.pilot?.gcs ? t('Update') : t('Insert')}
              />
            </td>
          </tr>
          <tr>
            <td style={{
              width: '50%',
              textAlign: 'right',
              paddingRight: '8px',
            }}>
              <Typography variant="body2" color="text.secondary">
                {t('Insurance')}
              </Typography>
            </td>
            <td style={{
              width: '50%',
              textAlign: 'left',
              paddingLeft: '8px',
            }}>
              <InsuranceChip drone={props.drone} />
            </td>
          </tr>
        </tbody>
      </table>
    </React.Fragment>
  );
};

export default DroneCardPilot;
