import React from "react";
import { weatherURL } from "../../config";
import useApi from "./useApi";

export interface Weather {
  temperature: number;
  windSpeed: number;
  windDirection: number;
  condition: {
    text: string;
    icon: string;
  },
  pki: {
    value: number;
    timestamp: string;
  }
};

export const useWeather = () => {
  const {
    post
  } = useApi(weatherURL);

  const getWeather = React.useCallback(async (position: {latitude: number, longitude: number, language?: string}): Promise<Weather> => {
    const res: any = await post('/weather/current', position);
    return {
      temperature: res.current.temp_c,
      windSpeed: res.current.wind_kph,
      windDirection: res.current.wind_dir,
      condition: {
        text: res.current.condition.text,
        icon: res.current.condition.icon
      },
      pki: {
        value: res.pki?.value ?? 0,
        timestamp: res.pki?.timestamp ?? ""
      }
    };
  }, [post]);

  return {
    getWeather
  };
};
