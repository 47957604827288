import { Box } from "@mui/material";
import React from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from 'remark-gfm';
import rehypeSanitize from 'rehype-sanitize';

interface Props {
  children: string;
  boxStyle?: React.CSSProperties;
  pStyle?: React.CSSProperties;
}

const Markdown: React.FC<Props> = ({ children, boxStyle, pStyle }) => {
  return (
    <Box sx={{
      ...boxStyle,
      overflow: 'hidden',
      '& h1': { 
        fontSize: '1.5rem',
        fontWeight: 'bold',
        mb: 2,
        mt: 0
      },
      '& h2': {
        fontSize: '1.25rem',
        fontWeight: 'bold',
        mb: 1.5,
        mt: 2
      },
      '& p': {
        mb: 1,
        ...pStyle
      },
      '& ul, & ol': { mb: 1, pl: 3 },
      '& li': { mb: 0.5 },
      '& > *:first-child': { mt: 0 },
      '& > *:last-child': { mb: 0 },
    }}>
      <ReactMarkdown remarkPlugins={[remarkGfm]} rehypePlugins={[rehypeSanitize]}>
        {children}
      </ReactMarkdown>
    </Box>
  )
};

export default Markdown;
