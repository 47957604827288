import React from "react";
import { useState } from 'react';
import MapFull from "../../../common/components/MapFull";
import Loading from "../../../common/components/Loading";
import { computeZoom } from "../../../common/utils/position";
import UserPosition from "../../../common/components/UserPosition";
import Polyline from "../../../common/components/Polyline";
import { Box, Button, Divider, FormControl, InputAdornment, InputLabel, MenuItem, Select, Slider, Stack, Step, StepContent, StepLabel, Stepper, TextField, Typography } from "@mui/material";
import { SegmentGroup, metersToCoordinates, rotatePoint } from "../../../classes/geometry";
import { Polygon as GeomPolygon } from "../../../classes/geometry";
import { primary } from "../../../theme";
import { generateFlightPlan } from "../../../utils/flightplan";
import { gql, useMutation, useQuery } from "@apollo/client";
import useUser from "../../../common/hooks/useUser";
import { lookupMatrix, Overlap, droneSpecs } from "../../../utils/flightplan-matrix";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useEffect } from 'react';
import { DroneModel, parseDroneModelsFromGraphQL } from "../../../classes/drone";

const Offset = require('polygon-offset');

interface SpeedSliderProps {
  maxSpeed: number;
  value: number;
  onChange?: (value: number) => void;
};

const SpeedSlider = (props: SpeedSliderProps) => {
  const { t } = useTranslation();
  return (
    <Slider
      min={1}
      max={props.maxSpeed}
      step={0.1}
      value={props.value}
      onChange={(_, value) => {
        const newValue = value as number;
        if (props.onChange) {
          props.onChange(newValue);
        }
      }}
      valueLabelDisplay="auto"
      valueLabelFormat={(value) => `${value.toFixed(2)} ${t('m/s')}`}
    />
  );
};

interface DistanceSliderProps {
  value: number;
  onChange?: (value: number) => void;
}

const DistanceSlider = (props: DistanceSliderProps) => {
  const { t } = useTranslation();
  return (
    <Slider
      min={-50}
      max={50}
      step={5}
      value={props.value}
      onChange={(_, value) => {
        props.onChange?.(value as number);
      }}
      valueLabelDisplay="auto"
      valueLabelFormat={(value) => `${value.toFixed(2)} ${t('m')}`}
    />
  );
};

interface PercentSliderProps {
  value: number;
  onChange?: (value: number) => void;
  step?: number;
}

const PercentSlider = (props: PercentSliderProps) => {
  const { t } = useTranslation();
  return (
    <Slider
      value={props.value}
      step={ props.step ?? 5 }
      min={0}
      max={100}
      onChange={(_, value) => {
        if (props.onChange) {
          props.onChange(value as number);
        }
      }}
      valueLabelDisplay="auto"
      valueLabelFormat={(value) => `${value.toFixed(2)} ${t('%')}`}
    />
  );
};

interface LateralMenuProps {
  back?: () => void;
  save?: () => void;
  reset?: () => void;
  name: string;
  setName: (value: string) => void;
  construct3d: boolean;
  setConstruct3d: (value: boolean) => void;
  rotation: number;
  setRotation: (value: number) => void;
  followTerrain: boolean;
  setFollowTerrain: (value: boolean) => void;
  speed: number;
  setSpeed: (value: number) => void;
  maxSpeed: number;
  distance: number;
  setDistance: (value: number) => void;
  // horizontalOverlapping: number;
  // setHorizontalOverlapping: (value: number) => void;
  // verticalOverlapping: number;
  // setVerticalOverlapping: (value: number) => void;
  overlapping: number;
  setOverlapping: (value: number) => void;
  resolution: number;
  startingHeight: number;
  setStartingHeight: (value: number) => void;
  endBehavior: 'rth' | 'hovering';
  setEndBehavior: (value: 'rth' | 'hovering') => void;
}

const LateralMenu = (props: LateralMenuProps) => {
  const [ activeStep, setActiveStep ] = React.useState(0);
  const { t } = useTranslation();

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    if (props.reset) props.reset();
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const [startingHeight, setStartingHeight] = useState(
    Number(droneSpecs.maxheightGSD().toFixed(2))
  );  

  const [gsdRequired, setGsdRequired] = useState(droneSpecs.GSDrequired);

  const [heightPhotoTerrain, setHeightPhotoTerrain] = useState(0);
  const [widthPhotoTerrain, setWidthPhotoTerrain] = useState(0);

  useEffect(() => {
    // Recalculate GSDrequired whenever startingHeight changes
    const GSDreal =
      (startingHeight * droneSpecs.sensorDiagonal() * 100) /
      (droneSpecs.photodiagonal() * droneSpecs.focalLength);
    setGsdRequired(GSDreal); // Update gsdRequired with the calculated GSDreal
    // Calculate widthPhotoTerrain and heightPhotoTerrain
    const widthPhotoTerrain = (droneSpecs.photoWidth * GSDreal) / 100;
    setHeightPhotoTerrain(heightPhotoTerrain);
    setWidthPhotoTerrain(widthPhotoTerrain);
    setHeightPhotoTerrain(heightPhotoTerrain);

    // Log values to the terminal
    console.log('Width of Photo from terrain:', widthPhotoTerrain.toFixed(2), 'm');
    console.log('Height of Photo from terrain:', heightPhotoTerrain.toFixed(2), 'm');
  }, [startingHeight]);
  
  const [overlap, setOverlap] = useState(droneSpecs.frontaloverlap);
  const [initialSpeed, setInitialSpeed] = useState(0);
  const [overlaplateral, setOverlapLateral] = useState(droneSpecs.lateraloverlap);
  const [timeLapseInterval, updateTimeLapseInterval] = useState(1);
  useEffect(() => {
    // Recalculate GSDrequired whenever startingHeight changes
    const GSDreal =
      (startingHeight * droneSpecs.sensorDiagonal() * 100) /
      (droneSpecs.photodiagonal() * droneSpecs.focalLength);
    setGsdRequired(GSDreal); // Update gsdRequired with the calculated GSDreal
    // Calculate widthPhotoTerrain and heightPhotoTerrain
    const widthPhotoTerrain = (droneSpecs.photoWidth * GSDreal) / 100;
    const heightPhotoTerrain = (droneSpecs.photoHeight * GSDreal) / 100;
    setHeightPhotoTerrain(heightPhotoTerrain);

    // Log values to the terminal
    console.log('Width of Photo from terrain:', widthPhotoTerrain.toFixed(2), 'm');
    console.log('Height of Photo from terrain:', heightPhotoTerrain.toFixed(2), 'm');
  }, [startingHeight]);

  useEffect(() => {
    // Convert frontal overlap percentage to a decimal
    const frontalOverlapDecimal = overlap / 100;
    console.log('Initial frontal overlap:', frontalOverlapDecimal * 100, '%');
    // Convert frontal overlap percentage to a decimal
    const lateralOverlapDecimal = overlaplateral / 100;
    // Calculate the real frontal overlap
    const RealfrontalOverlap = frontalOverlapDecimal * (1 + droneSpecs.prudenza/100);
    // Calculate the real lateral overlap
    const RealLateralOverlap = lateralOverlapDecimal * (1 + droneSpecs.prudenza/100);
    // Log the real frontal and lateral overlap
    console.log('Real Frontal Overlap:', parseFloat(RealfrontalOverlap.toFixed(2)) * 100, '%');
    console.log('Real Lateral Overlap:', parseFloat(RealLateralOverlap.toFixed(2)) * 100, '%');
    // Calculate distance between two shoots
    const distancebtwn2shoots = heightPhotoTerrain * (1 - RealfrontalOverlap);
    // Calculate distance between rows lateral
    const gap = widthPhotoTerrain * (1 - RealLateralOverlap);
    // Log the calculated value
    console.log('Distance Between Two Shoots:', distancebtwn2shoots.toFixed(2), 'meters');
    console.log('Distance Between rows Lateral:', gap.toFixed(2), 'meters');
    // Calculate initial speed
    const speed = distancebtwn2shoots / timeLapseInterval;
    
    // Log initial speed
    console.log('Initial Speed:', speed.toFixed(2), 'm/s');
  }, [initialSpeed, overlap, heightPhotoTerrain, timeLapseInterval]);

  function handleSliderChange(event: Event, value: number | number[], activeThumb: number): void {
    setGsdRequired(value as number);
  }

  function setTimeLapseInterval(value: number): void {
    if (value < 1) {
      console.warn("Time-lapse interval must be at least 1 second.");
      return;
    }
    updateTimeLapseInterval(value);
  }

  return (
    <Box style={{
      padding: 16,
      width: 600,
      maxWidth: '60%',
      height: '100%',
      overflowX: 'hidden',
      overflowY: 'scroll',
    }}>
      <Stepper activeStep={activeStep} orientation="vertical" style={{width: '100%'}}>
        <Step key="General" style={{width: '100%'}}>
          <StepLabel style={{
            cursor: 'pointer',
          }} onClick={() => {
            setActiveStep(0);
          }}>
            {t('General')}
          </StepLabel>
          <StepContent>
            <Box><Stack spacing={2} style={{
              width: '100%',
              padding: 0,
            }}>
              <Box style={{
                width: '100%',
              }}>
                <FormControl style={{
                  width: '100%',
                }}>
                  <TextField
                    id="name"
                    label={t("Name")}
                    variant="filled"
                    value={props.name}
                    onChange={(e) => {
                      props.setName(e.target.value);
                    }}
                    size="small"
                    autoFocus
                    fullWidth />
                </FormControl>
              </Box>
              <Box>
                <Typography variant="body2" style={{
                  color: 'rgba(0, 0, 0, 0.6)',
                  marginBottom: 8,
                }}>
                  {t('Distance Plane')}
                </Typography>
                <Typography variant="body2" style={{
                  color: 'rgba(0, 0, 0, 0.6)',
                  marginBottom: 8,
                }}>
                  {t('Enter the distance from the facade at which the drone will fly, in meters.')}
                </Typography>
               
              </Box>
              <Box style={{
                width: '100%',
              }}>
                <Typography variant="body2">
                  {t('Distance')}: {props.distance}m
                </Typography>
                <DistanceSlider value={props.distance} onChange={(v) => {
                  props.setDistance(v);
                }} />
              </Box>
              <Box sx={{ marginTop: 4 }}>
                <Typography variant="subtitle1" gutterBottom>
                  {t('Starting Height of Flight')}: 
                </Typography>
                <TextField
                  type="number"
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={startingHeight}
                  onChange={(e) => setStartingHeight(Number(e.target.value))}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">m</InputAdornment>,
                  }}
                  inputProps={{ min: 0 }}
                  sx={{
                    backgroundColor: '#f7f9fc',
                    borderRadius: 2,
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderColor: '#dcdfe3',
                      },
                      '&:hover fieldset': {
                        borderColor: '#3f51b5',
                      },
                    },
                  }}
                />
                <Typography variant="subtitle1" gutterBottom sx={{ marginTop: 4 }}>
                  {t('GSD')}: {gsdRequired.toFixed(2)} cm/pixel
                </Typography>
                <Slider
                  value={gsdRequired}
                  onChange={(e, newValue) => setGsdRequired(Number(newValue))}
                  aria-labelledby="gsd-slider"
                  valueLabelDisplay="auto"
                  valueLabelFormat={(value) => `${value.toFixed(2)} cm/pixel`}
                  min={0.1}
                  max={5}
                  step={0.01}
                  sx={{ width: '100%', marginTop: 2 }}
                />
              </Box>
              <Stack direction="row" spacing={1}>
                { props.back && <Button
                  onClick={props.back}
                  sx={{ mt: 1, mr: 1 }}
                  fullWidth
                >
                  {t('Back')}
                </Button> }
                <Button
                  variant="contained"
                  onClick={handleNext}
                  sx={{ mt: 1, mr: 1 }}
                  fullWidth
                  disabled={props.name.length === 0}
                >
                  {t('Continue')}
                </Button>
              </Stack>
            </Stack></Box>
          </StepContent>
        </Step>

        <Step key="Details">
          <StepLabel style={{
            cursor: 'pointer',
          }} onClick={() => {
            if (props.name.length === 0) return;
            if (props.reset) props.reset();
            setActiveStep(1);
          }}>
            {t('Details')}
          </StepLabel>
          <StepContent>
            <Box><Stack spacing={2} style={{
              width: '100%',
              padding: 0,
            }}>
              { props.reset && <Box style={{
                marginTop: 0,
                marginBottom: 0,
              }}>
                <Stack direction="row" spacing={2} style={{
                  width: '100%',
                }}>
                  <Button
                    onClick={props.reset}
                    fullWidth
                    variant="text">
                    {t('Reset')}
                  </Button>
                </Stack>
              </Box> }
              <Box sx={{ marginTop: 4 }}>
                <Typography variant="body2" gutterBottom>
                  {t('Frontal Overlapping')}:
                </Typography>
                <TextField
                  type="number"
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={overlap}
                  onChange={(e) => setOverlap(Number(e.target.value))}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                  }}
                  inputProps={{ min: 0, max: 100 }}
                  sx={{
                    backgroundColor: '#f7f9fc',
                    borderRadius: 2,
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderColor: '#dcdfe3',
                      },
                      '&:hover fieldset': {
                        borderColor: '#3f51b5',
                      },
                    },
                  }}
                />
              </Box>
              <Box style={{ width: '100%' }}>
                <Typography variant="body2" gutterBottom>
                  {t('Time-lapse Interval (seconds)')}:
                </Typography>
                <TextField
                  type="number"
                  value={timeLapseInterval} // Add a state to manage this value
                  onChange={(e) => setTimeLapseInterval(Number(e.target.value))}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">sec</InputAdornment>,
                  }}
                  variant="outlined"
                  size="small"
                  inputProps={{ min: 1 }} // Minimum value set to 1 second
                  sx={{
                    width: '100%',
                    backgroundColor: '#f7f9fc',
                    borderRadius: 2,
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderColor: '#dcdfe3',
                      },
                      '&:hover fieldset': {
                        borderColor: '#3f51b5',
                      },
                    },
                  }}
                />
              </Box>
              <Box style={{ width: '100%' }}>
                <Typography variant="body2">
                  {t('Speed')}: {initialSpeed.toFixed(2)} m/s
                </Typography>
                <SpeedSlider
                  value={initialSpeed}
                  maxSpeed={props.maxSpeed}
                  onChange={(value: number) => {
                    setInitialSpeed(value); // Update initialSpeed when slider is moved
                    console.log('Speed set to:', value.toFixed(2), 'm/s'); // Log the new speed value
                    // When the speed changes, calculate the real distance between two shoots
                    // Calculate the real distance between two shoots based on the speed and time lapse
                    const realdistancebtwn2shoots = value * timeLapseInterval; // formula: speed * time = distance
                    console.log('Real Distance Between Two Shoots:', realdistancebtwn2shoots.toFixed(2), 'meters');  // Log the new distance value
                  }}
                />
              </Box>
              <Box sx={{ marginTop: 4 }}>
                <Typography variant="body2" gutterBottom>
                  {t('Lateral Overlapping')}:
                </Typography>
                <TextField
                  type="number"
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={overlaplateral}
                  onChange={(e) => setOverlapLateral(Number(e.target.value))}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                  }}
                  inputProps={{ min: 0, max: 100 }}
                  sx={{
                    backgroundColor: '#f7f9fc',
                    borderRadius: 2,
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderColor: '#dcdfe3',
                      },
                      '&:hover fieldset': {
                        borderColor: '#3f51b5',
                      },
                    },
                  }}
                />
              </Box>
              <Box style={{
                width: '100%',
              }}>
                <FormControl variant="filled" size="small" fullWidth sx={{ mt: 1 }}>
                  <InputLabel id="end-behavior-select-label">{t('End Behavior')}</InputLabel>
                  <Select
                    labelId="end-behavior-select-label"
                    id="end-behavior-select"
                    value={props.endBehavior}
                    label={t("End Behavior")}
                    onChange={(e) => props.setEndBehavior(e.target.value as 'rth' | 'hovering')}
                  >
                    <MenuItem value="rth">{t('Return to Home')}</MenuItem>
                    <MenuItem value="hovering">{t('Hovering')}</MenuItem>
                  </Select>
                </FormControl>
              </Box>
              <Divider />

              <Stack direction="row" spacing={1}>
                <Button
                  onClick={handleBack}
                  sx={{ mt: 1, mr: 1 }}
                  fullWidth
                >
                  {t('Back')}
                </Button>
                <Button
                  variant="contained"
                  disabled={!props.save || props.name.length === 0}
                  onClick={() => {
                    if (props.save) props.save();
                  }}
                  sx={{ mt: 1, mr: 1 }}
                  fullWidth
                >
                  {t('Save')}
                </Button>
              </Stack>
            </Stack></Box>
          </StepContent>
        </Step>
      </Stepper>
    </Box>
  );
};

export interface SetupFlightPlanProps {
  path: google.maps.LatLngLiteral[];
  fieldID?: number;
  back?: () => void;
  save?: (name?: string) => Promise<number | undefined>;
}

const SetupFlightPlan = (props: SetupFlightPlanProps) => {
  const [ defaultCenter, setDefaultCenter ] = React.useState<google.maps.LatLngLiteral | undefined>();
  const [ defaultZoom, setDefaultZoom ] = React.useState<number | undefined>();
  const [ safeArea, setSafeArea ] = React.useState<google.maps.LatLngLiteral[] | undefined>();
  const [ name, setName ] = React.useState('');
  const [ construct3d, setConstruct3d ] = React.useState(false);
  const [ rotation, setRotation ] = React.useState(0);
  const [ followTerrain, setFollowTerrain ] = React.useState(false);
  const [ speed, setSpeed ] = React.useState(5);
  const [ maxSpeed, setMaxSpeed ] = React.useState(5);
  const [ altitude, setAltitude ] = React.useState(60);
  const [ startingHeight, setStartingHeight ] = React.useState(0);
  // const [ horizontalOverlapping, setHorizontalOverlapping ] = React.useState(70);
  // const [ verticalOverlapping, setVerticalOverlapping ] = React.useState(80);
  const [ overlapping, setOverlapping ] = React.useState(Overlap.OVERLAP_70_80);
  const [ resolution, setResolution ] = React.useState(0.0);
  const [ gap, setGap ] = React.useState(10);
  const [ path, setPath ] = React.useState<google.maps.LatLngLiteral[] | undefined>();
  const [ pathRotated3D, setPathRotated3D ] = React.useState<google.maps.LatLngLiteral[] | undefined>();
  const [ dronePath, setDronePath ] = React.useState<google.maps.LatLngLiteral[] | undefined>();
  const [ endBehavior, setEndBehavior ] = React.useState<'rth' | 'hovering'>('rth');
  const [ droneModels, setDroneModels ] = React.useState<DroneModel[]>([]);

  const safeAreaMinMeters = 30;

  const { userID, organizationID } = useUser();
  const navigate = useNavigate();

  const { loading, error, data } = useQuery(gql(`
    query DroneModelsCameras {
      drone_models {
        id
        name
        camera_multispectral_focal_length
        camera_multispectral_height
        camera_multispectral_sensor_height
        camera_multispectral_sensor_width
        camera_multispectral_width
        camera_rgb_focal_length
        camera_rgb_height
        camera_rgb_sensor_height
        camera_rgb_sensor_width
        camera_rgb_width
        camera_thermal_focal_length
        camera_thermal_height
        camera_thermal_sensor_height
        camera_thermal_sensor_width
        camera_thermal_width
      }
    }
  `));

  const [ insertFlightplan ] = useMutation(gql(`
    mutation InsertFlightplan(
      $name: String!,
      $data: bytea!,
      $user_id: bigint!,
      $organization_id: bigint,
      $field_id: bigint,
    ) {
      insert_flight_plans(objects: {
        name: $name,
        data: $data,
        user_id: $user_id,
        organization_id: $organization_id,
        field_id: $field_id,
      }) {
        returning {
          id
          uuid
        }
      }
    }
  `), {
    refetchQueries: ['FlightPlans', 'FlightPlan', 'FlightPlanNames'],
  });

  const safeAreaOffset = React.useCallback((): number => {
    return metersToCoordinates(Math.max(altitude, safeAreaMinMeters));
  }, [safeAreaMinMeters, altitude]);

  const onSave = async () => {
    if (!path) return;

    const fp = await generateFlightPlan({
      altitude: altitude,
      speed: speed,
      path: path,
      pathRotated3D: pathRotated3D,
      rotated3D: construct3d,
      followTerrain: followTerrain,
      endBehavior: endBehavior,
    });

    let fieldID = props.fieldID;
    if (!fieldID && props.save) {
      fieldID = await props.save(name);
    }

    const data = await fp.toData();
    await insertFlightplan({
      variables: {
        name: name,
        data: data,
        user_id: userID,
        organization_id: organizationID,
        field_id: fieldID,
      },
    });

    navigate('../..');
  };

  const onReset = () => {
    setOverlapping(Overlap.OVERLAP_70_80);
    const v = lookupMatrix(Overlap.OVERLAP_70_80, altitude);

    setSpeed(v.speed);
    setMaxSpeed(v.speed);
    setResolution(v.precision);
    setGap(v.gap);

    const polygon = new GeomPolygon(...props.path);
    const longestSegment = polygon.getLongestSegment();
    let rotation = 90 - (longestSegment?.getRotation() ?? 0);
    if (rotation < -180) rotation += 360;
    if (rotation > 180) rotation -= 360;
    setRotation(rotation);
  };
  const [distance, setDistance] = React.useState(0); // Distance value from slider
  const [timeLapseInterval, setTimeLapseInterval] = React.useState(1); // Time-lapse interval state
  const [parallelPath, setParallelPath] = React.useState<google.maps.LatLngLiteral[]>([]); 

  // Function to calculate the offset points for parallel line
  const calculateParallelLine = (
    path: google.maps.LatLngLiteral[],
    distance: number
  ): google.maps.LatLngLiteral[] => {
    if (path.length < 2) return [];
  
    const earthRadius = 6371000; // Earth radius in meters
    const offsetPath: google.maps.LatLngLiteral[] = [];
  
    for (let i = 0; i < path.length; i++) {
      let normalX = 0;
      let normalY = 0;
  
      // Calculate the perpendicular vector for each segment
      if (i === 0) {
        // For the first point
        const p1 = path[i];
        const p2 = path[i + 1];
        const dx = p2.lng - p1.lng;
        const dy = p2.lat - p1.lat;
  
        normalX = -dy;
        normalY = dx;
      } else if (i === path.length - 1) {
        // For the last point
        const p1 = path[i - 1];
        const p2 = path[i];
        const dx = p2.lng - p1.lng;
        const dy = p2.lat - p1.lat;
  
        normalX = -dy;
        normalY = dx;
      } else {
        // For intermediate points, average the normals of adjacent segments
        const pPrev = path[i - 1];
        const pCurr = path[i];
        const pNext = path[i + 1];
  
        const dx1 = pCurr.lng - pPrev.lng;
        const dy1 = pCurr.lat - pPrev.lat;
        const dx2 = pNext.lng - pCurr.lng;
        const dy2 = pNext.lat - pCurr.lat;
  
        const normalX1 = -dy1;
        const normalY1 = dx1;
        const normalX2 = -dy2;
        const normalY2 = dx2;
  
        normalX = (normalX1 + normalX2) / 2;
        normalY = (normalY1 + normalY2) / 2;
      }
      // Normalize the perpendicular vector
      const length = Math.sqrt(normalX * normalX + normalY * normalY);
      const unitNormalX = normalX / length;
      const unitNormalY = normalY / length;
      // Convert distance to degrees
      const latOffset = (unitNormalY * distance) / earthRadius * (180 / Math.PI);
      const lngOffset =
        (unitNormalX * distance) /
        (earthRadius * Math.cos((path[i].lat * Math.PI) / 180)) *
        (180 / Math.PI);
  
      // Apply the offset
      offsetPath.push({
        lat: path[i].lat + latOffset,
        lng: path[i].lng + lngOffset,
      });
    }
  
    return offsetPath;
  };

  React.useEffect(() => {
    const v = lookupMatrix(overlapping, altitude);

    setSpeed(v.speed);
    setMaxSpeed(v.speed);
    setResolution(v.precision);
    setGap(v.gap);
  }, [overlapping, altitude]);

  React.useEffect(() => {
    if (!google.maps.LatLngBounds) {
      return
    }
    const b = new google.maps.LatLngBounds();
    props.path.forEach((latLng) => {
      b.extend(new google.maps.LatLng(latLng.lat, latLng.lng));
    });
    setDefaultCenter(b.getCenter().toJSON());
    setDefaultZoom(computeZoom(b, window.innerWidth, window.innerHeight));
  }, [props.path, google.maps.LatLngBounds]);

  React.useEffect(() => {
    if (!props.path) return;

    const closedCoords = [
      ...props.path,
      props.path[0],
    ];
    const offset = new Offset();
    const safeArea = offset.data(closedCoords.map(p => {
      return [p.lat, p.lng];
    })).margin(safeAreaOffset())[0].map((p: number[]) => {
      return {
        lat: p[0],
        lng: p[1],
      };
    });
    setSafeArea(safeArea);
  }, [props.path, safeAreaOffset]);

  React.useEffect(() => {
    if (!props.path || !google.maps.geometry) return;
    onReset();
  }, [props.path, google.maps.geometry]);
  
  // When distance changes, calculate the parallel line
  React.useEffect(() => {
    if (props.path && props.path.length > 0) {
      const newParallelPath = calculateParallelLine(props.path, distance);
      setParallelPath(newParallelPath);
    }
  }, [distance, props.path]);

  React.useEffect(() => {
    const polygon = new GeomPolygon(...props.path);

    const polygonRotated = polygon.rotate(rotation);
    const roundingBox = polygonRotated.getRoundingBox();
    const segmentGroup = new SegmentGroup(roundingBox, gap);
    let p = segmentGroup.getPath(polygonRotated).map(p => rotatePoint(p, -rotation));
    setPath(p);

    if (construct3d) {
      const r = rotation + 20;

      const polygonRotated = polygon.rotate(r);
      const roundingBox = polygonRotated.getRoundingBox();
      const segmentGroup = new SegmentGroup(roundingBox, gap);
      const pathRotated = segmentGroup.getPath(polygonRotated).map(p => rotatePoint(p, -r));
      setPathRotated3D(pathRotated);
      p = [...p, ...pathRotated];
    } else {
      setPathRotated3D(undefined);
    }

    setDronePath(p);
  }, [props.path, rotation, construct3d, gap]);

  React.useEffect(() => {
    if (!data) return;

    const droneModels = parseDroneModelsFromGraphQL(data.drone_models);
    setDroneModels(droneModels);
  }, [data]);

  if (!defaultCenter || loading) {
    return <Loading open />;
  }

  return (
    <Stack
      style={{
        height: '100%',
      }}
      direction="row">
      <LateralMenu
        back={props.back}
        save={onSave}
        reset={onReset}
        name={name} setName={setName}
        construct3d={construct3d} setConstruct3d={setConstruct3d}
        rotation={rotation} setRotation={setRotation}
        followTerrain={followTerrain} setFollowTerrain={setFollowTerrain}
        speed={speed} setSpeed={setSpeed}
        maxSpeed={maxSpeed}
        distance={distance} setDistance={setDistance}
        // horizontalOverlapping={horizontalOverlapping} setHorizontalOverlapping={setHorizontalOverlapping}
        startingHeight={startingHeight} setStartingHeight={setStartingHeight}
        overlapping={overlapping} setOverlapping={setOverlapping}
        resolution={resolution}
        endBehavior={endBehavior} setEndBehavior={setEndBehavior}
      />
      <MapFull
        defaultCenter={defaultCenter}
        defaultZoom={defaultZoom}>
        {parallelPath.length > 0 && (
          <Polyline path={parallelPath} strokeColor="red" strokeOpacity={0.5} zIndex={2} />
        )}
        <Polyline path={props.path} closed />
        { dronePath && <Polyline path={dronePath} strokeColor={primary} zIndex={1} /> }
        <UserPosition />
      </MapFull>
    </Stack>
  );
};

export default SetupFlightPlan;
