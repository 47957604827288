import React from 'react';
import { Box, List, Pagination } from '@mui/material';
import ForumPost from './ForumPost';
import { ForumPost as ForumPostInt } from '../../../classes/forum';

interface ForumListProps {
  posts: Array<ForumPostInt>;
  itemsPerPage: number;
  totalItems: number;
  page: number;
  onPageChange: (page: number) => void;
}

const ForumList: React.FC<ForumListProps> = ({ 
  posts, 
  itemsPerPage,
  totalItems,
  page,
  onPageChange,
}) => {
  const pageCount = Math.ceil(totalItems / itemsPerPage);

  return (
    <Box sx={{ width: '100%' }}>
      <List sx={{ width: '100%' }}>
        {posts.map(post => (
          <React.Fragment key={post.id}>
            <ForumPost post={post} />
          </React.Fragment>
        ))}
      </List>
      <Box sx={{ display: 'flex', justifyContent: 'center', p: 2 }}>
        <Pagination 
          page={page}
          count={pageCount}
          color="primary"
          onChange={(_, value) => onPageChange(value)}
        />
      </Box>
    </Box>
  );
};

export default ForumList;
