import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import React from "react";
import { useTranslation } from "react-i18next";

export interface UploadLicenseResult {
  expirationDate: dayjs.Dayjs | null;
  file: File | null;
  licenseType: string;
}

const UploadLicense = (props: {
  open: boolean;
  licenseType: string;
  onClose: (res?: UploadLicenseResult) => void;
}) => {
  const [ expirationDate, setExpirationDate ] = React.useState<dayjs.Dayjs | null>(null);
  const [ file, setFile ] = React.useState<File | null>(null);
  const [ valid, setValid ] = React.useState<boolean>(false);

  const { t } = useTranslation();

  React.useEffect(() => {
    if (props.open) {
      setExpirationDate(null);
      setFile(null);
    }
  }, [props.open]);

  React.useEffect(() => {
    if (expirationDate && file) {
      setValid(true);
    } else {
      setValid(false);
    }
  }, [expirationDate, file]);

  if (!props.open) {
    return null;
  }

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={props.open}
      onClick={(event) => {
        event.stopPropagation();
      }}
      onClose={() => {props.onClose()}}
      PaperProps={{
        component: 'form',
        onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
          event.preventDefault();
          event.stopPropagation();

          if (!valid) return;

          props.onClose({
            expirationDate,
            file,
            licenseType: props.licenseType
          });
        },
      }}
    >
      <DialogTitle>{ t('Upload License') }</DialogTitle>
      <DialogContent>
        <DialogContentText>
          { t('Upload your {{type}} license.', { type: props.licenseType }) }
        </DialogContentText>
        <DatePicker
          label={t('Expiration Date')}
          value={expirationDate}
          onChange={(date) => setExpirationDate(date)}
          sx={{
            mt: 2,
            width: '100%'
          }}
        />
        <TextField
          type="file"
          fullWidth
          variant="outlined"
          margin="normal"
          slotProps={{
            htmlInput: {
              accept: 'application/pdf',
            },
            inputLabel: {
              shrink: true,
            },
          }}
          label={t('Upload File')}
          onChange={(e) => {
            const target = e.target as HTMLInputElement;
            if (target.files && target.files.length > 0) {
              const file = target.files[0];
              setFile(file);
            } else {
              setFile(null);
            }
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => props.onClose()}>
          { t('Cancel') }
        </Button>
        <Button type="submit" disabled={!valid}>
          { t('Upload') }
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UploadLicense;
