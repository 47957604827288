import { IconButton, Tooltip } from "@mui/material";
import React from "react";
import ChatIcon from '@mui/icons-material/Chat';
import useHelp from "../common/hooks/useHelp";
import { useTranslation } from "react-i18next";

const HelpMenu = () => {
  const { setHelpDialogOpen, helpDialog } = useHelp();

  const { t } = useTranslation();

  return (
    <React.Fragment>
      <Tooltip
        title={t('Need Help?')}
        placement="bottom"
      >
        <IconButton
          size="large"
          color="inherit"
          onClick={() => setHelpDialogOpen(true)}
        >
          <ChatIcon />
        </IconButton>
      </Tooltip>
      {helpDialog}
    </React.Fragment>
  );
};

export default HelpMenu;
