import React from "react";
import { DroneStatus } from "../../common/grpc/ui_pb";
import useUser from "../../common/hooks/useUser";
import { DroneCardProps } from "./DroneCard";
import useApi from "../../common/hooks/useApi";
import { Chip, CircularProgress, Divider, ListItemIcon, ListItemText, Menu, MenuItem, Stack, Typography } from "@mui/material";
import saveAs from "file-saver";
import { Command } from "../../common/grpc/common_pb";
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';
import RouteIcon from '@mui/icons-material/Route';
import DriveFileRenameOutlineOutlinedIcon from '@mui/icons-material/DriveFileRenameOutlineOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import CloudSyncIcon from '@mui/icons-material/CloudSync';
import DeleteSweepOutlinedIcon from '@mui/icons-material/DeleteSweepOutlined';
import { useTranslation } from "react-i18next";
import DroneStatusChip from "../../common/components/DroneStatusChip";
import { primary } from "../../theme";
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { LandAction } from "../cardactions/Drone";
import { RTHAction } from "../cardactions/Drone";
import { StartAction } from "../cardactions/Drone";
import { StopAction } from "../cardactions/Drone";
import WifiOffOutlinedIcon from '@mui/icons-material/WifiOffOutlined';

export interface DroneCardFlightProps extends DroneCardProps {
  anchorEl: HTMLElement | null;
  menuOpen: boolean;
  handleMenuClose: () => void;
  verticalSmall?: boolean;
  connected: boolean;
  setConnected: (connected: boolean) => void;
  droneStatus: DroneStatus.AsObject | null;
  setDroneStatus: (droneStatus: DroneStatus.AsObject | null) => void;
  droneAltitude: number | undefined;
  setDroneAltitude: (droneAltitude: number | undefined) => void;
  linkFlightplanDialogOpen: boolean;
  setLinkFlightplanDialogOpen: (linkFlightplanDialogOpen: boolean) => void;
  updateDroneModelDialogOpen: boolean;
  setUpdateDroneModelDialogOpen: (updateDroneModelDialogOpen: boolean) => void;
  deleteDialogOpen: boolean;
  setDeleteDialogOpen: (deleteDialogOpen: boolean) => void;
  renameDialogOpen: boolean;
  setRenameDialogOpen: (renameDialogOpen: boolean) => void;
}

export const DroneCardFlightActions = (props: DroneCardFlightProps) => {
  const { t } = useTranslation();

  return (
    <>
      { !props.droneStatus ? 
        <React.Fragment>
          <div style={{
            height: '100%',
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
            <CircularProgress size={40} style={{
              color: 'white',
            }} />
          </div>
        </React.Fragment>
        :
        props.connected ?
        <React.Fragment>
          { (props.droneStatus.metrics?.state+'' === 'LANDED' || props.droneStatus.metrics?.state+'' === 'HOVERING') && <StartAction droneUUID={props.drone.uuid} /> }
          { (props.droneStatus.metrics?.state+'' === 'FLYING' || props.droneStatus.metrics?.state+'' === 'FLIGHT_PLAN' || props.droneStatus.metrics?.state+'' === 'DOWNLOADING') && <StopAction droneUUID={props.drone.uuid} /> }
          { (props.droneStatus.metrics?.state+'' === 'FLYING' || props.droneStatus.metrics?.state+'' === 'FLIGHT_PLAN' || props.droneStatus.metrics?.state+'' === 'HOVERING') && <RTHAction droneUUID={props.drone.uuid} defaultAltitude={props.droneAltitude} /> }
          { (props.droneStatus.metrics?.state+'' === 'HOVERING') && <LandAction droneUUID={props.drone.uuid} /> }
        </React.Fragment>
        :
        <React.Fragment>
          <Stack style={{
            height: '100%',
            width: '100%',
            justifyContent: 'center',
            alignItems: 'center',
            textTransform: 'uppercase',
            color: 'white',
          }}>
            <Stack direction="row" spacing={1}>
              <WifiOffOutlinedIcon fontSize="large" />
              <Typography variant="h6">
                {t('Offline')}
              </Typography>
            </Stack>
          </Stack>
        </React.Fragment>
      }
    </>
  );
}

const DroneCardFlight = (props: DroneCardFlightProps) => {
  const { organizationUUID } = useUser();

  const { get, post } = useApi();
  const { t } = useTranslation();

  const handleWatch = () => {
    let url = 'https://controller.dromt.it/drone?drone=' + props.drone.uuid;
    if (organizationUUID) {
      url += '&organization=' + organizationUUID;
    }

    let a = document.createElement('a');
    a.href = url;
    a.target = '_blank';
    a.click();
    a.remove();
  };

  React.useEffect(() => {
    const int = setInterval(() => {
      get(`/drones/${props.drone.uuid}/status`).then((data) => {
        props.setDroneStatus(data as DroneStatus.AsObject);
      }).catch((err) => {
        console.error(err);
        props.setDroneStatus(null);
      });
    }, 1000);
    return () => {
      clearInterval(int);
    };
  }, [get]);

  React.useEffect(() => {
    if (props.droneStatus) {
      props.setConnected(props.droneStatus.metrics?.state+'' !== 'UNKNOWN');
      props.setDroneAltitude(props.droneStatus.metrics?.position?.altitude);
    } else {
      props.setConnected(false);
      props.setDroneAltitude(undefined);
    }
  }, [props.droneStatus]);

  const menu = (
    <Menu
      id="drone-menu"
      anchorEl={props.anchorEl}
      open={props.menuOpen}
      onClose={props.handleMenuClose}
      MenuListProps={{
        'aria-labelledby': 'drone-button',
      }}
    >
      <MenuItem onClick={() => {
        get(`/drones/${props.drone.uuid}/certificate`).then((data: any) => {
          let pfx = Buffer.from(data.pfx, 'base64');
          saveAs(new Blob([pfx]), props.drone.name + '.pfx');
        }).catch((err) => {
          console.error(err);
        }).finally(() => {
          props.handleMenuClose();
        });
      }}>
        <ListItemIcon>
          <CloudDownloadOutlinedIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText>{t('Download Certificate')}</ListItemText>
      </MenuItem>
      <MenuItem onClick={() => {
        props.setLinkFlightplanDialogOpen(true);
        props.handleMenuClose();
        }}>
        <ListItemIcon>
          <RouteIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText>{t('Link FlightPlan')}</ListItemText>
      </MenuItem>
      <Divider />
      <MenuItem onClick={() => {
        const command = {
          id: Command.CommandCode.DOWNLOAD_MEDIA,
          downloadMedia: {
            mediaType: "MEDIA_TYPE_ALL",
            cameraType: "CAMERA_TYPE_ALL",
            uploadToClient: false,
          },
        };

        post(`/controller/drone/${props.drone.uuid}`, [command]).then((data: any) => {
          if (!data.success) {
            console.error(data.message);
          }
        }).catch((err) => {
          console.error(err);
        }).finally(() => {
          props.handleMenuClose();
        });
      }} disabled={!props.connected}>
        <ListItemIcon>
          <CloudSyncIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText>{t('Synchronize Media')}</ListItemText>
      </MenuItem>
      <MenuItem onClick={() => {
        const command = {
          id: Command.CommandCode.DELETE_ALL_MEDIA,
        };

        post(`/controller/drone/${props.drone.uuid}`, [command]).then((data: any) => {
          if (!data.success) {
            console.error(data.message);
          }
        }).catch((err) => {
          console.error(err);
        }).finally(() => {
          props.handleMenuClose();
        });
      }} disabled={!props.connected}>
        <ListItemIcon>
          <DeleteSweepOutlinedIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText>{t('Delete All Media')}</ListItemText>
      </MenuItem>
      <Divider />
      <MenuItem onClick={() => {
        props.setUpdateDroneModelDialogOpen(true);
        props.handleMenuClose();
      }}>
        <ListItemIcon>
          <DriveFileRenameOutlineOutlinedIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText>{t('Change Model')}</ListItemText>
      </MenuItem>
      <MenuItem onClick={() => {
        props.setRenameDialogOpen(true);
        props.handleMenuClose();
      }}>
        <ListItemIcon>
          <DriveFileRenameOutlineOutlinedIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText>{t('Rename')}</ListItemText>
      </MenuItem>
      <MenuItem onClick={() => {
        props.setDeleteDialogOpen(true);
        props.handleMenuClose();
      }}>
        <ListItemIcon>
          <DeleteOutlineOutlinedIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText>{t('Delete')}</ListItemText>
      </MenuItem>
    </Menu>
  );

  return (
    <React.Fragment>
      { menu }
      <table style={{
        width: '100%',
      }}>
        <tbody style={{
          width: '100%',
        }}>
          <tr style={{
            width: '100%',
          }}>
            <td style={{
              width: '50%',
              textAlign: 'right',
              paddingRight: '8px',
            }}>
              <Typography variant="body2">
                {t('Flight Plan')}
              </Typography>
            </td>
            <td style={{
              width: '50%',
              textAlign: 'left',
              paddingLeft: '8px',
            }}>
              <Chip
                style={{
                  backgroundColor: props.drone.flightPlan ? undefined : '#e0e0e0',
                  color: props.drone.flightPlan ? undefined : 'black',
                }}
                color={props.drone.flightPlan ? 'primary' : undefined}
                label={props.drone.flightPlan ? props.drone.flightPlan.name : 'N/A' }
                onClick={() => {
                  props.setLinkFlightplanDialogOpen(true);
                }}
                onDelete={ props.drone.flightPlan ? async () => {
                  if (props.linkFlightPlanDroneMutation) {
                    await props.linkFlightPlanDroneMutation({
                      variables: {
                        drone_id: props.drone.uuid,
                        flightplan_id: undefined,
                      },
                    });
                  }
                } : undefined }
              />
            </td>
          </tr>
          <tr style={{
            width: '100%',
          }}>
            <td style={{
              width: '50%',
              textAlign: 'right',
              paddingRight: '8px',
            }}>
              <Typography variant="body2">
                {t('Status')}
              </Typography>
            </td>
            <td style={{
              width: '50%',
              textAlign: 'left',
              paddingLeft: '8px',
              paddingTop: '4px',
            }}>
              <DroneStatusChip
                status={props.droneStatus?.metrics?.state+''}
                loading={!props.droneStatus || !props.droneStatus.metrics}
              />
            </td>
          </tr>
          <tr>
            <td style={{
              width: '50%',
              textAlign: 'right',
              paddingRight: '8px',
            }}>
              <Typography variant="body2">
                {t('Live Stream')}
              </Typography>
            </td>
            <td style={{
              width: '50%',
              textAlign: 'left',
              paddingLeft: '8px',
            }}>
              <Chip
                style={{
                  backgroundColor: props.connected ? primary : '#e0e0e0',
                  color: props.connected ? 'white' : 'black',
                }}
                onClick={props.connected ? handleWatch : undefined}
                onDelete={props.connected ? handleWatch : undefined}
                deleteIcon={props.connected ? <RemoveRedEyeIcon style={{
                  color: 'white'
                }} /> : undefined}
                label={ props.connected ? t('Watch') : t('Disabled') }
              />
            </td>
          </tr>
        </tbody>
      </table>
    </React.Fragment>
  );
};

export default DroneCardFlight;
