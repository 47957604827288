import React from 'react';
import { Container } from '@mui/material';
import FileExplorer from '../../../components/pilot/resources/FileExplorer';

const FileExplorerView: React.FC = () => {
  return (
    <Container maxWidth="xl">
      <FileExplorer />
    </Container>
  );
};

export default FileExplorerView;
