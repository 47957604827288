import React from "react";
import { useLocation } from "react-router-dom";
import theme from "../theme";
import Menu, { MenuItems } from "./Menu";

const items: MenuItems[] = [
  {
    items: [
      { title: 'Network' },
      { title: 'Forum', url: '/community/forum' },
    ]
  }
];

export interface CommunityMenuProps {
  open?: boolean;
  url?: string;
};

const CommunityMenu = (props: CommunityMenuProps) => {
  const location = useLocation();

  React.useEffect(() => {
    items[0].items.forEach(item => {
      item.active = !!item.url && location.pathname.startsWith(item.url);
    });
  }, [location]);

  return (
    <Menu
      theme={theme}
      open={props.open ?? false}
      title="Community"
      items={items}
      url={props.url} />
  );
};

export default CommunityMenu;
