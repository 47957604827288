import React from 'react';
import { Box, Typography } from '@mui/material';
import FolderOffIcon from '@mui/icons-material/FolderOff';
import { gray } from '../../../theme';
import { useTranslation } from 'react-i18next';

const EmptyState: React.FC = () => {
  const { t } = useTranslation();
  
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        p: 4,
        bgcolor: gray,
        borderRadius: '16px',
        minHeight: '200px',
        width: '100%',
      }}
    >
      <FolderOffIcon sx={{ fontSize: 48, color: 'text.secondary', mb: 2 }} />
      <Typography variant="h6" color="text.secondary" gutterBottom>
        {t('resources.empty.title')}
      </Typography>
      <Typography variant="body2" color="text.secondary" align="center">
        {t('resources.empty.description')}
      </Typography>
    </Box>
  );
};

export default EmptyState;
