import React from "react";
import MapFull from "../../../common/components/MapFull";
import { PositionContext } from "../../../common/contexts/position";
import Loading from "../../../common/components/Loading";
import { computeZoom, geolocationToLatLng } from "../../../common/utils/position";
import UserPosition from "../../../common/components/UserPosition";
import { Fab, Stack, Tooltip, useMediaQuery } from "@mui/material";
import InfoWindow from "../../../common/components/InfoWindow";
import Polyline from "../../../common/components/Polyline";
import Polygon from "../../../common/components/Polygon";
import MapPoint from "../../../common/components/MapPoint";
import ClearIcon from '@mui/icons-material/Clear';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckIcon from '@mui/icons-material/Check';
import SearchIcon from '@mui/icons-material/Search';
import SearchLocationDialog from "../../dialogs/SearchLocationDialog";
import { useTranslation } from "react-i18next";
import LayersIcon from '@mui/icons-material/Layers';
import LayersClearIcon from '@mui/icons-material/LayersClear';
import theme from "../../../theme";
import useFlightZones, { limitToColor } from "../../../common/hooks/useFlightZones";
import { ZoneLegend } from "../area/DrawArea";

interface ButtonsProps {
  onSearchLocation?: () => void;
  onDeleteAll?: () => void;
  onDeleteLast?: () => void;
  onConfirm?: () => void;
  toggleDFlight?: () => void;
  dFlightEnabled?: boolean;
}

const Buttons = (props: ButtonsProps) => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm')); 

  return (
    <div style={{
      position: 'absolute',
      bottom: 22,
      right: 16,
      zIndex: 1000,
      textAlign: 'right',
    }}>
      <Stack direction={ isMobile ? 'column' : 'row' } spacing={1}>
        <Tooltip title={t("Flight Zones")} placement={ isMobile ? 'left' : 'top' }>
          <span><Fab size="small" onClick={() => {
            if (props.toggleDFlight) {
              props.toggleDFlight();
            }
          }}
          disabled={props.toggleDFlight === undefined}>
            { props.dFlightEnabled ? <LayersClearIcon /> : <LayersIcon /> }
          </Fab></span>
        </Tooltip>
        <Tooltip title={t("Search Location")} placement={ isMobile ? 'left' : 'top' }>
          <span><Fab size="small" onClick={() => {
            if (props.onSearchLocation) {
              props.onSearchLocation();
            }
          }}
          disabled={props.onSearchLocation === undefined}>
            <SearchIcon />
          </Fab></span>
        </Tooltip>
        <Tooltip title={t("Delete Last")} placement={ isMobile ? 'left' : 'top' }>
          <span><Fab size="small" onClick={() => {
            if (props.onDeleteLast) {
              props.onDeleteLast();
            }
          }}
          disabled={props.onDeleteLast === undefined}>
            <ClearIcon />
          </Fab></span>
        </Tooltip>
        <Tooltip title={t("Delete All")} placement={ isMobile ? 'left' : 'top' }>
          <span><Fab size="small" onClick={() => {
            if (props.onDeleteAll) {
              props.onDeleteAll();
            }
          }}
          disabled={props.onDeleteAll === undefined}>
            <DeleteIcon />
          </Fab></span>
        </Tooltip>
        <Tooltip title={t("Confirm")} placement={ isMobile ? 'left' : 'top' }>
          <span><Fab color="primary" variant="extended" size="medium" onClick={() => {
            if (props.onConfirm) {
              props.onConfirm();
            }
          }}
          disabled={props.onConfirm === undefined}>
            <CheckIcon />
            {t('Confirm')}
          </Fab></span>
        </Tooltip>
      </Stack>
    </div>
  );
};

export interface DrawFacadeProps {
  path?: google.maps.LatLngLiteral[];
  centerPath?: boolean;
  onPathChange?: (path: google.maps.LatLngLiteral[]) => void;
}

const DrawFacade = (props: DrawFacadeProps) => {
  const [ locationDialogOpen, setLocationDialogOpen ] = React.useState<boolean>(false);

  const [ defaultCenter, setDefaultCenter ] = React.useState<google.maps.LatLngLiteral | undefined>();
  const [ defaultZoom, setDefaultZoom ] = React.useState<number | undefined>();
  const [ center, setCenter ] = React.useState<google.maps.LatLngLiteral | undefined>();
  const [ zoom, setZoom ] = React.useState<number | undefined>();
  const [ path, setPath ] = React.useState<google.maps.LatLngLiteral[]>(props.path ?? []);

  const { t } = useTranslation();
  const {
    enabled: flightZonesEnabled,
    setEnabled: setFlightZonesEnabled,
    flightZonesCoordinates
  } = useFlightZones({
    enabled: true
  });

  const positionCtx = React.useContext(PositionContext);

  const changePointLocation = (index: number, latLng: google.maps.LatLngLiteral) => {
    const newPath = [...path];
    newPath[index] = latLng;
    setPath(newPath);
  }

  React.useEffect(() => {
    if (props.centerPath) return;

    let locationFound = false;
    const posSub = positionCtx?.watchPosition().subscribe((position: GeolocationPosition | null) => {
      if (position && !locationFound) {
        setDefaultCenter(geolocationToLatLng(position));
        setDefaultZoom(17);
        setCenter(geolocationToLatLng(position));
        setZoom(17);
        locationFound = true;
      }
    });
    return () => {
      posSub?.unsubscribe();
    }
  }, [positionCtx, props.centerPath]);

  React.useEffect(() => {
    if (!props.centerPath) return;

    const b = new google.maps.LatLngBounds();
    path.forEach((latLng) => {
      b.extend(new google.maps.LatLng(latLng.lat, latLng.lng));
    });
    setCenter(b.getCenter().toJSON());
    setZoom(computeZoom(b, window.innerWidth, window.innerHeight));
  }, [path, props.centerPath]);

  if (!defaultCenter) {
    return <Loading open />;
  }

  return (
    <React.Fragment>
      <MapFull
        center={center}
        zoom={zoom}
        onCameraChanged={(e) => {
          setCenter(e.detail.center);
          setZoom(e.detail.zoom);
        }}
        defaultCenter={defaultCenter}
        defaultZoom={defaultZoom}
        onClick={(e) => {
          if (!e.detail.latLng) {
            return;
          }
          setPath([...path, e.detail.latLng]);
        }}>
        { flightZonesEnabled && flightZonesCoordinates.length === 0 && <Loading open /> }
        { flightZonesEnabled && flightZonesCoordinates.map((coords, index) => {
          return <Polygon
            key={index}
            path={coords.coords}
            opacity={0.3}
            color={limitToColor(coords.height)}
            zIndex={200 - coords.height} />;
        }) }

        <Polyline path={path} closed />
        { path.map((point, index) => {
          return <MapPoint
            key={index}
            position={point}
            onDrag={(e) => {
              if (e && e.latLng) {
                changePointLocation(index, e.latLng.toJSON());
              }
            }} />;
        }) }
        <UserPosition />
        { flightZonesEnabled && <ZoneLegend /> }
        <InfoWindow
          text={t("Draw the horizontal line of the facade to be mapped by clicking on the map to define its borders.")}
          width={300}
          open />
        <Buttons
          toggleDFlight={() => setFlightZonesEnabled(!flightZonesEnabled)}
          dFlightEnabled={flightZonesEnabled}
          onSearchLocation={() => {
            setLocationDialogOpen(true);
          }}
          onDeleteAll={() => {
            setPath([]);
          }}
          onDeleteLast={() => {
            setPath(path.slice(0, path.length - 1));
          }}
          onConfirm={path.length == 2 && props.onPathChange ? () => {
            props.onPathChange!(path);
          } : undefined}
          />
      </MapFull>
      <SearchLocationDialog
        open={locationDialogOpen}
        onClose={(location) => {
          setLocationDialogOpen(false);
          if (location) {
            setCenter(location);
          }
        }} />
    </React.Fragment>
  );
};


export default DrawFacade;
