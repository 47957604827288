import React from "react";
import { useTranslation } from "react-i18next";
import { Dialog, DialogActions, DialogContent, DialogTitle, TextField, Button } from "@mui/material";
import { HelpData } from "../hooks/useHelp";
import useUser from "../hooks/useUser";

export interface HelpDialogProps {
  open: boolean;
  onClose: (data?: HelpData) => void;
}

function HelpDialog(props: HelpDialogProps) {
  const { t } = useTranslation();

  const { authenticatedUserEmail } = useUser();

  return (
    <React.Fragment>
      <Dialog
        fullWidth
        maxWidth="sm"
        open={props.open}
        onClick={(event) => {
          event.stopPropagation();
        }}
        onClose={() => {props.onClose()}}
        PaperProps={{
          component: 'form',
          onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();
            event.stopPropagation();
            const formData = new FormData(event.currentTarget);
            const formJson = Object.fromEntries((formData as any).entries());

            const data: HelpData = {
              message: formJson.message,
              email: authenticatedUserEmail ?? '',
              page: `${window.location.protocol}//${window.location.host}${window.location.pathname}`,
            }

            props.onClose(data);
          },
        }}
      >
        <DialogTitle>{ t('Help') }</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            required
            margin="dense"
            id="message"
            name="message"
            label={t('Please enter your message here.')}
            type="text"
            fullWidth
            variant="outlined"
            multiline
            minRows={4}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => {props.onClose()}}>{t('Cancel')}</Button>
          <Button type="submit">{t('Submit')}</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

export default HelpDialog;
