import React from 'react';
import { Box, Fab, Grow, Backdrop } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CloseIcon from '@mui/icons-material/Close';
import { LayerSelector } from './LayerSelector';
import { CardSelector } from './CardSelector';

interface Layer {
  id: string;
  name: string;
  color: string;
}

interface CardOption {
  id: string;
  name: string;
}

interface MobileSelectorsProps {
  layers: Layer[];
  selectedLayers: string[];
  onLayerToggle: (layerId: string) => void;
  cardOptions: CardOption[];
  selectedCard?: string;
  onCardSelect: (cardId: string) => void;
}

export const MobileSelectors: React.FC<MobileSelectorsProps> = ({
  layers,
  selectedLayers,
  onLayerToggle,
  cardOptions,
  selectedCard,
  onCardSelect,
}) => {
  const [isOpen, setIsOpen] = React.useState(false);

  const handleClose = () => setIsOpen(false);

  return (
    <React.Fragment>
      <Fab
        onClick={() => setIsOpen(!isOpen)}
        sx={{
          position: 'fixed',
          bottom: 80,
          right: 16,
          zIndex: 1300
        }}
      >
        {isOpen ? <CloseIcon /> : <MoreVertIcon />}
      </Fab>

      <Backdrop
        open={isOpen}
        onClick={handleClose}
        sx={{ 
          zIndex: 1200,
          backgroundColor: 'rgba(0, 0, 0, 0.2)'
        }}
      />

      <Grow 
        in={isOpen}
        mountOnEnter
        unmountOnExit
        timeout={{
          enter: 200,
          exit: 150
        }}
      >
        <Box
          sx={{
            position: 'fixed',
            bottom: 144,
            right: 16,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end',
            gap: 2,
            maxHeight: 'calc(100vh - 200px)',
            overflowY: 'auto',
            transformOrigin: 'bottom right',
            zIndex: 1300
          }}
        >
          <LayerSelector
            layers={layers}
            selectedLayers={selectedLayers}
            onLayerToggle={onLayerToggle}
          />
          <CardSelector
            options={cardOptions}
            selectedOption={selectedCard}
            onOptionSelect={onCardSelect}
          />
        </Box>
      </Grow>
    </React.Fragment>
  );
};
