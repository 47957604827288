import React from 'react';
import { Box, Typography, IconButton } from '@mui/material';
import { Masonry } from '@mui/lab';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ItemCard from './ItemCard';
import EmptyState from './EmptyState';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/client';
import { GET_FOLDER_CONTENTS, GET_FOLDER_CONTENTS_IN_ORGANIZATION, GET_ROOT_CONTENTS, GET_ROOT_CONTENTS_IN_ORGANIZATION } from './queries';
import { File } from '../../../classes/file';
import Loading from '../../../common/components/Loading';
import useUser from '../../../common/hooks/useUser';

const FileExplorer: React.FC = () => {
  const [items, setItems] = React.useState<File[]>([]);
  const [folders, setFolders] = React.useState<File[]>([]);
  const [files, setFiles] = React.useState<File[]>([]);
  const [currentFolderName, setCurrentFolderName] = React.useState<string | undefined>();

  const navigate = useNavigate();
  const { folderId } = useParams();
  const location = useLocation();
  const { t } = useTranslation();
  const { organizationUUID } = useUser();

  const { loading: loadingRoot, error: errorRoot, data: dataRoot } = useQuery(
    GET_ROOT_CONTENTS,
    {
      fetchPolicy: 'cache-and-network',
      skip: !!folderId || !!organizationUUID,
    }
  );

  const { loading: loadingFolder, error: errorFolder, data: dataFolder } = useQuery(
    GET_FOLDER_CONTENTS,
    {
      variables: { id: folderId || '' },
      fetchPolicy: 'cache-and-network',
      skip: !folderId || !!organizationUUID,
    }
  );

  const { loading: loadingOrg, error: errorOrg, data: dataOrg } = useQuery(
    GET_ROOT_CONTENTS_IN_ORGANIZATION,
    {
      variables: { organization_id: organizationUUID || '' },
      fetchPolicy: 'cache-and-network',
      skip: !!folderId || !organizationUUID,
    }
  );

  const { loading: loadingOrgFolder, error: errorOrgFolder, data: dataOrgFolder } = useQuery(
    GET_FOLDER_CONTENTS_IN_ORGANIZATION,
    {
      variables: { id: folderId || '', organization_id: organizationUUID || '' },
      fetchPolicy: 'cache-and-network',
      skip: !folderId || !organizationUUID,
    }
  );

  const handleItemClick = (item: File) => {
    if (item.type === 'folder') {
      navigate(`/pilot/resources/${item.id}`);
    }
  };

  const handleBack = () => {
    navigate(-1);
  };

  const updateFiles = (data: any) => {
    if (data && data.files) {
      const { files } = data;
      setItems(files);
      setFolders(files.filter((f: File) => f.type === 'folder'));
      setFiles(files.filter((f: File) => f.type === 'file'));

      if (data.files_by_pk) {
        setCurrentFolderName(data.files_by_pk.name);
      } else {
        setCurrentFolderName(undefined);
      }
    }
  };

  React.useEffect(() => {
    updateFiles(dataRoot);
  }, [dataRoot]);

  React.useEffect(() => {
    updateFiles(dataFolder);
  }, [dataFolder]);

  React.useEffect(() => {
    updateFiles(dataOrg);
  }, [dataOrg]);

  React.useEffect(() => {
    updateFiles(dataOrgFolder);
  }, [dataOrgFolder]);

  if (loadingRoot || loadingFolder || loadingOrg || loadingOrgFolder) {
    return <Loading open />;
  }

  return (
    <Box sx={{ p: 3 }}>
      <Box sx={{ mb: 3, display: 'flex', alignItems: 'center' }}>
        <IconButton 
          onClick={handleBack} 
          disabled={location.pathname === '/pilot/resources'}
          sx={{ mr: 2 }}
        >
          <ArrowBackIcon />
        </IconButton>
        <Typography variant="h6">
          {currentFolderName ? `${t('resources.folder')}: ${currentFolderName}` : t('resources.title')}
        </Typography>
      </Box>

      {items.length === 0 ? (
        <EmptyState />
      ) : (
        <>
          {folders.length > 0 && (
            <>
              <Typography variant="h6" sx={{ mb: 2 }}>{t('resources.folders')}</Typography>
              <Masonry columns={{ xs: 2, sm: 3, md: 4 }} spacing={2} sx={{ mb: 2 }}>
                {folders.map((item) => (
                  <ItemCard key={item.id} item={item} onClick={handleItemClick} />
                ))}
              </Masonry>
            </>
          )}

          {files.length > 0 && (
            <>
              <Typography variant="h6" sx={{ mb: 2 }}>{t('resources.files.files')}</Typography>
              <Masonry columns={{ xs: 2, sm: 3, md: 4 }} spacing={2} sx={{ mb: 2 }}>
                {files.map((item) => (
                  <ItemCard key={item.id} item={item} onClick={handleItemClick} />
                ))}
              </Masonry>
            </>
          )}
        </>
      )}
    </Box>
  );
};

export default FileExplorer;
