import React from "react";
import MapFull from "../common/components/MapFull";
import useFlightZones, { FlightZone, limitToColor } from "../common/hooks/useFlightZones";
import Loading from "../common/components/Loading";
import Polygon from "../common/components/Polygon";
import UserPosition from "../common/components/UserPosition";
import { ZoneLegend } from "../components/flightplans/area/DrawArea";
import { PositionContext } from "../common/contexts/position";
import { geolocationToLatLng } from "../common/utils/position";
import { Box, Fab, useMediaQuery, useTheme } from "@mui/material";
import { CurrentPosition } from "../components/dashboard/CurrentPosition";
import { CertificateButton } from "../components/dashboard/CertificateButton";
import { LogBookButton } from "../components/dashboard/LogBookButton";
import { InsuranceButton } from "../components/dashboard/InsuranceButton";
import { LayerSelector } from "../components/dashboard/LayerSelector";
import { CardSelector } from "../components/dashboard/CardSelector";
import { MobileSelectors } from '../components/dashboard/MobileSelectors';
import { MobileButtons } from '../components/dashboard/MobileButtons';
import { NewFlightPlanButton } from "../components/dashboard/NewFlightPlanButton";
import { useNavigate } from "react-router-dom";
import { gql, useQuery } from "@apollo/client";
import { whereNotDeleted } from "../common/utils/graphql";
import { useTranslation } from "react-i18next";
import { useWeather, Weather } from "../common/hooks/useWeather";
import { WeatherCard } from "../components/dashboard/WeatherCard";
import { Marker } from "@vis.gl/react-google-maps";
import SearchIcon from '@mui/icons-material/Search';
import SearchLocationDialog from "../components/dialogs/SearchLocationDialog";

interface QueryData {
  drones: DroneDashboardData[];
  pilot_licenses_aggregate: {
    aggregate: Aggregate;
  }
}

export interface DroneDashboardData {
  id: number;
  uuid: string;
  name: string;
  drone_pilot_insurance_expire_date?: string;
  drone_pilot_insurance_file_id: number;
  file?: {
    id: number;
    uuid: string;
  }
}

export interface Aggregate {
  count: number;
}

const Dashboard = () => {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('md'));
  const isVerySmall = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const google = window.google;

  const layers = [
    { id: 'Flight Maps - ITA', name: t('dashboard.layers.flight_maps_ita'), color: '#4CAF50' },
    // { id: 'NOTAM', name: t('dashboard.layers.notam'), color: '#4CAF50' },
    // { id: 'Parks', name: t('dashboard.layers.parks'), color: '#8BC34A' },
    // { id: 'Ground', name: t('dashboard.layers.ground'), color: '#2196F3' },
    // { id: 'Census', name: t('dashboard.layers.census'), color: '#9C27B0' },
    // { id: 'BV-LOS', name: t('dashboard.layers.bvlos'), color: '#00BCD4' },
    // { id: 'ATM09', name: t('dashboard.layers.atm09'), color: '#FF9800' },
  ];

  const cardOptions = [
    { id: 'AIP', name: t('dashboard.cards.aip') },
    { id: 'VFR', name: t('dashboard.cards.vfr') },
    { id: 'FMC', name: t('dashboard.cards.fmc') },
    { id: 'CRUISE', name: t('dashboard.cards.cruise') },
  ];

  const [ userPosition, setUserPosition ] = React.useState<google.maps.LatLngLiteral | undefined>();
  const [ selectedPoint, setSelectedPoint ] = React.useState<google.maps.LatLngLiteral | undefined>();
  const [ selectedZones, setSelectedZones ] = React.useState<FlightZone[] | undefined>();
  const [ defaultCenter, setDefaultCenter ] = React.useState<google.maps.LatLngLiteral>({
    lat: 42.0148467,      // center of Italy
    lng: 12.074717
  });
  const [ defaultZoom, setDefaultZoom ] = React.useState<number | undefined>(6);
  const [ center, setCenter ] = React.useState<google.maps.LatLngLiteral | undefined>();
  const [ zoom, setZoom ] = React.useState<number | undefined>();
  const [ positionCollapsed, setPositionCollapsed ] = React.useState(isSmall);

  const [ selectedLayers, setSelectedLayers ] = React.useState<string[]>(['Flight Maps - ITA']);
  const [ selectedCard, setSelectedCard ] = React.useState<string | undefined>();
  const [ weather, setWeather ] = React.useState<Weather | undefined>();
  const [ weatherCardCollapsed, setWeatherCardCollapsed ] = React.useState(true);
  const [ locationDialogOpen, setLocationDialogOpen ] = React.useState<boolean>(false);

  const {
    enabled: flightZonesEnabled,
    setEnabled: setFlightZonesEnabled,
    flightZonesCoordinates
  } = useFlightZones({
    enabled: false
  });
  const { getWeather } = useWeather();

  const positionCtx = React.useContext(PositionContext);

  const now = React.useMemo(() => new Date().toISOString(), []);

  const { data, error, loading } = useQuery<QueryData>(gql(`
    query DashboardData($now: String!) {
      drones(where: ${whereNotDeleted()}, order_by: {created_at: desc}) {
        id
        uuid
        name
        drone_pilot_insurance_expire_date
        drone_pilot_insurance_file_id
        file {
          uuid
          name
        }
      }
      pilot_licenses_aggregate(where: {file_id: {_is_null: false}, expire_date: {_gt: $now}}) {
        aggregate {
          count
        }
      }
    }
  `), {
    variables: {
      now,
    }
  });

  React.useEffect(() => {
    let locationFound = false;
    const posSub = positionCtx?.watchPosition().subscribe((position: GeolocationPosition | null) => {
      if (!position) return;
      setUserPosition(geolocationToLatLng(position));

      if (position && !locationFound) {
        setDefaultCenter(geolocationToLatLng(position));
        setDefaultZoom(17);
        setCenter(geolocationToLatLng(position));
        setZoom(17);
        locationFound = true;
      }
    });
    return () => {
      posSub?.unsubscribe();
    }
  }, [positionCtx]);

  React.useEffect(() => {
    if ((!selectedPoint && !userPosition) || !flightZonesCoordinates) {
      setSelectedZones([]);
      return;
    }

    if (!google?.maps?.geometry) {
      return;
    }

    const pos = selectedPoint ?? userPosition;

    // look for the zones that contains the selected point
    const zones = flightZonesCoordinates.filter((zone) => {
      if (!zone.coords) return false;
      const poly = new google.maps.Polygon({ paths: zone.coords });
      return google.maps.geometry.poly.containsLocation(pos!, poly);
    });

    setSelectedZones(zones);
  }, [selectedPoint, userPosition, flightZonesCoordinates, google?.maps?.geometry]);

  React.useEffect(() => {
    if (!userPosition || weather) return;

    getWeather({
      latitude: userPosition.lat,
      longitude: userPosition.lng,
      language: i18n.language
    }).then((weather) => {
      setWeather(weather);
    });
  }, [userPosition, getWeather, weather, i18n.language]);

  React.useEffect(() => {
    const flightMapsITA = selectedLayers.includes('Flight Maps - ITA');
    setFlightZonesEnabled(flightMapsITA);
  }, [selectedLayers]);

  const handleLayerToggle = (layerId: string) => {
    setSelectedLayers(prev => 
      prev.includes(layerId)
        ? prev.filter(id => id !== layerId)
        : [...prev, layerId]
    );
  };

  const handleCardSelect = (cardId: string) => {
    setSelectedCard(cardId);
  };

  const handleNewFlightPlan = () => {
    navigate('/fly/plans');
  };

  if (!defaultCenter) {
    return <Loading open />;
  }

  return (
    <Box sx={{
      height: 'calc(100% - 64px)',
      borderRadius: isSmall ? 0 : '15px',
      overflow: 'hidden',
    }}>
      <MapFull
        center={center}
        zoom={zoom}
        onCameraChanged={(e) => {
          setCenter(e.detail.center);
          setZoom(e.detail.zoom);
        }}
        defaultCenter={defaultCenter}
        defaultZoom={defaultZoom}
        onClick={(e) => {
          if (!e.detail.latLng) {
            return;
          }

          setSelectedPoint(e.detail.latLng);
        }}>
        { flightZonesEnabled && flightZonesCoordinates.length === 0 && <Loading open /> }
        { flightZonesEnabled && flightZonesCoordinates.map((coords, index) => {
          return <Polygon
            key={index}
            path={coords.coords}
            opacity={0.3}
            color={limitToColor(coords.height)}
            zIndex={200 - coords.height} />;
        }) }

        <UserPosition />
        { selectedPoint && <Marker position={selectedPoint} /> }
        { !isSmall && <ZoneLegend /> }

        <CurrentPosition
          selectedPosition={selectedPoint}
          selectedZones={selectedZones}
          onClear={() => setSelectedPoint(undefined)}
          collapsible={isSmall}
          collapsed={positionCollapsed}
          setCollapsed={setPositionCollapsed}
          sx={{
            position: 'absolute',
            top: isSmall ? undefined : 16,
            right: isSmall ? undefined : 16,
            bottom: isSmall ? 16 : undefined,
            left: isSmall ? 16 : undefined,
            width: isSmall ? 'calc(100% - 100px)' : '100%',
          }}
        />

        <Fab
          sx={{
            position: 'absolute',
            bottom: isSmall ? undefined : 64,
            right: isSmall ? 16 : 16,
            top: isSmall ? 80 : undefined,
          }}
          size={ isSmall ? 'large' : "small" }
          onClick={() => {
            setLocationDialogOpen(!locationDialogOpen);
          }}>
          <SearchIcon />
        </Fab>
        { !isSmall && <React.Fragment>
          <CertificateButton
            data={data?.pilot_licenses_aggregate.aggregate}
            sx={{
              position: 'absolute',
              top: 16,
              left: 16,
            }}
          />
          <LogBookButton
            onClick={() => {
              // Handle click
            }}
            sx={{
              position: 'absolute',
              top: 'calc(16px + 8px + 90px)',
              left: 16,
            }}
            disabled
          />
          <InsuranceButton
            data={data?.drones}
            sx={{
              position: 'absolute',
              top: 'calc(16px + (8px + 90px) * 2)',
              left: 16,
            }}
          />
        </React.Fragment> }
        { isSmall && 
          <MobileButtons
            certificateData={data?.pilot_licenses_aggregate.aggregate}
            onLogBookClick={() => {
              // Handle click
            }}
            insuranceData={data?.drones}
          />
        }
        { !isSmall && <React.Fragment>
          <LayerSelector
            layers={layers}
            selectedLayers={selectedLayers}
            onLayerToggle={handleLayerToggle}
            sx={{
              position: 'absolute',
              bottom: 16,
              left: 130,
            }}
          />
          <CardSelector
            options={cardOptions}
            selectedOption={selectedCard}
            onOptionSelect={handleCardSelect}
            sx={{
              position: 'absolute',
              bottom: 16,
              left: 'calc(8px + 120px + 130px)',
            }}
          />
        </React.Fragment> }
        { isSmall && 
          <MobileSelectors
            layers={layers}
            selectedLayers={selectedLayers}
            onLayerToggle={handleLayerToggle}
            cardOptions={cardOptions}
            selectedCard={selectedCard}
            onCardSelect={handleCardSelect}
          />
        }
        <NewFlightPlanButton
          onClick={handleNewFlightPlan}
          sx={{
            position: 'absolute',
            bottom: 16,
            right: 16,
          }}
        />

        <WeatherCard
          weather={weather}
          collapsed={weatherCardCollapsed}
          onToggleCollapse={() => setWeatherCardCollapsed(!weatherCardCollapsed)}
          sx={{
            position: 'absolute',
            top: 16,
            left: isSmall ? 16 : 118,
          }}
          small={isVerySmall}
        />
      </MapFull>

      <SearchLocationDialog
        open={locationDialogOpen}
        onClose={(location) => {
          setLocationDialogOpen(false);
          if (location) {
            setCenter(location);
          }
        }} />
    </Box>
  );
};

export default Dashboard;
