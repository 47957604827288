import React, { useState, useEffect } from 'react';

interface ServiceWorkerUpdateState {
  isUpdateAvailable: boolean;
  registration: ServiceWorkerRegistration | null;
}

export const useServiceWorkerUpdate = () => {
  const [updateState, setUpdateState] = useState<ServiceWorkerUpdateState>({
    isUpdateAvailable: false,
    registration: null,
  });

  useEffect(() => {
    const checkForUpdates = async () => {
      // Check if currently registered service worker needs to be updated
      if (navigator.serviceWorker) {
        const registration = await navigator.serviceWorker.ready;
        if (!registration) return;

        setUpdateState({
          isUpdateAvailable: !!registration.waiting,
          registration,
        });
      }
    };

    // Set up periodic checks
    const interval = setInterval(checkForUpdates, 60 * 1000); // Check every minute

    return () => clearInterval(interval);
  }, []);

  const applyUpdate = React.useCallback(async () => {
    if (updateState.registration && updateState.isUpdateAvailable) {
      if (updateState.registration.waiting) {
        // Send message to service worker to skip waiting
        updateState.registration.waiting.postMessage({ type: 'SKIP_WAITING' });
        
        // Reload the page to activate the new service worker
        window.location.reload();
      }
    }
  }, [updateState]);

  return {
    isUpdateAvailable: updateState.isUpdateAvailable,
    applyUpdate,
  };
};
