import React from 'react';
import { useTranslation } from 'react-i18next';
import { Alert, Chip, Snackbar } from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import VisibilityIcon from '@mui/icons-material/Visibility';
import useFiles from '../../hooks/useFiles';
import UploadInsurance from '../dialogs/UploadInsurance';
import Loading from '../../common/components/Loading';
import { gql, useApolloClient, useMutation } from '@apollo/client';
import Drone from '../../classes/drone';
import { useNavigate } from 'react-router-dom';

interface InsuranceChipProps {
  drone: Drone;
}

const InsuranceChip = ({ drone }: InsuranceChipProps) => {
  const [state, setState] = React.useState<'active' | 'expired' | 'unknown'>('unknown');
  const [uploadInsuranceDialogOpen, setUploadInsuranceDialogOpen] = React.useState<boolean>(false);
  const [uploading, setUploading] = React.useState<boolean>(false);
  const [uploadingProgress, setUploadingProgress] = React.useState<number | undefined>();
  const [snackbarOpen, setSnackbarOpen] = React.useState<boolean>(false);

  const navigate = useNavigate();

  const client = useApolloClient();

  const [updateInsuranceMutation] = useMutation(gql(`
    mutation UpdateInsurance($id: bigint!, $expirationDate: String!, $fileId: bigint!) {
      update_drones_by_pk(pk_columns: {id: $id}, _set: {drone_pilot_insurance_expire_date: $expirationDate, drone_pilot_insurance_file_id: $fileId}) {
        id
      }
    }
  `), {
    refetchQueries: ['Fleet']
  });

  const { t } = useTranslation();
  const { uploadFile, ensureFolder } = useFiles();

  React.useEffect(() => {
    if (!drone.pilot?.insurance || !drone.pilot.insurance.expire_date) {
      setState('unknown');
      return;
    }

    if (new Date(drone.pilot.insurance.expire_date) < new Date()) {
      setState('expired');
    } else {
      setState('active');
    }
  }, [drone]);

  let chip = null;
  switch (state) {
    case 'active':
      chip = (
        <Chip
          label={t("Active")}
          icon={<FileUploadIcon fontSize="small" />}
          onClick={() => {
            setUploadInsuranceDialogOpen(true);
          }}
          deleteIcon={<VisibilityIcon fontSize="small" />}
          color="primary"
          onDelete={() => {
            if (!drone.pilot?.insurance || !drone.pilot.insurance.file_uuid) return;

            navigate(`/pilot/resources/view/${drone.pilot.insurance.file_uuid}/`);
          }}
        />
      );
      break;
    case 'expired':
      chip = (
        <Chip
          label={t("Expired")}
          icon={<FileUploadIcon fontSize="small" />}
          onClick={() => {
            setUploadInsuranceDialogOpen(true);
          }}
          deleteIcon={<WarningIcon fontSize="small" />}
          onDelete={() => {
            setUploadInsuranceDialogOpen(true);
          }}
          color="error"
        />
      );
      break;
    default:
      chip = (
        <Chip
          label={t("Upload")}
          icon={<FileUploadIcon fontSize="small" />}
          onClick={() => {
            setUploadInsuranceDialogOpen(true);
          }}
        />
      );
      break;
  }

  return (
    <React.Fragment>
      { chip }
      <UploadInsurance
        open={uploadInsuranceDialogOpen}
        onClose={async (res) => {
          setUploadInsuranceDialogOpen(false);
          if (!res) return;

          if (!res.expirationDate) return;
          if (!res.file) return;

          setUploading(true);

          try {
            const folderRes = await ensureFolder(t("Insurances"));
            const file = await uploadFile(res.file, folderRes.id);
            await updateInsuranceMutation({
              variables: {
                id: drone.id,
                expirationDate: res.expirationDate,
                fileId: file.id
              }
            });
            await client.cache.reset();
          } catch (e) {
            console.error(e);
            setUploading(false);
            return;
          }

          setUploading(false);
          setSnackbarOpen(true);
        }}
      />
      <Loading
        open={uploading}
        progress={uploadingProgress}
      />
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        onClose={() => {
          setSnackbarOpen(false);
        }}
      >
        <Alert
          onClose={() => {
            setSnackbarOpen(false);
          }}
          severity="success"
          sx={{ width: '100%' }}
        >
          {t('Insurance uploaded successfully')}
        </Alert>
      </Snackbar>
    </React.Fragment>
  );
}

export default InsuranceChip;
