import React from 'react';
import { Card, CardActionArea, Typography, Box, Skeleton } from '@mui/material';
import { useTranslation } from 'react-i18next';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import { orange, red } from '@mui/material/colors';
import { useNavigate } from 'react-router-dom';
import { DroneDashboardData } from '../../pages/Dashboard';

interface InsuranceButtonProps {
  sx?: React.CSSProperties;
  disabled?: boolean;
  data?: DroneDashboardData[];
}

export const InsuranceButton: React.FC<InsuranceButtonProps> = ({ sx, disabled, data }) => {
  const { t } = useTranslation();

  const [ledColor, setLedColor] = React.useState('text.disabled');
  const [navigateFunction, setNavigateFunction] = React.useState(() => () => {});

  const navigate = useNavigate();

  const navigateToResources = () => {
    navigate('/pilot/resources');
  }

  const navigateToDrones = () => {
    navigate('/pilot/fleet');
  }

  React.useEffect(() => {
    if (disabled || !data) {
      setLedColor('text.disabled');
      setNavigateFunction(() => () => {});
      return;
    }

    const nDrones = data.length;
    const nCertified = data.filter(drone => {
      if (!drone.file) return false;
      if (!drone.drone_pilot_insurance_expire_date) return false;
      const certDate = new Date(drone.drone_pilot_insurance_expire_date);
      return certDate > new Date();
    }).length;

    if (nDrones === 0) {
      setLedColor('text.disabled');
      setNavigateFunction(() => () => {});
      return;
    }

    if (nCertified === 0) {
      setLedColor(red['500']);
      setNavigateFunction(() => navigateToDrones);
      return;
    }

    if (nCertified < nDrones) {
      setLedColor(orange['500']);
      setNavigateFunction(() => navigateToResources);
      return;
    }

    setLedColor('#4caf50');
    setNavigateFunction(() => navigateToResources);
  }, [disabled, data]);

  if (!data) {
    return (
      <Card 
        sx={{ 
          width: 90, 
          height: 90,
          backgroundColor: 'white',
          borderRadius: 1,
          ...sx 
        }}
      >
        <Box
          sx={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            padding: 2,
          }}
        >
          <Skeleton variant="circular" width={54} height={54} />
          <Skeleton variant="text" width={60} sx={{ mt: 1 }} />
        </Box>
      </Card>
    );
  }

  return (
    <Card 
      sx={{ 
        width: 90, 
        height: 90,
        backgroundColor: 'white',
        borderRadius: 1,
        opacity: disabled ? 0.5 : 1,
        ...sx 
      }}
    >
      <CardActionArea
        onClick={navigateFunction}
        disabled={disabled}
        sx={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          padding: 5,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: 0.5,
            margin: 1,
          }}
        >
          <SmartToyIcon 
            sx={{ 
              fontSize: 54,
              color: disabled ? 'text.disabled' : 'text.primary',
            }} 
          />
          <Typography
            variant="caption"
            align="center"
            sx={{
              color: disabled ? 'text.disabled' : 'text.primary',
              textTransform: 'uppercase',
              fontSize: 10,
            }}
          >
            {t('dashboard.buttons.insurance')}
          </Typography>
          <Box
            sx={{
              position: 'absolute',
              top: 4,
              right: 4,
              width: 12,
              height: 12,
              borderRadius: '50%',
              backgroundColor: ledColor,
            }}
          />
        </Box>
      </CardActionArea>
    </Card>
  );
};
