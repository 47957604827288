export interface PersonalInfoData {
  id: string;
  firstName: string;
  lastName: string;
  birthDate: string;
  email: string;
  phone: string;
  city: string;
  linkedin: string;
  isPublic: boolean;
}

export interface QualificationsData {
  id: string;
  pilotLicenses: PilotLicense[];
  qualifications: string[];
  isPublic: boolean;
}

export interface PilotLicense {
  license_name: string;
  expire_date?: string;
  file_id?: number;
  file_uuid?: string;
}

export interface EquipmentData {
  id: string;
  drones: Array<{
    model: string;
  }>;
  software: Array<{
    name: string;
  }>;
  isPublic: boolean;
}

export interface ExperienceData {
  id: string;
  startYear: string;
  missionCount: string;
  specializations: string[];
  isPublic: boolean;
}

export interface AvailabilityData {
  id: string;
  missionTypes: string[];
  operationalRange: number;
  isPublic: boolean;
}

export interface PilotProfileData {
  personalInfo: PersonalInfoData;
  qualifications: QualificationsData;
  equipment: EquipmentData;
  experience: ExperienceData;
  availability: AvailabilityData;
}

export class PilotProfile {
  private data: PilotProfileData;

  constructor() {
    // Initialize with default empty values
    this.data = {
      personalInfo: {
        id: '',
        firstName: '',
        lastName: '',
        birthDate: '',
        email: '',
        phone: '',
        city: '',
        linkedin: '',
        isPublic: false
      },
      qualifications: {
        id: '',
        pilotLicenses: [],
        qualifications: [],
        isPublic: false
      },
      equipment: {
        id: '',
        drones: [],
        software: [],
        isPublic: false
      },
      experience: {
        id: '',
        startYear: '',
        missionCount: '',
        specializations: [],
        isPublic: false
      },
      availability: {
        id: '',
        missionTypes: [],
        operationalRange: 0,
        isPublic: false
      }
    };
  }

  // Getters
  getData(): PilotProfileData {
    return this.data;
  }

  getPersonalInfo(): PersonalInfoData {
    return this.data.personalInfo;
  }

  getQualifications(): QualificationsData {
    return this.data.qualifications;
  }

  getEquipment(): EquipmentData {
    return this.data.equipment;
  }

  getExperience(): ExperienceData {
    return this.data.experience;
  }

  getAvailability(): AvailabilityData {
    return this.data.availability;
  }

  // Setters
  setPersonalInfo(data: Partial<PersonalInfoData>) {
    this.data.personalInfo = { ...this.data.personalInfo, ...data, ...transformObject(data) };
  }

  setQualifications(data: Partial<QualificationsData>) {
    this.data.qualifications = { ...this.data.qualifications, ...data, ...transformObject(data) };
    this.data.qualifications.pilotLicenses = (data as any)?.pilot_licenses?.map((license: any) => ({ ...license, expire_date: license.expire_date, file_id: license.file?.id, file_uuid: license.file?.uuid })) || this.data.qualifications.pilotLicenses;
    this.data.qualifications.qualifications = (data as any)?.certificates?.map((certificate: any) => certificate.cert_name) || this.data.qualifications.qualifications;
  }

  setEquipment(data: Partial<EquipmentData>, droneModels: string[]) {
    this.data.equipment = { ...this.data.equipment, ...data, ...transformObject(data) };
    this.data.equipment.drones = droneModels.map(model => ({ model }));
    this.data.equipment.software = (data as any)?.software_equipments?.map((software: any) => ({ name: software.name })) || this.data.equipment.software;
  }

  setExperience(data: Partial<ExperienceData>) {
    this.data.experience = { ...this.data.experience, ...data, ...transformObject(data) };
    this.data.experience.specializations = (data as any)?.specializations?.map((spec: any) => spec.name) || this.data.experience.specializations;
  }

  setAvailability(data: Partial<AvailabilityData>) {
    this.data.availability = { ...this.data.availability, ...data, ...transformObject(data) };
    this.data.availability.missionTypes = (data as any)?.mission_types?.map((type: any) => type.name) || this.data.availability.missionTypes;
  }

  toJSON(): string {
    return JSON.stringify(this.data);
  }

  fromJSON(json: string): void {
    try {
      const parsed = JSON.parse(json) as PilotProfileData;
      this.data = parsed;
    } catch (error) {
      console.error('Error parsing pilot profile data:', error);
    }
  }
}

const snakeToCamel = (str: string): string => 
  str.replace(/_([a-z])/g, (g) => g[1].toUpperCase());

const transformObject = (obj: any): any => {
  if (!obj || typeof obj !== 'object') return obj;
  if (Array.isArray(obj)) return obj.map(transformObject);
  
  return Object.entries(obj).reduce((acc: any, [key, value]) => {
    const camelKey = snakeToCamel(key);
    acc[camelKey] = transformObject(value);
    return acc;
  }, {});
};
