import React from 'react';
import { 
  Card, 
  CardActionArea, 
  Menu, 
  MenuItem, 
  Typography,
  Stack,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import MapIcon from '@mui/icons-material/Map';

interface CardOption {
  id: string;
  name: string;
}

interface CardSelectorProps {
  options: CardOption[];
  selectedOption?: string;
  onOptionSelect: (optionId: string) => void;
  sx?: React.CSSProperties;
}

export const CardSelector: React.FC<CardSelectorProps> = ({
  options,
  selectedOption,
  onOptionSelect,
  sx
}) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOptionClick = (optionId: string) => {
    onOptionSelect(optionId);
    handleClose();
  };

  return (
    <>
      <Card 
        sx={{ 
          width: 120,
          height: 48,
          backgroundColor: 'white',
          borderRadius: 1,
          ...sx 
        }}
      >
        <CardActionArea
          onClick={handleClick}
          sx={{
            height: '100%',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            padding: '0 16px',
            gap: 1,
          }}
        >
          <MapIcon 
            sx={{ 
              fontSize: 28,
              color: 'text.primary',
            }} 
          />
          <Typography
            sx={{
              color: 'text.primary',
              textTransform: 'uppercase',
            }}
          >
            {t('Carte')}
          </Typography>
        </CardActionArea>
      </Card>

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        sx={{
          '& .MuiPaper-root': {
            minWidth: 180,
            mt: -1,
          }
        }}
      >
        {options.map((option) => (
          <MenuItem 
            key={option.id}
            onClick={() => handleOptionClick(option.id)}
            selected={option.id === selectedOption}
            dense
            disabled
          >
            <Stack 
              direction="row" 
              alignItems="center" 
              spacing={1}
              sx={{ width: '100%' }}
            >
              <Typography variant="body2">
                {option.name}
              </Typography>
            </Stack>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
