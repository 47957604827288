import axios from "axios";
import React from "react";

export const limitToColor = (limit: number) => {
  if (limit <= 0) return '#FF0000';  // Red for 0 meters
  if (limit <= 25) return '#FFA500';  // Orange for 25 meters
  if (limit <= 45) return '#FFFF00';  // Yellow for 45 meters
  if (limit <= 60) return '#00FFFF';  // Cyan for 60 meters
  if (limit <= 120) return '#008000';  // Green for 120 meters
  return '#000000';  // Black for anything above 120 meters
}

export let reasons = [
  "NATURE",
  "NOISE",
  "AIR_TRAFFIC",
  "SENSITIVE",
  "PRIVACY",
  "EMERGENCY",
  "OTHER",
  "POPULATION"
];

export interface FlightZonesOptions {
  enabled?: boolean,
}

export interface FlightZone {
  coords: google.maps.LatLngLiteral[],
  height: number,
  identifier: string,
  name: string,
  reason: string[],
  type: string,
  restriction: string,
  applicability: {
    permanent: 'YES' | 'NO',
    startDateTime?: string,
    endDateTime?: string,
  }[],
}

const useFlightZones = (options?: FlightZonesOptions) => {
  const [ enabled, setEnabled ] = React.useState<boolean>(options?.enabled ?? false);
  const [ flightZonesCoordinates, setFlightZonesCoordinates ] = React.useState<FlightZone[]>([]);

  React.useEffect(() => {
    if (!enabled || flightZonesCoordinates.length > 0) return;

    axios.get('https://static.dromt.it/it/zones.json').then((response) => {
      if (!response.data) return;

      const coords = response.data.features.
        // filter((f: any) => f.name.toLowerCase().includes('torino citt')).
        // map((f: any) => {console.log(f); return f}).
        map((f: any) => ({
          coords: f.geometry[0].horizontalProjection.coordinates[0].
            map((c: any) => ({ lat: c[1], lng: c[0] })),
          height: f.geometry[0].lowerLimit ?? 0,
          identifier: f.identifier,
          name: f.name,
          reason: f.reason,
          type: f.type,
          restriction: f.restriction,
          applicability: f.applicability
        }));
      setFlightZonesCoordinates(coords);
    }).catch((error) => {
      console.error(error);
    });
  }, [enabled]);

  return { enabled, setEnabled, flightZonesCoordinates };
};

export default useFlightZones;
