import React from "react";
import useApi from "../common/hooks/useApi";
import useUser from "../common/hooks/useUser";

const baseURL = "https://n8n.dromt.it/webhook/";
// const baseURL = "https://n8n.dromt.it/webhook-test/";

const webhooks = {
  "file-upload": "f3751383-461d-4ddb-9602-d34ff23dc54e",
  "ensure-folder": "16c3e981-c02a-492e-8b61-938e1e39d299",
  "download-file": "98c6c1dd-e1a1-4987-b01e-3b9b1e593e42"
}

const useFiles = () => {
  const {
    post,
    getBlob
  } = useApi(baseURL);

  const { userID, organizationID } = useUser();

  const uploadFile = React.useCallback(async (file: File, folderID?: number): Promise<{id: number, uuid: string}> => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('filename', file.name);
    formData.append('filesize', ''+file.size);
    if (userID) {
      formData.append('user_id', ''+userID);
    }
    if (organizationID) {
      formData.append('organization_id', ''+organizationID);
    }
    if (folderID) {
      formData.append('folder_id', ''+folderID);
    }
    return (await post(webhooks['file-upload'], formData, { 'Content-Type': 'multipart/form-data' })) as {id: number, uuid: string};
  }, [post, userID, organizationID]);

  const ensureFolder = React.useCallback(async (path: string): Promise<{id: number, name: string}> => {
    const data: any = {
      path,
    };
    if (userID) {
      data.user_id = userID;
    }
    if (organizationID) {
      data.organization_id = organizationID;
    }
    return (await post(webhooks['ensure-folder'], data)) as {id: number, name: string};
  }, [post, userID, organizationID]);

  const downloadFile = React.useCallback((uuid: string): Promise<Blob> => {
    return getBlob(webhooks['download-file'] + '?uuid=' + uuid);
  }, [getBlob]);

  return {
    uploadFile,
    ensureFolder,
    downloadFile
  };
};

export default useFiles;
