import React from "react";
import MapFull from "../../../common/components/MapFull";
import { PositionContext } from "../../../common/contexts/position";
import Loading from "../../../common/components/Loading";
import { computeZoom, geolocationToLatLng } from "../../../common/utils/position";
import UserPosition from "../../../common/components/UserPosition";
import { Card, CardContent, Fab, Stack, Tooltip, Typography, useMediaQuery } from "@mui/material";
import InfoWindow from "../../../common/components/InfoWindow";
import Polyline from "../../../common/components/Polyline";
import Polygon from "../../../common/components/Polygon";
import { Polygon as GeomPolygon } from "../../../classes/geometry";
import { numberFormat } from "../../../common/utils/text";
import MapPoint from "../../../common/components/MapPoint";
import ClearIcon from '@mui/icons-material/Clear';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckIcon from '@mui/icons-material/Check';
import SearchIcon from '@mui/icons-material/Search';
import SearchLocationDialog from "../../dialogs/SearchLocationDialog";
import { useTranslation } from "react-i18next";
import useFlightZones, { limitToColor } from "../../../common/hooks/useFlightZones";
import LayersIcon from '@mui/icons-material/Layers';
import LayersClearIcon from '@mui/icons-material/LayersClear';
import theme from "../../../theme";

interface AreaWindowProps {
  area?: number;
}

const AreaWindow = (props: AreaWindowProps) => {
  const { t } = useTranslation();

  return (
    <div style={{
      position: 'absolute',
      top: 16,
      left: 16,
      width: 150,
      zIndex: 1000,
    }}>
      <Card variant="outlined">
        <CardContent>
          <Typography sx={{ fontSize: 12 }} color="text.secondary" gutterBottom>
            {t('Total Area')}
          </Typography>
          <Typography sx={{ fontSize: 18 }} color="text.secondary">
            { props.area ? numberFormat(props.area, 2) + ' ha' : 'N/A' }
          </Typography>
        </CardContent>
      </Card>
    </div>
  );
};

interface ButtonsProps {
  onSearchLocation?: () => void;
  onDeleteAll?: () => void;
  onDeleteLast?: () => void;
  onConfirm?: () => void;
  toggleDFlight?: () => void;
  dFlightEnabled?: boolean;
}

const Buttons = (props: ButtonsProps) => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm')); 

  return (
    <div style={{
      position: 'absolute',
      bottom: 22,
      right: 16,
      zIndex: 1000,
      textAlign: 'right',
    }}>
      <Stack direction={ isMobile ? 'column' : 'row' } spacing={1}>
        <Tooltip title={t("Flight Zones")} placement={ isMobile ? 'left' : 'top' }>
          <span><Fab size="small" onClick={() => {
            if (props.toggleDFlight) {
              props.toggleDFlight();
            }
          }}
          disabled={props.toggleDFlight === undefined}>
            { props.dFlightEnabled ? <LayersClearIcon /> : <LayersIcon /> }
          </Fab></span>
        </Tooltip>
        <Tooltip title={t("Search Location")} placement={ isMobile ? 'left' : 'top' }>
          <span><Fab size="small" onClick={() => {
            if (props.onSearchLocation) {
              props.onSearchLocation();
            }
          }}
          disabled={props.onSearchLocation === undefined}>
            <SearchIcon />
          </Fab></span>
        </Tooltip>
        <Tooltip title={t("Delete Last")} placement={ isMobile ? 'left' : 'top' }>
          <span><Fab size="small" onClick={() => {
            if (props.onDeleteLast) {
              props.onDeleteLast();
            }
          }}
          disabled={props.onDeleteLast === undefined}>
            <ClearIcon />
          </Fab></span>
        </Tooltip>
        <Tooltip title={t("Delete All")} placement={ isMobile ? 'left' : 'top' }>
          <span><Fab size="small" onClick={() => {
            if (props.onDeleteAll) {
              props.onDeleteAll();
            }
          }}
          disabled={props.onDeleteAll === undefined}>
            <DeleteIcon />
          </Fab></span>
        </Tooltip>
        <Tooltip title={t("Confirm")} placement={ isMobile ? 'left' : 'top' }>
          <span><Fab color="primary" variant="extended" size="medium" onClick={() => {
            if (props.onConfirm) {
              props.onConfirm();
            }
          }}
          disabled={props.onConfirm === undefined}>
            <CheckIcon />
            {t('Confirm')}
          </Fab></span>
        </Tooltip>
      </Stack>
    </div>
  );
};

export const ZoneLegend = () => {
  const { t } = useTranslation();

  const zones = [
    { height: 0, label: '0m' },
    { height: 25, label: '25m' },
    { height: 45, label: '45m' },
    { height: 60, label: '60m' },
    { height: 120, label: '120m' }
  ];

  return (
    <div style={{
      position: 'absolute',
      bottom: 16,
      left: 16,
      zIndex: 1000,
    }}>
      <Card variant="outlined">
        <CardContent>
          <Typography sx={{ fontSize: 12 }} color="text.secondary" gutterBottom>
            {t('Flight Zones')}
          </Typography>
          <Stack spacing={1}>
            {zones.map((zone, index) => (
              <div key={index} style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                <div style={{
                  width: 16,
                  height: 16,
                  backgroundColor: limitToColor(zone.height),
                  opacity: 0.7,
                  border: '1px solid rgba(0,0,0,0.3)'
                }} />
                <Typography sx={{ fontSize: 12 }} color="text.secondary">
                  {zone.label}
                </Typography>
              </div>
            ))}
          </Stack>
        </CardContent>
      </Card>
    </div>
  );
};

export interface DrawAreaProps {
  path?: google.maps.LatLngLiteral[];
  centerPath?: boolean;
  onPathChange?: (path: google.maps.LatLngLiteral[]) => void;
}

const DrawArea = (props: DrawAreaProps) => {
  const [ locationDialogOpen, setLocationDialogOpen ] = React.useState<boolean>(false);

  const [ defaultCenter, setDefaultCenter ] = React.useState<google.maps.LatLngLiteral>({
    lat: 42.0148467,      // center of Italy
    lng: 12.074717
  });
  const [ defaultZoom, setDefaultZoom ] = React.useState<number | undefined>(6);
  const [ center, setCenter ] = React.useState<google.maps.LatLngLiteral | undefined>();
  const [ zoom, setZoom ] = React.useState<number | undefined>();
  const [ path, setPath ] = React.useState<google.maps.LatLngLiteral[]>(props.path ?? []);
  const [ area, setArea ] = React.useState<number | undefined>();

  const { t } = useTranslation();
  const {
    enabled: flightZonesEnabled,
    setEnabled: setFlightZonesEnabled,
    flightZonesCoordinates
  } = useFlightZones({
    enabled: true
  });

  const positionCtx = React.useContext(PositionContext);

  const changePointLocation = (index: number, latLng: google.maps.LatLngLiteral) => {
    const newPath = [...path];
    newPath[index] = latLng;
    setPath(newPath);
  }

  React.useEffect(() => {
    if (props.centerPath) return;

    let locationFound = false;
    const posSub = positionCtx?.watchPosition().subscribe((position: GeolocationPosition | null) => {
      if (position && !locationFound) {
        setDefaultCenter(geolocationToLatLng(position));
        setDefaultZoom(17);
        setCenter(geolocationToLatLng(position));
        setZoom(17);
        locationFound = true;
      }
    });
    return () => {
      posSub?.unsubscribe();
    }
  }, [positionCtx, props.centerPath]);

  React.useEffect(() => {
    if (!props.centerPath) return;

    const b = new google.maps.LatLngBounds();
    path.forEach((latLng) => {
      b.extend(new google.maps.LatLng(latLng.lat, latLng.lng));
    });
    setCenter(b.getCenter().toJSON());
    setZoom(computeZoom(b, window.innerWidth, window.innerHeight));
  }, [path, props.centerPath]);

  React.useEffect(() => {
    if (path.length < 3) {
      setArea(undefined);
      return;
    }

    if (!google.maps.geometry) {
      return
    }

    const polygon = new GeomPolygon(...path);
    try {
      setArea(polygon.areaMeters() / 10000);
    } catch (e) {
      setArea(undefined);
    }
  }, [path, google.maps.geometry]);

  if (!defaultCenter) {
    return <Loading open />;
  }

  return (
    <React.Fragment>
      <MapFull
        center={center}
        zoom={zoom}
        onCameraChanged={(e) => {
          setCenter(e.detail.center);
          setZoom(e.detail.zoom);
        }}
        defaultCenter={defaultCenter}
        defaultZoom={defaultZoom}
        onClick={(e) => {
          if (!e.detail.latLng) {
            return;
          }
          setPath([...path, e.detail.latLng]);
        }}>
        { flightZonesEnabled && flightZonesCoordinates.length === 0 && <Loading open /> }
        { flightZonesEnabled && flightZonesCoordinates.map((coords, index) => {
          return <Polygon
            key={index}
            path={coords.coords}
            opacity={0.3}
            color={limitToColor(coords.height)}
            zIndex={200 - coords.height} />;
        }) }

        <Polyline path={path} closed />
        { path.map((point, index) => {
          return <MapPoint
            key={index}
            position={point}
            onDrag={(e) => {
              if (e && e.latLng) {
                changePointLocation(index, e.latLng.toJSON());
              }
            }} />;
        }) }
        <UserPosition />
        { path.length >= 3 && <AreaWindow area={area} /> }
        { flightZonesEnabled && <ZoneLegend /> }
        <InfoWindow
          text={t('Draw the perimeter of the area to map by clicking the corners on the map')}
          width={300}
          open />
        <Buttons
          toggleDFlight={() => setFlightZonesEnabled(!flightZonesEnabled)}
          dFlightEnabled={flightZonesEnabled}
          onSearchLocation={() => {
            setLocationDialogOpen(true);
          }}
          onDeleteAll={() => {
            setPath([]);
          }}
          onDeleteLast={() => {
            setPath(path.slice(0, path.length - 1));
          }}
          onConfirm={path.length >= 3 && props.onPathChange ? () => {
            props.onPathChange!(path);
          } : undefined}
          />
      </MapFull>
      <SearchLocationDialog
        open={locationDialogOpen}
        onClose={(location) => {
          setLocationDialogOpen(false);
          if (location) {
            setCenter(location);
          }
        }} />
    </React.Fragment>
  );
};

export default DrawArea;
