import { Box, Button, Fab, Grid, Pagination, Stack, Typography } from "@mui/material";
import React from "react";
import { darkGray } from "../theme";
import AddIcon from '@mui/icons-material/Add';
import { ApolloCache, DefaultContext, MutationFunctionOptions, OperationVariables, gql, useMutation, useQuery } from "@apollo/client";
import { and, whereNotDeleted, whereOrganization } from "../common/utils/graphql";
import { OrganizationContext } from "../common/contexts/organization";
import Loading from "../common/components/Loading";
import Drone from "../classes/drone";
import { useScreenSize } from "../common/hooks/useScreenSize";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import DroneCard from "../components/fleet/DroneCard";

interface FleetPaginationProps {
  currentPage: number;
  pages: number;
  onPageChange: (page: number) => void;
}

const FleetPagination = (props: FleetPaginationProps) => {
  return (
    <Box style={{
      padding: '16px',
      display: 'flex',
      justifyContent: 'center',
    }}>
      <Pagination
        count={props.pages}
        page={props.currentPage}
        onChange={(e, page) => {
          props.onPageChange(page);
        }}
        color="primary" />
    </Box>
  );
};

interface FleetPageProps {
  drones: Drone[];
  viewKind: 'pilot' | 'flight';
  deleteDrone?: (options?: MutationFunctionOptions<any, OperationVariables, DefaultContext, ApolloCache<any>> | undefined) => Promise<any>;
  renameDroneMutation?: (options?: MutationFunctionOptions<any, OperationVariables, DefaultContext, ApolloCache<any>> | undefined) => Promise<any>;
  linkFlightPlanDroneMutation?: (options?: MutationFunctionOptions<any, OperationVariables, DefaultContext, ApolloCache<any>> | undefined) => Promise<any>;
  updateDroneModelMutation?: (options?: MutationFunctionOptions<any, OperationVariables, DefaultContext, ApolloCache<any>> | undefined) => Promise<any>;
}

const FleetPage = (props: FleetPageProps) => {
  const { vertical, verticalSmall } = useScreenSize();

  return (
    <Grid container spacing={2} style={{
      height: 'calc(100% - 64px)',
      padding: '32px',
      paddingBottom: 0,
      overflowY: 'auto',
    }}>
      { props.drones.map((drone) => (
        <Grid item xs={vertical ? 12 : 6} md={vertical ? 6 : 4} lg={vertical ? 4 : 4} xl={3} key={drone.uuid}
        style={{
          height: '100%',
          paddingTop: verticalSmall ? '0' : '16px',
        }}>
          <DroneCard
            drone={drone}
            viewKind={props.viewKind}
            deleteDrone={props.deleteDrone}
            renameDroneMutation={props.renameDroneMutation}
            linkFlightPlanDroneMutation={props.linkFlightPlanDroneMutation}
            updateDroneModelMutation={props.updateDroneModelMutation}
            />
        </Grid>
      )) }
    </Grid>
  );
};

export interface FleetProps {
  viewKind: 'pilot' | 'flight';
}

const Fleet = (props: FleetProps) => {
  const [ drones, setDrones ] = React.useState<Drone[]>([]);
  const [ itemsPerPage, setItemsPerPage ] = React.useState(1);
  const [ currentPageNumber, setCurrentPageNumber ] = React.useState(1);
  const [ currentPage, setCurrentPage ] = React.useState<Drone[]>([]);

  const organizationCtx = React.useContext(OrganizationContext);

  const { medium, large, extraLarge, vertical } = useScreenSize();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { loading, error, data } = useQuery(gql(`
    query Fleet {
      drones(where: `+and(whereNotDeleted(), whereOrganization(organizationCtx?.organizationID))+`, order_by: {created_at: desc}) {
        id
        uuid
        name
        drone_pilot_insurance_expire_date
        drone_pilot_insurance_file_id
        drone_pilot_flight_controller
        drone_pilot_gcs
        drone_pilot_mtom
        file {
          uuid
          id
        }
        flight_plan {
          uuid
          name
        }
        drone_model {
          name
          image_url
        }
      }
    }
  `));

  const [ deleteDrone ] = useMutation(gql(`
    mutation DeleteDrone($drone_id: uuid!) {
      update_drones(
        where: {uuid: {_eq: $drone_id}},
        _set: {deleted_at: "now()"}
      ) {
        affected_rows
      }
    }
  `), {
    refetchQueries: ['Fleet', 'Flights', 'FlightPlans']
  });

  const [ renameDroneMutation ] = useMutation(gql(`
    mutation RenameDrone($drone_id: uuid!, $name: String!) {
      update_drones(
        where: {uuid: {_eq: $drone_id}},
        _set: {name: $name}
      ) {
        affected_rows
      }
    }
  `), {
    refetchQueries: ['Fleet', 'Flights', 'FlightPlans']
  });

  const [ linkFlightPlanDroneMutation ] = useMutation(gql(`
    mutation UpdateDrone($drone_id: uuid!, $flightplan_id: bigint) {
      update_drones(
        where: {uuid: {_eq: $drone_id}},
        _set: {flight_plan_id: $flightplan_id}
      ) {
        affected_rows
      }
    }
  `), {
    refetchQueries: ['Fleet', 'Flights', 'FlightPlans']
  });

  const [ updateDroneModelMutation ] = useMutation(gql(`
    mutation UpdateDroneModel($drone_id: uuid!, $model_id: bigint) {
      update_drones(
        where: {uuid: {_eq: $drone_id}},
        _set: {drone_model_id: $model_id}
      ) {
        affected_rows
      }
    }
  `), {
    refetchQueries: ['Fleet']
  });

  const newDrone = React.useCallback(() => {
    navigate('new');
  }, []);

  React.useEffect(() => {
    if (data) {
      setDrones(data.drones.map((drone: any) => {
        const d: Drone = {
          id: drone.id,
          uuid: drone.uuid,
          name: drone.name,
          model: drone.drone_model ? {
            name: drone.drone_model.name,
            image_url: drone.drone_model.image_url,
          } : undefined,
          pilot: {
            mtom: drone.drone_pilot_mtom,
            flight_controller: drone.drone_pilot_flight_controller,
            gcs: drone.drone_pilot_gcs,
            insurance: drone.drone_pilot_insurance_expire_date ? {
              expire_date: drone.drone_pilot_insurance_expire_date,
              file_id: drone.drone_pilot_insurance_file_id,
              file_uuid: drone.file ? drone.file.uuid : undefined,
            } : undefined,
          }
        };
        if (drone.flight_plan) {
          d.flightPlan = {
            uuid: drone.flight_plan.uuid,
            name: drone.flight_plan.name,
          };
        }
        return d;
      }));
    } else {
      setDrones([]);
    }
  }, [data]);

  React.useEffect(() => {
    if (extraLarge) {
      setItemsPerPage(4);
    } else if (large) {
      setItemsPerPage(vertical ? 3 : 3);
    } else if (medium) {
      setItemsPerPage(vertical ? 2 : 3);
    } else {
      setItemsPerPage(vertical ? 1 : 2);
    }
  }, [medium, large, extraLarge, vertical]);

  React.useEffect(() => {
    const start = (currentPageNumber - 1) * itemsPerPage;
    const end = start + itemsPerPage;
    setCurrentPage(drones.slice(start, end));
  }, [drones, itemsPerPage, currentPageNumber]);

  if (loading && !data) {
    return <Loading open />;
  }

  return (
    <React.Fragment>
      { currentPage.length > 0
      ? 
      <Stack style={{
        height: '100%',
      }}>
        <FleetPage
          drones={currentPage}
          viewKind={props.viewKind}
          deleteDrone={deleteDrone}
          renameDroneMutation={renameDroneMutation}
          linkFlightPlanDroneMutation={linkFlightPlanDroneMutation}
          updateDroneModelMutation={updateDroneModelMutation} />
        <FleetPagination
          onPageChange={(page) => setCurrentPageNumber(page)}
          currentPage={currentPageNumber}
          pages={Math.ceil(drones.length / itemsPerPage)} />
      </Stack>
      : 
      <React.Fragment>
        <Typography variant="h5" style={{
          textAlign: 'center',
          marginTop: '32px',
          textTransform: 'uppercase',
          color: darkGray,
        }}>
          {t('No drones found')}
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={newDrone}
          style={{
            margin: '32px auto',
            display: 'block',
          }}>
          {t('Add Drone')}
        </Button>
        <Typography variant="body2" style={{
          textAlign: 'center',
          color: darkGray,
        }}>
          {t('or')}
        </Typography>
        <Button
          variant="text"
          color="primary"
          onClick={() => window.location.reload()}
          style={{
            display: 'block',
            margin: '16px auto',
          }}>
          {t('Reload')}
        </Button>
      </React.Fragment>
      }
      <Fab color="primary"
      onClick={newDrone}
      style={{
        position: 'fixed',
        bottom: '40px',
        right: '32px',
      }}>
        <AddIcon />
      </Fab>
    </React.Fragment>
  );
};

export default Fleet;
