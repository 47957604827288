import React from "react";
import DrawArea from "../../components/flightplans/area/DrawArea";
import SetupFlightPlanFacade from "../../components/flightplans/facade/SetupFlightPlan";
import SetupFlightPlan from "../../components/flightplans/area/SetupFlightPlan";
import { useParams } from "react-router-dom";
import { gql, useMutation, useQuery, useApolloClient } from "@apollo/client";
import Loading from "../../common/components/Loading";
import useUser from "../../common/hooks/useUser";
import DrawFacade from "../../components/flightplans/facade/DrawFacade";

const NewAreaFlightPlan = () => {
  const { fieldUUID, type } = useParams();

  const [ fieldID, setFieldID ] = React.useState<number | undefined>();
  const [ showSetup, setShowSetup ] = React.useState<boolean>(false);
  const [ path, setPath ] = React.useState<google.maps.LatLngLiteral[] | undefined>([]);

  const { userID, organizationID } = useUser();

  const client = useApolloClient();

  const { loading, error, data } = useQuery(gql(`
    query Field($uuid: uuid!) {
      fields(where: {uuid: {_eq: $uuid}}) {
        id
        lat_lngs {
          lat
          lng
        }
      }
    }
  `), {
    variables: {
      uuid: fieldUUID,
    },
    skip: !fieldUUID,
  });

  const [ insertField ] = useMutation(gql`
    mutation InsertField($name: String!, $user_id: bigint!, $organization_id: bigint) {
      insert_fields(objects: {
        name: $name
        user_id: $user_id,
        organization_id: $organization_id,
      }) {
        returning {
          id
          uuid
        }
      }
    }
  `, {
    refetchQueries: ['Fields', 'Field'],
  });

  const [ insertLatLngs ] = useMutation(gql`
    mutation InsertLatLngs($input: [lat_lngs_insert_input!]!) {
      insert_lat_lngs(objects: $input) {
        returning {
          id
        }
      }
    }
  `, {
    refetchQueries: ['Fields', 'Field'],
  });

  const newField = React.useCallback(async (name: string): Promise<number | undefined> => {
    if (fieldUUID) return;

    const res = await insertField({ variables: {
      name,
      user_id: userID,
      organization_id: organizationID,
    } });
    if (res.data) {
      const fieldID = res.data.insert_fields.returning[0].id;
      await insertLatLngs({ variables: {
        input: path?.map((latLng) => ({
          field_id: fieldID,
          lat: latLng.lat,
          lng: latLng.lng,
        })),
      } });
      setFieldID(fieldID);
      await client.resetStore();
      return fieldID;
    }
  }, [fieldUUID, insertField, insertLatLngs, path, organizationID, userID]);

  React.useEffect(() => {
    if (data) {
      setShowSetup(true);
      setPath(data.fields[0].lat_lngs.map((ll: any) => (new google.maps.LatLng(ll.lat, ll.lng).toJSON())));
      setFieldID(data.fields[0].id);
    }
  }, [data]);

  if (fieldUUID && ((loading && !data) || !path || path.length === 0)) {
    return <Loading open />;
  }

  return (
    <React.Fragment>
      {
        !(showSetup && path)
        ?
        (type === 'facade' ? <DrawFacade path={path} onPathChange={(p) => {
          setPath(p);
          setShowSetup(true);
        }} /> : <DrawArea path={path} onPathChange={(p) => {
          setPath(p);
          setShowSetup(true);
        }} />)
        :
        (type === 'facade' ? <SetupFlightPlanFacade path={path} back={fieldUUID ? undefined : () => {
          setShowSetup(false);
        }} save={async (name): Promise<number | undefined> => {
          let fieldID = undefined;
          if (name) fieldID = await newField(name);
          return fieldID;
        }} fieldID={fieldID} /> : <SetupFlightPlan path={path} back={fieldUUID ? undefined : () => {
          setShowSetup(false);
        }} save={async (name): Promise<number | undefined> => {
          let fieldID = undefined;
          if (name) fieldID = await newField(name);
          return fieldID;
        }} fieldID={fieldID} />)
      }
    </React.Fragment>
  );
};

export default NewAreaFlightPlan;
