import React from 'react';
import { Box, List, Pagination } from '@mui/material';
import Comment from './Comment';
import { ForumComment } from '../../../classes/forum';

interface CommentListProps {
  comments: Array<ForumComment>;
  itemsPerPage: number;
  totalItems: number;
  page: number;
  onPageChange: (page: number) => void;
}

const CommentList: React.FC<CommentListProps> = ({ 
  comments, 
  itemsPerPage,
  totalItems,
  page,
  onPageChange,
}) => {
  const pageCount = Math.ceil(totalItems / itemsPerPage);

  return (
    <Box sx={{ width: '100%' }}>
      <List sx={{ width: '100%' }}>
        {comments.map(comment => (
          <Comment key={comment.id} comment={comment} />
        ))}
      </List>
      {pageCount > 1 && (
        <Box sx={{ display: 'flex', justifyContent: 'center', p: 2 }}>
          <Pagination 
            page={page}
            count={pageCount}
            color="primary"
            onChange={(_, value) => onPageChange(value)}
            size="small"
          />
        </Box>
      )}
    </Box>
  );
};

export default CommentList;
