import { Avatar, Divider, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Box, Badge } from "@mui/material";
import React from "react";
import { stringAvatar } from "../common/utils/avatar";
import { useTranslation } from "react-i18next";
import { useAuth } from "react-oidc-context";
import Iubenda from "../common/components/Iubenda";
import LogoutIcon from '@mui/icons-material/Logout';
import TranslateIcon from '@mui/icons-material/Translate';
import SystemUpdateIcon from '@mui/icons-material/SystemUpdate';
import { useServiceWorkerUpdate } from '../hooks/useServiceWorkerUpdate';

const AccountMenu = () => {
  const [ userOpen, setUserOpen ] = React.useState(false);
  const [ anchorElUser, setAnchorElUser ] = React.useState<null | HTMLElement>(null);
  const [ languageMenuOpen, setLanguageMenuOpen ] = React.useState(false);
  const [ languageAnchorEl, setLanguageAnchorEl ] = React.useState<null | HTMLElement>(null);

  const { user, removeUser, signoutSilent, clearStaleState } = useAuth();
  const { t, i18n } = useTranslation();
  const { isUpdateAvailable, applyUpdate } = useServiceWorkerUpdate();

  const languages = [
    { code: 'en', label: 'English', flag: 'gb' },
    { code: 'it', label: 'Italiano', flag: 'it' }
  ];

  const handleUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setUserOpen(true);
    setAnchorElUser(event.currentTarget);
  };

  const handleUserClose = () => {
    setUserOpen(false);
    setAnchorElUser(null);
  };

  const handleEditProfile = () => {
    const a = document.createElement('a');
    a.href = 'https://identity.dromt.it/realms/Dromt/account/';
    a.target = '_blank';
    a.click();
    handleUserClose();
    a.remove();
  };

  const handleLanguageMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setLanguageMenuOpen(true);
    setLanguageAnchorEl(event.currentTarget);
  };

  const handleLanguageMenuClose = () => {
    setLanguageMenuOpen(false);
    setLanguageAnchorEl(null);
  };

  const handleLanguageChange = (langCode: string) => {
    i18n.changeLanguage(langCode);
    handleLanguageMenuClose();
    handleUserClose();
  };

  const handleLogout = () => {
    removeUser();
    signoutSilent({
      id_token_hint: user?.id_token,
    });
    clearStaleState();

    // reload the page
    window.location.reload();
  };

  return (
    <React.Fragment>
      <IconButton
        size="large"
        aria-controls={userOpen ? 'menu-user' : undefined}
        aria-haspopup="true"
        aria-expanded={userOpen ? 'true' : undefined}
        onClick={handleUserMenu}
        color="inherit"
      >
        <Badge
          color="secondary"
          overlap="circular"
          variant="dot"
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          invisible={!isUpdateAvailable}
        >
          <Avatar {...stringAvatar(user?.profile.name ?? user?.profile.email ?? t('User'))} />
        </Badge>
      </IconButton>
      <Menu
        id="menu-user"
        anchorEl={anchorElUser}
        open={userOpen}
        onClose={handleUserClose}
        MenuListProps={{
          'aria-labelledby': 'button',
        }}
      >
        <MenuItem onClick={handleEditProfile}>
          <ListItemIcon style={{ marginRight: 8 }}>
            <Avatar {...stringAvatar(user?.profile.name ?? user?.profile.email ?? t('User'))} />
          </ListItemIcon>
          <ListItemText>{user?.profile.name ?? user?.profile.email ?? t('User')}</ListItemText>
        </MenuItem>

        <Divider />

        {isUpdateAvailable && (
          <>
              <MenuItem onClick={async () => {
                await applyUpdate();
                handleUserClose();
              }}>
                <Badge color="secondary" variant="dot" anchorOrigin={{ horizontal: 'left' }}>
                  <ListItemIcon>
                    <SystemUpdateIcon fontSize="small" />
                  </ListItemIcon>
                </Badge>
                <ListItemText>{t('common.update.now')}</ListItemText>
              </MenuItem>
            <Divider />
          </>
        )}

        <MenuItem onClick={handleLanguageMenuOpen}>
          <ListItemIcon>
            <TranslateIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>{t('common.language')}</ListItemText>
        </MenuItem>
        <Menu
          id="language-menu"
          anchorEl={languageAnchorEl}
          open={languageMenuOpen}
          onClose={handleLanguageMenuClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          {languages.map((lang) => (
            <MenuItem
              key={lang.code}
              onClick={() => handleLanguageChange(lang.code)}
              selected={i18n.language.toLowerCase().startsWith(lang.code.toLowerCase())}
            >
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <span className={`fi fi-${lang.flag}`} style={{ fontSize: '1em' }} />
                {lang.label}
              </Box>
            </MenuItem>
          ))}
        </Menu>

        <Divider />

        <Iubenda menuItems />

        <Divider />

        <MenuItem onClick={handleLogout}>
          <ListItemIcon>
            <LogoutIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>{t("Logout")}</ListItemText>
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
};

export default AccountMenu;
