import React from "react";
import { Outlet } from "react-router-dom";
import { Box } from "@mui/material";

export interface ScrollLayoutProps {
  height?: string;
}

const ScrollLayout = ({ height }: ScrollLayoutProps) => {
  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      margin: 0,
      height: height ?? 'calc(100% - 64px)',
      width: '100%',
      overflowY: 'scroll',
      overflowX: 'hidden',
    }}>
      <Outlet />
    </Box>
  );
};

export default ScrollLayout;
