import React from 'react';
import {
  Box,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Button,
  IconButton,
  Tooltip,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';
import InputAdornment from '@mui/material/InputAdornment';

export interface ForumHeaderProps {
  onSearch: (query: string) => void;
  onSort: (sort: string) => void;
}

const ForumHeader: React.FC<ForumHeaderProps> = ({ onSearch, onSort }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleCreatePost = () => {
    navigate('/community/forum/create');
  };

  return (
    <Box sx={{ display: 'flex', gap: 2, mb: 3, alignItems: 'center' }}>
      <TextField
        placeholder={t('forum.search')}
        variant="outlined"
        size="small"
        sx={{ flexGrow: 1 }}
        onChange={(e) => onSearch(e.target.value)}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />
      <FormControl size="small" sx={{ minWidth: isMobile ? 100 : 120 }}>
        <InputLabel>{t('forum.sort_by')}</InputLabel>
        <Select defaultValue="recent" label={t('forum.sort_by')} onChange={(e) => onSort(e.target.value)}>
          <MenuItem value="recent">{t('forum.recent')}</MenuItem>
          <MenuItem value="comments">{t('forum.most_comments')}</MenuItem>
        </Select>
      </FormControl>
      {isMobile ? (
        <Tooltip title={t('forum.create_post')}>
          <IconButton
            color="primary"
            onClick={handleCreatePost}
            sx={{
              backgroundColor: theme.palette.primary.main,
              color: theme.palette.primary.contrastText,
              '&:hover': {
                backgroundColor: theme.palette.primary.dark,
              },
            }}
          >
            <AddIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Button variant="contained" color="primary" onClick={handleCreatePost}>
          {t('forum.create_post')}
        </Button>
      )}
    </Box>
  );
};

export default ForumHeader;
