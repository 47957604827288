import React from 'react';
import { Box, TextField, FormControlLabel, Switch, Chip, Autocomplete, Button, Snackbar, Alert } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { PilotLicense, QualificationsData } from '../../../classes/PilotProfile';
import { gql, useMutation, useApolloClient } from '@apollo/client';
import useUser from '../../../common/hooks/useUser';
import UploadIcon from '@mui/icons-material/Upload';
import UploadLicense from '../../dialogs/UploadLicense';
import useFiles from '../../../hooks/useFiles';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useNavigate } from 'react-router-dom';

interface QualificationsProps {
  data: QualificationsData;
}

const QualificationsSection: React.FC<QualificationsProps> = ({ data }) => {
  const [newData, setNewData] = React.useState<QualificationsData>(data);
  const [uploadDialogOpen, setUploadDialogOpen] = React.useState(false);
  const [selectedLicense, setSelectedLicense] = React.useState<PilotLicense | undefined>();
  const [pilotLicensesStrings, setPilotLicensesStrings] = React.useState<string[]>([]);
  const [uploading, setUploading] = React.useState(false);
  const [snackOpen, setSnackOpen] = React.useState(false);
  const [snackMessage, setSnackMessage] = React.useState('');

  const { userID } = useUser();
  const { t } = useTranslation();
  const client = useApolloClient();
  const navigate = useNavigate();
  const {
    ensureFolder,
    uploadFile
  } = useFiles();

  const [qualificationsMutationUpdate] = useMutation(gql`
    mutation UpdateQualifications(
      $id: bigint!,
      $is_public: Boolean,
    ) {
      update_qualifications_by_pk(pk_columns: {id: $id}, _set: {
        is_public: $is_public,
      }) {
        id
      }
    }
  `);

  const [qualificationsMutationInsert] = useMutation(gql`
    mutation InsertQualifications(
      $user_id: bigint!,
      $is_public: Boolean,
      $pilot_licenses: pilot_licenses_arr_rel_insert_input,
      $qualifications: qualifications_arr_rel_insert_input
    ) {
      insert_qualifications(objects: {
        user_id: $user_id,
        is_public: $is_public,
        pilot_licenses: $pilot_licenses,
        qualifications: $qualifications
      }) {
        affected_rows
      }
    }
  `, {
    refetchQueries: ['UserProfiles']
  });

  const [pilotLicensesRemoveMutation] = useMutation(gql`
    mutation RemovePilotLicense($qualification_id: bigint!, $license_name: String!) {
      delete_pilot_licenses(
        where: {
          qualifications_id: { _eq: $qualification_id },
          license_name: { _eq: $license_name }
        }
      ) {
        affected_rows
      }
    }
  `);

  const [qualificationsRemoveMutation] = useMutation(gql`
    mutation RemoveQualifications($qualification_id: bigint!) {
      delete_certificates(where: {qualifications_id: {_eq: $qualification_id}}) {
        affected_rows
      }
    }
  `);

  const [pilotLicensesAddMutation] = useMutation(gql`
    mutation AddPilotLicense($qualification_id: bigint!, $license_name: String!) {
      insert_pilot_licenses_one(object: {
        qualifications_id: $qualification_id,
        license_name: $license_name
      }) {
        license_name
      }
    }
  `);

  const [qualificationsAddMutation] = useMutation(gql`
    mutation AddQualification($qualification_id: bigint!, $cert_name: String!) {
      insert_certificates(objects: {qualifications_id: $qualification_id, cert_name: $cert_name}) {
        affected_rows
      }
    }
  `);

  const [updatePilotLicenseMutation] = useMutation(gql`
    mutation UpdatePilotLicense($qualification_id: bigint!, $license_name: String!, $expire_date: String!, $file_id: bigint!) {
      update_pilot_licenses(
        where: {
          qualifications_id: { _eq: $qualification_id },
          license_name: { _eq: $license_name }
        },
        _set: {
          expire_date: $expire_date,
          file_id: $file_id
        }
      ) {
        affected_rows
      }
    }
  `);

  const handleChange = (field: keyof QualificationsData) => (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = field === 'isPublic' ? event.target.checked : event.target.value;
    setNewData(prevState => ({ ...prevState, [field]: value }));
  };

  const handleArrayChange = (field: 'pilotLicenses' | 'qualifications') => (
    _event: React.SyntheticEvent,
    newValue: string[]
  ) => {
    setNewData(prevState => ({ ...prevState, [field]: newValue }));
  };

  const handleSave = async () => {
    const variables = {
      is_public: newData.isPublic,
      pilot_licenses: { data: newData.pilotLicenses.map(license => ({ license_name: license })) },
      qualifications: { data: newData.qualifications.map(qualification => ({ qualification_name: qualification })) }
    };

    if (!data.id) {
      await qualificationsMutationInsert({
        variables: {
          user_id: userID,
          ...variables
        }
      });
    } else {
      await qualificationsMutationUpdate({
        variables: {
          id: data.id,
          ...variables
        }
      });

      // if licenses are different
      if (data.pilotLicenses.sort().join(',') !== newData.pilotLicenses.sort().join(',')) {
        const licensesToRemove = data.pilotLicenses.filter(license => !newData.pilotLicenses.includes(license));
        const licensesToAdd = newData.pilotLicenses.filter(license => !data.pilotLicenses.includes(license));

        await Promise.all([
          ...licensesToRemove.map(license => 
            pilotLicensesRemoveMutation({
              variables: {
                qualification_id: data.id,
                license_name: license
              }
            })
          ),
          ...licensesToAdd.map(license => 
            pilotLicensesAddMutation({
              variables: {
                qualification_id: data.id,
                license_name: license
              }
            })
          )
        ]);
      }

      // if qualifications are different
      if (data.qualifications.sort().join(',') !== newData.qualifications.sort().join(',')) {
        await qualificationsRemoveMutation({
          variables: {
            qualification_id: data.id
          }
        });
        await Promise.all(
          newData.qualifications.map(async (qualification) => {
            await qualificationsAddMutation({
              variables: {
                qualification_id: data.id,
                cert_name: qualification
              }
            });
          })
        );
      }

      // refresh the graphql cache
      window.location.reload();
    }
  };

  const showSnackMessage = (message: string) => {
    setSnackMessage(message);
    setSnackOpen(true);
  };

  // Common license types
  const commonLicenses = [
    'Open A1-A3',
    'Specific',
    'Night',
    'Extended Visual Line of Sight',
    'Beyond Visual Line of Sight'
  ];

  // Common qualification types
  const commonQualifications = ['BVLOS', 'Night Flight', 'FPV'];

  React.useEffect(() => {
    setPilotLicensesStrings(data.pilotLicenses.map(license => license.license_name));
  }, [data.pilotLicenses]);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, p: 2 }}>
      <Autocomplete
        multiple
        id="pilotLicenses"
        options={commonLicenses}
        value={pilotLicensesStrings}
        onChange={async (event, newValue) => {
          const addedLicenses = newValue.filter(license => !pilotLicensesStrings.includes(license));
          const removedLicenses = pilotLicensesStrings.filter(license => !newValue.includes(license));

          try {
            // Remove licenses that were removed
            await Promise.all(
              removedLicenses.map(license =>
                pilotLicensesRemoveMutation({
                  variables: {
                    qualification_id: data.id,
                    license_name: license
                  }
                })
              )
            );

            // Add new licenses
            await Promise.all(
              addedLicenses.map(license =>
                pilotLicensesAddMutation({
                  variables: {
                    qualification_id: data.id,
                    license_name: license
                  }
                })
              )
            );

            setPilotLicensesStrings(newValue);
            await client.cache.reset();
          } catch (e) {
            console.error(e);
            showSnackMessage(t('Failed to update licenses'));
          }
        }}
        freeSolo
        renderTags={(value: string[], getTagProps) =>
          value.map((option: string, index: number) => {
            const license = data.pilotLicenses.find(license => license.license_name === option);
            return (
              <Chip
                variant="outlined"
                label={option}
                icon={license?.file_id ? <CheckCircleIcon /> : <UploadIcon />}
                onClick={() => {
                  if (license?.file_uuid) {
                    navigate(`/pilot/resources/view/${license.file_uuid}`);
                    return;
                  }
                  setSelectedLicense(license);
                  setUploadDialogOpen(true);
                }}
                {...getTagProps({ index })}
              />
          )
        })}
        renderInput={(params) => (
          <TextField
            {...params}
            label={t('pilot.fields.pilotLicenses')}
            placeholder={t('pilot.placeholders.addLicense')}
            required
          />
        )}
      />

      <Autocomplete
        multiple
        id="qualifications"
        options={commonQualifications}
        value={newData.qualifications}
        onChange={handleArrayChange('qualifications')}
        freeSolo
        renderTags={(value: string[], getTagProps) =>
          value.map((option: string, index: number) => (
            <Chip
              variant="outlined"
              label={option}
              {...getTagProps({ index })}
            />
          ))
        }
        renderInput={(params) => (
          <TextField
            {...params}
            label={t('pilot.fields.qualifications')}
            placeholder={t('pilot.placeholders.addQualification')}
          />
        )}
      />

      <FormControlLabel
        control={
          <Switch
            checked={newData.isPublic}
            onChange={handleChange('isPublic')}
          />
        }
        label={t('pilot.fields.publicProfile')}
      />
      <Button
        variant="contained"
        color="primary"
        onClick={handleSave}
      >
        {t('Save')}
      </Button>
      <UploadLicense
        open={uploadDialogOpen}
        licenseType={selectedLicense?.license_name || ''}
        onClose={async (result) => {
          setUploadDialogOpen(false);
          if (!result) return;
          if (!result.expirationDate) return;
          if (!result.file) return;

          setUploading(true);

          try {
            const folderRes = await ensureFolder(t("Licenses"));
            const file = await uploadFile(result.file, folderRes.id);
            await updatePilotLicenseMutation({
              variables: {
                qualification_id: data.id,
                license_name: selectedLicense?.license_name || '',
                expire_date: result.expirationDate.toISOString(),
                file_id: file.id
              }
            });
            await client.cache.reset();
            showSnackMessage(t('License uploaded successfully'));
          } catch (e) {
            console.error(e);
            showSnackMessage(t('Failed to upload license'));
          } finally {
            setUploading(false);
          }
        }}
      />

      <Snackbar
        open={snackOpen}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        onClose={() => {
          setSnackOpen(false);
        }}
      >
        <Alert
          onClose={() => {
            setSnackOpen(false);
          }}
          severity="success"
          sx={{ width: '100%' }}
        >
          {snackMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default QualificationsSection;
