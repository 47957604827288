import React from 'react';
import { Box, CircularProgress, useMediaQuery } from '@mui/material';
import ForumHeader from '../../../components/community/forum/ForumHeader';
import ForumList from '../../../components/community/forum/ForumList';
import ForumSidebar from '../../../components/community/forum/ForumSidebar';
import { ForumPost } from '../../../classes/forum';
import { gql, useQuery } from '@apollo/client';
import { useScreenSize } from '../../../common/hooks/useScreenSize';
import theme from '../../../theme';

const ForumPage: React.FC = () => {
  const [ posts, setPosts ] = React.useState<ForumPost[]>([]);
  const [ sort, setSort ] = React.useState<string>('recent');
  const [ searchQuery, setSearchQuery ] = React.useState<string>('');
  const [ page, setPage ] = React.useState(1);
  const itemsPerPage = 5;
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const { data, loading, error } = useQuery(gql(`
    query Posts($sort: [forum_posts_order_by!], $limit: Int, $offset: Int, $search: String) {
      forum_posts(
        limit: $limit, 
        offset: $offset, 
        order_by: $sort,
        where: {
          _or: [
            { title: { _ilike: $search } },
            { description: { _ilike: $search } }
          ]
        }
      ) {
        created_at
        current_status
        description
        email
        forum_post_comments_aggregate {
          aggregate {
            count
          }
        }
        id
        title
        username
      }
      forum_posts_aggregate(
        where: {
          _or: [
            { title: { _ilike: $search } },
            { description: { _ilike: $search } }
          ]
        }
      ) {
        aggregate {
          count
        }
      }
    }
  `), {
    variables: {
      sort: sort === 'recent' ? { created_at: 'desc' } : { forum_post_comments_aggregate: { count: 'desc' } },
      limit: itemsPerPage,
      offset: (page - 1) * itemsPerPage,
      search: searchQuery.length >= 3 ? `%${searchQuery}%` : '%',
    },
  });

  React.useEffect(() => {
    if (data) {
      setPosts(data.forum_posts.map((item: any) => {
        return {
          id: item.id,
          username: item.username,
          email: item.email,
          createdAt: item.created_at,
          title: item.title,
          description: item.description,
          currentStatus: item.current_status,
          nComments: item.forum_post_comments_aggregate.aggregate.count,
          comments: undefined,
        };
      }));
    }
  }, [data]);

  // Reset to first page when search or sort changes
  React.useEffect(() => {
    setPage(1);
  }, [searchQuery, sort]);

  return (
    <Box sx={{ display: 'flex', gap: 3, p: isMobile ? 1 : 3, pt: 3, pb: 3, width: '100%', maxWidth: '1000px', mx: 'auto' }}>
      <Box sx={{ flexGrow: 1 }}>
        <ForumHeader 
          onSearch={(query: string) => {
            setSearchQuery(query);
          }}
          onSort={(sort: string) => {
            setSort(sort);
          }}
        />
        { loading && <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mb: 3 }}>
          <CircularProgress size={30} 
            variant='indeterminate'
            color='primary' />
        </Box> }
        <ForumList
          posts={posts}
          itemsPerPage={itemsPerPage}
          totalItems={data?.forum_posts_aggregate.aggregate.count ?? 0}
          page={page}
          onPageChange={setPage}
        />
      </Box>
      {/* <ForumSidebar /> */}
    </Box>
  );
};

export default ForumPage;
