import React from 'react';
import {
  ButtonGroup,
  IconButton,
  Tooltip,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { gql, useApolloClient, useMutation } from '@apollo/client';
import { ForumComment } from '../../../classes/forum';
import ConfirmDialog from '../../../common/dialogs/ConfirmDialog';
import { useTranslation } from 'react-i18next';

interface CommentButtonsProps {
  comment: ForumComment;
  onEdit: () => void;
}

const CommentButtons: React.FC<CommentButtonsProps> = ({ comment, onEdit }) => {
  const client = useApolloClient();
  const { t } = useTranslation();
  const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);

  const [ deleteCommentMutation ] = useMutation(gql`
    mutation DeleteComment($id: bigint!) {
      update_forum_post_comments_by_pk(pk_columns: { id: $id }, _set: { deleted_at: "now()" }) {
        deleted_at
      }
    }
  `);

  return (
    <>
      <ButtonGroup size="small" onClick={(e) => e.stopPropagation()}>
        <Tooltip title={t('forum.edit_comment')}>
          <IconButton
            style={{ padding: 4 }}
            onClick={onEdit}
          >
            <EditIcon fontSize="small" />
          </IconButton>
        </Tooltip>
        <Tooltip title={t('forum.delete_comment')}>
          <IconButton
            style={{ padding: 4 }}
            onClick={() => setDeleteDialogOpen(true)}
            color="error"
          >
            <DeleteIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      </ButtonGroup>

      <ConfirmDialog
        open={deleteDialogOpen}
        onClose={async (confirmed) => {
          setDeleteDialogOpen(false);
          if (confirmed) {
            await deleteCommentMutation({
              variables: {
                id: comment.id,
              },
            });
            await client.clearStore();
            window.location.reload();
          }
        }}
        title={t('forum.delete_comment')}
        body={t('forum.delete_comment_confirm')}
        confirmColor="error"
      />
    </>
  );
};

export default CommentButtons;
